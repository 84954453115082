import {styledTheme} from '@festi/common/themes'
import styled from 'styled-components'
import CheckLineIcon from 'remixicon-react/CheckLineIcon'
import cn from 'classnames'
import {ReactNode, useCallback, useRef} from 'react'
import {FieldConfig, useField} from 'formik'

interface NewCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactNode
  errorMsg?: string | false
  strong?: boolean
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: fit-content;
`

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid ${styledTheme.palette.ui20Solid};
  background-color: ${styledTheme.palette.white};
  color: transparent;
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  border-radius: 0;
  pointer-events: none;

  &:checked + ${Checkbox} {
    background-color: ${styledTheme.palette.lightBlue};
    border-color: ${styledTheme.palette.lightBlue};
    color: ${styledTheme.palette.blue};
  }
  &:focus-visible + ${Checkbox} {
    border-color: ${styledTheme.palette.lightBlue};
  }
  &:disabled + ${Checkbox} {
    background-color: ${styledTheme.palette.ui20Solid};
    border-color: ${styledTheme.palette.ui20Solid};
    color: ${styledTheme.palette.ui40Solid};
  }
`

const Label = styled.label`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  font-weight: 400;
  color: ${styledTheme.palette.blue};
  user-select: none;
  cursor: ${styledTheme.cursor.pointer};

  &.disabled {
    cursor: not-allowed;
    color: ${styledTheme.palette.ui20Solid};
  }
  &.strong {
    font-weight: 500;
  }
  &:hover&:not(.disabled) ${Checkbox} {
    border-color: ${styledTheme.palette.lightBlue};
  }
`
// Note(Bjorg): There was no error message in the design, I added it in case needed
const ErrorMsg = styled.p`
  font-size: 12px;
  padding-inline: 4px;
  color: ${styledTheme.palette.error};
`

export default function NewCheckbox({
  label,
  strong = false,
  errorMsg = false,
  ...props
}: NewCheckboxProps) {
  const checkboxRef = useRef<HTMLInputElement>(null)

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        checkboxRef.current?.click()
      }
    },
    [checkboxRef],
  )

  return (
    <Root>
      <Label className={cn({disabled: props.disabled, strong})}>
        {label && label}
        <CheckboxInput
          ref={checkboxRef}
          type="checkbox"
          onKeyDown={onKeyDown}
          {...props}
        />
        <Checkbox role="checkbox">
          <CheckLineIcon size={14} />
        </Checkbox>
      </Label>
      {!!errorMsg && <ErrorMsg role="alert">{errorMsg}</ErrorMsg>}
    </Root>
  )
}

export function NewFormikCheckbox(
  props: NewCheckboxProps &
    React.InputHTMLAttributes<HTMLInputElement> &
    FieldConfig,
): JSX.Element {
  const [field, meta] = useField(props)
  const errorMsg = !!meta.touched && meta.error

  return (
    <NewCheckbox
      {...field}
      {...props}
      checked={field.value}
      errorMsg={errorMsg}
    />
  )
}
