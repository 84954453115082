import HeartLineIcon from 'remixicon-react/HeartLineIcon'

import UtilityButton from './UtilityButton'
import {useWishlist} from '@festi/common/utils/wishlists'

interface Props {
  onClick: () => void
}

export default function WishlistButton({onClick}: Props) {
  const {wishlistItems} = useWishlist()
  return (
    <UtilityButton
      icon={HeartLineIcon}
      onClick={onClick}
      counter={wishlistItems?.length}
    />
  )
}
