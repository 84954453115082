import {gql} from '@apollo/client'

export const textFragment = gql`
  fragment TextFields on TextRecord {
    id
    name
    content {
      value
    }
  }
`

export const shippingMethodTextFragment = gql`
  fragment ShippingMethodTextFields on ShippingMethodTextRecord {
    id
    slug
    shortDescription
    longDescription {
      value
    }
  }
`

export const textQuery = gql`
  ${textFragment}
  query Text($name: String) {
    text(filter: {name: {eq: $name}}) {
      ...TextFields
    }
  }
`

export const allShippingMethodTextsQuery = gql`
  ${shippingMethodTextFragment}
  query AllShippingMethodTexts {
    allShippingMethodTexts {
      ...ShippingMethodTextFields
    }
  }
`
