import React, {useEffect, useRef} from 'react'

interface Props {
  actionUrl: string
  fields: [
    {
      name: string
      value: string
    },
  ]
  isSuccess: boolean
}

export default function ValitorPay3DSecureProcessingForm({
  actionUrl,
  fields,
  isSuccess,
}: Props): JSX.Element {
  const formRef = useRef(null)

  useEffect(() => {
    if (fields && isSuccess) {
      formRef.current.submit()
    }
  }, [fields, isSuccess])

  return (
    <form
      ref={formRef}
      id="webform0"
      name="red2ACSv1"
      method="POST"
      action={actionUrl}
      acceptCharset="UTF-8"
    >
      {fields?.map(({name, value}) => (
        <input key={name} type="hidden" name={name} value={value} />
      ))}
    </form>
  )
}
