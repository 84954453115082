import React from 'react'

import styled from 'styled-components'
import {palette} from 'styled-tools'

interface Props {
  children: React.ReactNode
}

const Message = styled.span`
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.2;
  padding: 5px 0;
  color: ${palette('error')};
`

export default function ErrorMsg({children}: Props): JSX.Element {
  return <Message>{children}</Message>
}
