import {styledTheme} from '@festi/common/themes'
import classNames from 'classnames'
import Building4LineIcon from 'remixicon-react/Building4LineIcon'
import UserLineIcon from 'remixicon-react/UserLineIcon'
import styled from 'styled-components'

const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-block: 16px;
  padding-left: 16px;
  padding-right: 32px;
  background: ${styledTheme.palette.transparent};
  border: 1px solid ${styledTheme.palette.grey};
  border-radius: 12px;
  cursor: ${styledTheme.cursor.pointer};

  &.small {
    gap: 8px;
    padding-block: 8px;
    padding-left: 12px;
    padding-right: 28px;
  }
  &.xsmall {
    gap: 8px;
    padding-block: 4px;
    padding-left: 12px;
    padding-right: 28px;
  }
  &.selected {
    border: 2px solid ${styledTheme.palette.link};
    cursor: default;
  }
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 48px;

  &.xsmall,
  &.small {
    height: 42px;
  }
`

const Name = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: ${styledTheme.palette.blue};

  &.xsmall,
  &.small {
    font-size: 16px;
    line-height: 22px;
  }
  &.selected {
    color: ${styledTheme.palette.link};
  }
`
const Ssn = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${styledTheme.palette.ui60Solid};

  &.selected {
    color: ${styledTheme.palette.link};
  }
`

interface UserTabProps {
  name: string
  ssn: string
  selected: boolean
  onClick: () => void
  isBusiness?: boolean
  size?: 'default' | 'small' | 'xsmall'
}

export default function UserTab({
  name,
  ssn,
  selected,
  onClick,
  isBusiness = false,
  size = 'default',
}: UserTabProps) {
  const iconColor = selected
    ? styledTheme.palette.link
    : styledTheme.palette.ui40Solid

  const styleState = {
    small: size === 'small',
    xsmall: size === 'xsmall',
    selected,
  }

  return (
    <Root role="button" onClick={onClick} className={classNames(styleState)}>
      <IconContainer>
        {isBusiness ? (
          <Building4LineIcon size={20} color={iconColor} />
        ) : (
          <UserLineIcon size={20} color={iconColor} />
        )}
      </IconContainer>
      <InfoContainer className={classNames(styleState)}>
        <Name className={classNames(styleState)}>{name}</Name>
        <Ssn className={classNames({selected})}>{ssn}</Ssn>
      </InfoContainer>
    </Root>
  )
}
