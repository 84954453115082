import React, {useEffect, useCallback, useState, useRef} from 'react'

import cn from 'classnames'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'
import transition from 'styled-transition-group'

import {useCommonContext} from '@festi/common/contexts/common'
import {styledTheme} from '@festi/common/themes'
import {
  buildCategoryTree,
  CategoryTreeItem,
} from '@festi/common/utils/categories'
import {fluidRange} from '@festi/common/utils/styles'

import {FocusTrapWrapper} from '../layout'
import SubMenuCategoryItem from './SubMenuCategoryItem'

interface Props {
  isOpen?: boolean
  onRequestClose?: () => void
  onDutyFreeFrontPage?: boolean
}

const DrawerInner = styled.div`
  -webkit-overflow-scrolling: 'touch';
  overflow: auto;
  outline: none;
  position: absolute;
  top: ${styledTheme.navBarHeight.small};
  left: 0;
  min-height: calc(100% - ${styledTheme.navBarHeight.small});
  width: 100%;
  z-index: 1;
  transition: transform 0.25s;
  transition-timing-function: cubic-bezier(0.33, 0.48, 0.13, 0.9);
  display: flex;
  align-items: stretch;

  &.noPointer {
    pointer-events: none;
  }

  &.onDutyFreeFrontPage {
    top: ${styledTheme.navBarHeight.dutyFreeFrontPageSmall};
    min-height: calc(100% - ${styledTheme.navBarHeight.dutyFreeFrontPageSmall});
  }

  ${media.md`
    width: calc(100% - 160px);
    max-width: 1920px;
    top: ${styledTheme.navBarHeight.large};
    min-height: calc(100% - ${styledTheme.navBarHeight.large});

    &.onDutyFreeFrontPage {
      top: ${styledTheme.navBarHeight.dutyFreeFrontPageLarge};
      min-height: calc(100% - ${styledTheme.navBarHeight.dutyFreeFrontPageLarge});
    }
  `}
`

const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow-y: auto;
`

const MenuItem = styled.li`
  padding-left: 24px;

  ${media.md`
    padding-left: 50px;
  `}

  ${media.lg`
    padding-left: 120px;
  `}
`

const MenuItemText = styled.div`
  font-size: 1.5rem;
  color: ${palette('white')};
  font-weight: 500;
  transition: color 0.2s, transform 0.2s ease-out;
`

const MenuItemBtn = styled.button`
  padding: 16px 0;
  background: none;
  border-radius: 0;
  border: 0;
  cursor: pointer;
  text-align: left;
  width: 100%;
  position: relative;

  .remixicon-icon {
    position: absolute;
    left: -8px;
    top: calc(50% - 16px);
    transform: scale(0);
    transition: transform 0.2s ease-out;
  }

  &:hover,
  &.selected {
    outline: none;

    .remixicon-icon {
      transform: scale(1);
    }

    ${MenuItemText} {
      color: ${palette('white')} !important;
      transform: translateX(24px);
    }
  }

  ${media.md`
    ${fluidRange('padding-top', '16px', '24px', 'md', 'xxl')}
    ${fluidRange('padding-bottom', '16px', '24px', 'md', 'xxl')}
  `}

  @media (max-height: 1050px) {
    padding: 12px 0;
  }

  @media (max-height: 800px) {
    padding: 8px 0;
  }
`

const MenuList = styled.ul`
  padding: 24px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  transform-origin: 0 50%;
  transition: transform 0.2s ease-in-out;

  ${media.md`
    padding: 40px 16px;
  `}
`

const SelectedCategoryNameText = styled.span`
  color: ${palette('white')};
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
`

const SelectedCategoryName = styled.div`
  position: absolute;
  right: 8px;
  top: 240px;
  opacity: 0;
  width: 40px;
  transform-origin: 50% 0;
  transform: rotate(-90deg);
  transition: opacity 0.15s;
  display: flex;
  align-items: center;
  justify-content: center;

  &.isVisible {
    opacity: 1;
  }

  ${media.md`
    display: none;
  `}
`

const Menu = styled.div`
  position: relative;
  background-color: ${palette('blue')};
  width: calc(100% - 112px);
  min-height: 100%;
  border-right: 8px solid ${palette('green')};
  align-self: stretch;
  z-index: 1;
  overflow: hidden;
  pointer-events: auto;

  ${MenuList} {
    transition: opacity 0.15s;
    transition-delay: 0.15s;
  }

  &.hasSelectedCategory {
    position: fixed;
    width: 48px;
    z-index: 1;

    ${MenuList} {
      opacity: 0;
      transition: opacity 0s;
      transition-delay: 0;
    }
  }

  ${media.xs`
    width: 100%;
  `}

  ${media.md`
    width: 320px;
    min-width: 320px;
    min-height: calc(100% - 64px);

    &.hasSelectedCategory {
      position: relative;
      width: 320px;
      min-width: 320px;

      ${MenuList} {
        opacity: 1;
      }
    }
  `}

  ${media.lg`
    width: 400px;
    min-width: 400px;

    &.hasSelectedCategory {
      position: relative;
      width: 400px;
      min-width: 400px;
    }
  `}
`

const SubMenu = styled.div`
  position: absolute;
  background-color: ${palette('white')};
  min-height: 100%;
  flex-grow: 1;
  align-self: stretch;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.15s;
  width: 112px;
  padding-right: 0px;

  &.isVisible {
    opacity: 1;
    width: 100%;
    max-width: calc(100vw - 48px);
    margin-left: 48px;

    ${media.md`
      margin-left: 0;
      /* margin-left: 320px; */
    `}

    ${media.lg`
      /* margin-left: 400px; */
    `}
  }

  ${media.md`
    position: relative;
    width: unset;
    ${fluidRange('padding-top', '32px', '48px', 'md', 'xxl')}
    ${fluidRange('padding-bottom', '32px', '48px', 'md', 'xxl')}
    ${fluidRange('padding-left', '48px', '80px', 'md', 'xxl')}
  `}
`

const SubMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 120px);
  margin-top: 55px;
  overflow: auto;

  // column-fill: auto doesn't work properly in Firefox
  &.subMenuOverflow {
    @-moz-document url-prefix() {
      column-fill: balance;
    }
  }

  @media (min-width: 1100px) {
    display: block;
    column-count: 2;
    width: 90%;
    overflow: visible;
  }

  @media (min-width: 1440px) {
    column-count: 3;
  }

  @media (min-width: 1920px) {
    column-count: 4;
  }

  ${media.md`
    margin-top: 0;
  `}
`

const BackBtn = styled.button`
  display: none;
  position: fixed;
  z-index: 1;
  background-color: ${palette('white')};
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${palette('ui10Solid')};
  padding: 0 40px;
  font-size: 1.125rem;
  height: 56px;
  color: ${palette('blue')};
  font-weight: 500;
  text-align: left;

  .remixicon-icon {
    position: absolute;
    left: 10px;
    top: calc(50% - 12px);
  }

  &.isVisible {
    display: block;
  }

  ${media.md`
    &.isVisible {
      display: none;
    }
  `}
`

const Scroll = styled.div`
  overflow-x: auto;
`

const DrawerWrapper = transition.div.attrs({
  unmountOnExit: true,
  timeout: 100,
})`
  position: fixed;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${theme('zIndex.sideDrawer')};
  background-color: ${palette('ui60')};

  /* Safari fix for background flickering */
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1;


  &:enter {
    opacity: 0;

    ${DrawerInner} {
      transform: translateX(-100%);
    }
  }

  &:enter-active {
    opacity: 1;
    transition: opacity 0.25s;

    ${DrawerInner} {
      transform: 0;
    }
  }

  &:exit {
    opacity: 1;

    ${DrawerInner} {
      transform: 0;
    }
  }

  &:exit-active {
    opacity: 0;
    transition: opacity 0.25s;

    ${DrawerInner} {
      transform: translateX(-100%);
    }
  }
`

export default function CategoriesDrawer({
  isOpen,
  onRequestClose,
  onDutyFreeFrontPage,
}: Props): JSX.Element {
  const {categories} = useCommonContext()
  const subMenuRef = useRef<HTMLDivElement>()
  const [selectedCategory, setSelectedCategory] = useState<CategoryTreeItem>()
  const [subMenuOverflow, setSubMenuOverflow] = useState<boolean>()

  const onKeyDown = useCallback(
    ({key}) => {
      if (key === 'Escape' && !!onRequestClose) {
        onRequestClose()
      }
    },
    [onRequestClose],
  )

  useEffect(() => {
    if (isOpen) {
      // Animating in
      document.body.style.overflow = 'hidden'
    } else {
      // Animating out
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', onKeyDown)

      return () => window.removeEventListener('keydown', onKeyDown)
    }
    return null
  }, [onKeyDown])

  useEffect(() => {
    if (subMenuRef.current) {
      if (
        subMenuRef.current.clientHeight > window.innerHeight &&
        !subMenuOverflow
      ) {
        setSubMenuOverflow(true)
      }
    }
  }, [subMenuRef, selectedCategory, subMenuOverflow])

  function shouldClose({target}: React.BaseSyntheticEvent): void {
    if (
      onRequestClose &&
      (target?.id === 'menu-drawer-wrapper' ||
        (target?.id === 'menu-drawer-submenu' && !selectedCategory))
    ) {
      onRequestClose()
    }
  }

  const categoryTree = buildCategoryTree(categories)

  const handleClickMenuItem = useCallback(
    (category: CategoryTreeItem) => {
      setSubMenuOverflow(false)
      setSelectedCategory(
        category.id !== selectedCategory?.id ? category : undefined,
      )
    },
    [selectedCategory],
  )

  return (
    <DrawerWrapper
      id="menu-drawer-wrapper"
      onClick={shouldClose}
      in={isOpen}
      className={cn({hasSelectedCategory: !!selectedCategory})}
      aria-expanded={isOpen}
    >
      <FocusTrapWrapper isOpen={isOpen}>
        <DrawerInner
          className={cn({onDutyFreeFrontPage, noPointer: !selectedCategory})}
        >
          <MenuWrapper>
            <Menu className={cn({hasSelectedCategory: selectedCategory})}>
              <MenuList>
                {categoryTree.map((category: CategoryTreeItem) => (
                  <MenuItem key={category.id}>
                    <MenuItemBtn
                      onClick={() => handleClickMenuItem(category)}
                      className={cn(
                        selectedCategory?.id === category.id
                          ? 'selected'
                          : 'faded',
                      )}
                    >
                      <ArrowRightSLineIcon
                        size={32}
                        color={styledTheme.palette.green}
                      />
                      <MenuItemText>{category.name}</MenuItemText>
                    </MenuItemBtn>
                  </MenuItem>
                ))}
              </MenuList>

              <SelectedCategoryName
                className={cn({isVisible: selectedCategory})}
              >
                <SelectedCategoryNameText>
                  {selectedCategory?.name}
                </SelectedCategoryNameText>
              </SelectedCategoryName>
            </Menu>

            <SubMenu
              className={cn({isVisible: selectedCategory})}
              onClick={shouldClose}
              id="menu-drawer-submenu"
            >
              <BackBtn
                onClick={() => setSelectedCategory(undefined)}
                className={cn({isVisible: selectedCategory})}
              >
                <ArrowLeftSLineIcon
                  size={24}
                  color={styledTheme.palette.green}
                />
                Til baka
              </BackBtn>
              <Scroll>
                <SubMenuContent
                  ref={subMenuRef}
                  className={cn({subMenuOverflow})}
                >
                  {selectedCategory?.children?.map(
                    (subCategory: CategoryTreeItem) => (
                      <SubMenuCategoryItem
                        key={subCategory.id}
                        categoryItem={subCategory}
                        onClickCategory={onRequestClose}
                      />
                    ),
                  )}
                </SubMenuContent>
              </Scroll>
            </SubMenu>
          </MenuWrapper>
        </DrawerInner>
      </FocusTrapWrapper>
    </DrawerWrapper>
  )
}
