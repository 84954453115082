import React from 'react'

import Link from 'next/link'
import {media} from 'styled-bootstrap-grid'
import styled, {css} from 'styled-components'
import {ifProp, palette, prop, theme} from 'styled-tools'

import {CardTitle} from '@festi/common/components/typography'

interface Props {
  linkTo: string
  imgUrl: string
  title: string
  withGutter?: boolean
  keepLg?: boolean // This keeps the card from popping into mobile mode. Use with slider.
}

interface ImageProps {
  src: string
}

interface CardWrapperProps {
  withGutter: boolean
  keepLg: boolean
}

const Image = styled.div<ImageProps>`
  background-image: url(${prop('src')});
  position: relative;
  background-size: cover;
  background-position: center;

  &:before {
    content: '';
    display: block;
    position: relative;
    background-color: ${palette('ui30')};
    padding-bottom: 80%;
    transition: opacity 0.15s;
  }
`

const TitleContainer = styled.div`
  background-color: ${palette('white')};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
`

function smCardStyles() {
  return css`
    flex-direction: row;

    ${Image} {
      width: 25%;
    }

    ${TitleContainer} {
      width: 75%;
    }
  `
}

function lgCardStyles() {
  return css`
    flex-direction: column-reverse;

    ${Image} {
      width: 100%;
    }

    ${TitleContainer} {
      width: 100%;
    }

    ${media.md`
      ${Image} {
        &:before {
          padding-bottom: 110%;
        }
      }
    `}
  `
}

const CardWrapper = styled.a<CardWrapperProps>`
  ${ifProp({keepLg: true}, lgCardStyles, smCardStyles)}
  display: block;
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  border: 1px solid ${palette('ui20Solid')};
  transition: border-color 0.15s;
  margin-bottom: ${ifProp({withGutter: true}, '16px', '0')};
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: ${theme('boxShadow.card')};
    opacity: 0;
    transition: opacity 0.15s;
  }

  &:hover {
    border-color: ${palette('ui60')};

    &:before {
      opacity: 1;
    }

    ${Image} {
      &:before {
        opacity: 0;
      }
    }
  }

  ${media.sm`
    ${lgCardStyles}
  `}
`

export default function ImageCard({
  linkTo,
  imgUrl,
  title,
  withGutter,
  keepLg,
}: Props): JSX.Element {
  return (
    <Link href={linkTo || '#'} passHref legacyBehavior>
      <CardWrapper withGutter={withGutter} keepLg={keepLg}>
        <Image src={imgUrl} />
        <TitleContainer>
          <CardTitle>{title}</CardTitle>
        </TitleContainer>
      </CardWrapper>
    </Link>
  )
}
