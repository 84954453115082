import Link from 'next/link'
import {useRouter} from 'next/router'
import styled from 'styled-components'
import {Container, media} from 'styled-bootstrap-grid'

import settings from '@festi/common/constants/settings'
import {onDutyFree} from '@festi/common/constants/channels'

const Root = styled.div`
  position: relative;
`

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  min-height: 46px;
  padding: 10px 16px;
  color: white;
  background-color: black;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  a {
    color: #6dc4f2;
  }

  ${media.md`
    justify-content: center;
  `}
`

export default function DutyfreeBanner() {
  const {pathname} = useRouter()

  const onFrontPage = pathname === '/'

  if (!onFrontPage || !onDutyFree) return null

  return (
    <Root>
      <Container style={{padding: 0}}>
        <Banner>
          <span>
            Þú ert að skoða ELKO Duty Free á Keflavíkurflugvelli.&nbsp;
          </span>
          <Link href={settings.baseUrl}>
            Smelltu hér til að fara á aðalvef.
          </Link>
        </Banner>
      </Container>
    </Root>
  )
}
