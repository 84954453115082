import styled from 'styled-components'
import ErrorWarningFillIcon from 'remixicon-react/ErrorWarningFillIcon'

import {H6} from '@festi/common/components/typography'
import {styledTheme} from '@festi/common/themes'
import {useCheckout} from '@festi/common/contexts'
import {onDutyFree} from '@festi/common/constants/channels'

const DeliveryDetails = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr;
  padding: 8px 16px;
`

interface Props {
  sku?: string | null
  isInStock?: boolean
  borderTop?: boolean
}

export default function CartItemWarning({sku, isInStock, borderTop}: Props) {
  const {salesType} = useCheckout()

  const notInStore = salesType?.some(
    (item) =>
      item.sku === sku && item.warehouse !== (onDutyFree ? 'duty-free' : 'web'),
  )

  if (!notInStore && isInStock) return null

  return (
    <DeliveryDetails
      style={{
        backgroundColor: !isInStock
          ? styledTheme.palette.errorBack
          : styledTheme.palette.warningBack,
        borderTop: borderTop ? `1px solid ${styledTheme.palette.border}` : '',
      }}
    >
      <ErrorWarningFillIcon
        size={16}
        color={
          !isInStock ? styledTheme.palette.error : styledTheme.palette.warning
        }
      />
      <H6>
        {!isInStock
          ? 'Því miður er þessi vara uppseld í augnablikinu'
          : 'Þessi vara er ekki til á miðlægum lager, afhending mun tefjast um 1-2 virka daga.'}
      </H6>
    </DeliveryDetails>
  )
}
