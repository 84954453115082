import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  NormalizedCacheObject,
} from '@apollo/client'
import getConfig from 'next/config'
import urljoin from 'url-join'

let prevClient: ApolloClient<NormalizedCacheObject>

// TODO: cleanup when myaccount is migrated from static to next
const apiUrl =
  getConfig()?.publicRuntimeConfig?.datocmsUrl ||
  'https://graphql.datocms.com/'

// TODO: cleanup when myaccount is migrated from static to next
const apiToken =
  getConfig()?.publicRuntimeConfig?.datocmsToken ||
  ''

const commonConf = {
  headers: {
    Authorization: `Bearer ${apiToken}`,
  },
}

const link = createHttpLink({
  uri: apiUrl,
  ...commonConf,
})

export const createClient = () => {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    cache: new InMemoryCache(),
    link,
  })
}

const previewLink = createHttpLink({
  uri: urljoin(apiUrl, 'preview'),
  ...commonConf,
})

export const previewClient = new ApolloClient({
  ssrMode: typeof window === 'undefined', // set to true for SSR
  cache: new InMemoryCache(),
  link: previewLink,
})

export function client(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState: any = null,
): ApolloClient<NormalizedCacheObject> {
  const currApolloClient = prevClient ?? createClient()

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = currApolloClient.extract()

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    currApolloClient.cache.restore({...existingCache, ...initialState})
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return currApolloClient

  // Create the Apollo Client once in the client
  if (!prevClient) prevClient = currApolloClient
  return currApolloClient
}
