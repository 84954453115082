import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'

import {H5} from '@festi/common/components/typography'
import {onDutyFree} from '@festi/common/constants/channels'

const Root = styled.div`
  position: relative;
`

const Line = styled.div`
  position: fixed;
  top: 54px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 3px;
  z-index: 1000;
  background-color: black;

  ${media.md`
    top: 74px;
    justify-content: flex-start;
  `}
`

const Logo = styled.div`
  position: relative;
  margin: 0 16px;
  padding: 3px 6px;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    max-width: 70px;
    height: 26px;
    z-index: -1;
    border-radius: 4px;
    background-color: black;
    transform: rotate(-1deg);
  }

  ${H5} {
    color: white;
  }

  ${media.md`
    margin: 0 30px;
  `}
`

export default function DutyfreeLine() {
  if (!onDutyFree) return null
  return (
    <Root>
      <Line>
        <Logo>
          <H5 headline>Duty free</H5>
        </Logo>
      </Line>
    </Root>
  )
}
