import styled from 'styled-components'

import UserButton from './utilites/UserButton'
import StoreButton from './utilites/StoreButton'
import WishlistButton from './utilites/WishlistButton'
import CheckoutButton from './utilites/CheckoutButton'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

interface Props {
  onClickCart: () => void
  onClickLogin: () => void
  onClickWishlist: () => void
}
export default function HeaderUtilites({
  onClickLogin,
  onClickCart,
  onClickWishlist,
}: Props) {
  return (
    <Wrapper>
      <StoreButton />

      <WishlistButton onClick={onClickWishlist} />

      <CheckoutButton onClick={onClickCart} />

      <UserButton onClick={onClickLogin} />
    </Wrapper>
  )
}
