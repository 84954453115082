import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import range from 'lodash/range'
import sumBy from 'lodash/sumBy'
import toPairs from 'lodash/toPairs'
import ReactSlider from 'react-slider'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'
import {formatPrice} from '@festi/utils/numbers'

interface Props {
  label?: string
  min?: number
  max?: number
  minValue?: number
  maxValue?: number
  data?: object
  onChange?: (change: number[]) => void
}

const Wrapper = styled.div`
  position: relative;
  padding-top: 10px;
`

const Label = styled.div`
  color: ${palette('blue')};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 8px;
`

const Slider = styled(ReactSlider)`
  width: 100%;
  height: 6px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  .track-1 {
    background-color: ${palette('lightBlue')};
    opacity: 0.1;
  }
`

const SliderTrack = styled.div`
  height: 100%;
  background: ${palette('light')};
  border-radius: 0;
  border: 1px solid ${palette('ui10Solid')};
  &.track-1 {
    height: 100%;
    background-color: ${palette('blue')};
    border-color: 1px solid ${palette('blue')};
    opacity: 1;
  }
`

const SliderStick = styled.div`
  position: absolute;
  top: -3px;
  left: -6px;
  height: 12px;
  width: 12px;
  border: 0;
  border-radius: 10px;
  background-color: ${palette('blue')};
  cursor: grap;
`

const SliderStickWrapper = styled.div`
  position: relative;
  height: 100%;
  &:focus {
    outline: none;
  }
`

const RangeWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 4px;
`

const RangeLabel = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${palette('ui60Solid')};
  line-height: 1.2;
  text-transform: uppercase;
`

const PriceData = styled.div`
  height: 50px;
`

const PriceDataElement = styled.span`
  display: inline-block;
  justify-content: center;
`

const PriceRange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const Price = styled.span`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${palette('blue')};
  line-height: 1;
  text-align: left;
`

export default function PriceRangeSlider({
  label,
  min,
  max,
  minValue,
  maxValue,
  data,
  onChange,
}: Props): JSX.Element {
  const usedMin = min || 0
  const usedMax = max || 260000

  const steps = 100
  const calcDist = (usedMax - usedMin) / steps
  const priceArray: [string, string][] = toPairs(data)
  const priceSteps: {step: number; count: number}[] = priceArray.map(
    ([step, count]) => {
      const stepPoint =
        (parseInt(step) - usedMin) * (steps / (usedMax - usedMin))
      return {
        step: Math.min(Math.floor(stepPoint), steps - 1),
        count: parseInt(count),
      }
    },
  )
  const groupedByStep = groupBy(priceSteps, 'step')
  const itemsPerStep = mapValues(groupedByStep, (g) => sumBy(g, 'count'))
  const maxCount = Math.max(...Object.values(itemsPerStep))
  const priceCountArray = range(steps).map(
    (_, index) => itemsPerStep[index] || 0,
  )

  return (
    <Wrapper>
      {!!label && <Label>{label}</Label>}
      <PriceRange>
        <Price>{formatPrice(minValue)}</Price>
        <ArrowRightSLineIcon color={styledTheme.palette.lightBlue} />
        <Price>{formatPrice(maxValue)}</Price>
      </PriceRange>

      <PriceData>
        {priceCountArray.map((count, index) => (
          <PriceDataElement
            key={index}
            style={{
              height: `${(count / maxCount) * 100}%`,
              width: `${100 / steps}%`,
              backgroundColor: styledTheme.palette.lightBlue60,
            }}
          ></PriceDataElement>
        ))}
      </PriceData>

      <Slider
        min={usedMin}
        max={usedMax}
        value={[minValue, maxValue]}
        pearling
        minDistance={calcDist * 5}
        step={calcDist}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderTrack={(props: any) => <SliderTrack {...props} />}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderThumb={(props: any) => (
          <SliderStickWrapper {...props}>
            <SliderStick />
          </SliderStickWrapper>
        )}
        onChange={onChange}
      />

      <RangeWrapper>
        <RangeLabel>{formatPrice(usedMin)}</RangeLabel>
        <RangeLabel>{formatPrice(usedMax)}</RangeLabel>
      </RangeWrapper>
    </Wrapper>
  )
}
