import React from 'react'

import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {Modal, ModalSize} from '@festi/common/components/common'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
  image: string
}

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 50px;
  ${media.md`
    padding-top: 60px;
  `}
`

const Image = styled.img`
  max-width: 100%;
`

function EnergyImage({image}): JSX.Element {
  return (
    <ImageWrapper>
      <Image src={image} />
    </ImageWrapper>
  )
}

export default function EnergyImageModal({
  isOpen,
  image,
  onRequestClose,
}: Props): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      size={ModalSize.XSmall}
    >
      <EnergyImage image={image} />
    </Modal>
  )
}
