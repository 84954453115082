import React from 'react'

import Link from 'next/link'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import {SlideInnerProps} from 'src/components/common/CustomSlider'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {ifProp, palette, prop, theme} from 'styled-tools'

import {VariantList} from '@festi/common/api/rest'
import {IconButton} from '@festi/common/components/buttons'
import {MiniAddToCartButton} from '@festi/common/components/cart'
import {
  CardTitle,
  CardSubtitle,
  PriceText,
} from '@festi/common/components/typography'
import {getListingPrice} from '@festi/common/utils/price'
import {useSingleUserPrice} from '@festi/common/utils/rest'
import {fluidRange} from '@festi/common/utils/styles'
import {formatPrice} from '@festi/utils/numbers'

interface ImageProps {
  src: string
}

const ProductImage = styled.div<ImageProps>`
  background-image: url(${prop('src')});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  flex-grow: 1;
  margin-top: 10px;
  margin-bottom: 10px;
`

const Title = styled(CardTitle)`
  margin-bottom: 4px;
`

const ActionBtn = styled(IconButton)`
  position: absolute;
  bottom: 0;
  left: 0;
  transition: opacity 0.15s;

  ${media.sm`
    opacity: 0;
  `}
`

interface CardWrapperProps {
  keepSm?: boolean
}

const CardWrapper = styled.div<CardWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  padding: 16px;
  margin-bottom: ${ifProp({withGutter: true}, '16px', '0')};
  border: 1px solid ${palette('ui20Solid')};
  background-color: ${palette('white')};
  transition: border-color 0.15s;
  box-sizing: border-box;
  cursor: pointer;

  ${ifProp(
    {keepSm: true},
    'height: 260px',
    fluidRange('height', '260px', '500px'),
  )};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    box-shadow: ${theme('boxShadow.card')};
    transition: opacity 0.15s;
  }
`

interface WrapperProps {
  withGutter: boolean
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: ${ifProp({withGutter: true}, '16px', '0')};

  &:hover {
    ${CardWrapper} {
      border-color: ${palette('ui60')};

      &:before {
        opacity: 1;
      }
    }

    ${ActionBtn} {
      opacity: 1;
    }
  }
`

interface Props {
  variant: VariantList
  origin: string
  keepSm?: boolean // This keeps the card from popping into desktop mode. Use when the card is in a small sidebar f.x.
  withGutter?: boolean
  searchInsights?: () => void
}

export default function AdditionalProductCard({
  variant,
  origin,
  keepSm,
  isHidden,
  withGutter,
  onClick,
  onKeyDown,
  onMouseDown,
  searchInsights,
}: Props & SlideInnerProps): JSX.Element {
  const {data: userPrice} = useSingleUserPrice(variant?.sku)

  if (!variant) return null

  const {product, name, sku, firstImage} = variant
  const {slug} = product

  const linkTo = `/vorur/${slug}/${sku}`
  const price = userPrice?.lowestPrice ?? getListingPrice(variant)?.lowestPrice
  const image = firstImage?.image?.productList || '/images/placeholder.png'

  return (
    <Wrapper withGutter={withGutter}>
      <Link
        href={linkTo || '#'}
        prefetch={false}
        draggable={false}
        tabIndex={isHidden ? -1 : 0}
        onClick={(e) => {
          !!onClick && onClick(e)
          !!searchInsights && searchInsights()
        }}
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
      >
        <CardWrapper keepSm={keepSm}>
          <Title>{name}</Title>
          <CardSubtitle>{sku}</CardSubtitle>
          <ProductImage src={image} />
          <PriceText>{formatPrice(price)}</PriceText>
        </CardWrapper>
      </Link>

      <MiniAddToCartButton
        variant={variant}
        icon={AddLineIcon}
        origin={origin}
        searchInsights={searchInsights}
      />
    </Wrapper>
  )
}
