import React, {useMemo, useState, useCallback} from 'react'

import Slider, {Settings} from 'react-slick'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import {ProductTags} from 'src/components/products'
import ProductImageBanner, {
  itemBannerColorMap,
} from 'src/components/products/ProductImageBanner'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, prop, ifProp} from 'styled-tools'

import {VariantDetail} from '@festi/common/api/rest'
import {FullScreenModal} from '@festi/common/components/common'
import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'

import FullScreenImageGallery from './FullScreenImageGallery'

interface SlideProps {
  selected?: boolean
}

interface ImgProps {
  src: string
}

export interface ArrowBtnProps {
  bottom?: boolean
  left?: boolean
  right?: boolean
}

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

interface WrapperProps {
  highlightColor?: string
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  ${fluidRange('margin-bottom', '10px', '24px')}
  border: 2px solid transparent;
  border-color: ${({highlightColor}) => highlightColor};
`

const MobileMiniContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  max-width: 430px;
  margin-right: 16px;

  .slick-track {
    height: auto !important;
  }
`

const CarouselLayoutContainer = styled.div`
  //// slick-track is the container of all slide items
  .slick-track {
    display: flex;
  }
  // slick slide is the container of a single slide item. There is also a wrapper div before our custom component
  .slick-slide {
    display: flex;
    height: unset;
    > div {
      display: flex;
      width: 100%;
      > div {
        align-self: center;
      }
    }
  }
`

const MobileMiniArrowBtn = styled.button<ArrowBtnProps>`
  position: absolute;
  top: calc(50% - 14px);
  left: ${ifProp({left: true}, '-34px', 'unset')};
  right: ${ifProp({right: true}, '-34px', 'unset')};
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`

const MiniSlide = styled.div<SlideProps>`
  width: 100%;
  margin: 2px 0;
  border: 2px solid
    ${ifProp({selected: true}, palette('lightBlue'), 'transparent')};
  transition: border-color 0.15s;
  cursor: pointer;

  &:focus {
    outline: none !important;
  }

  ${media.sm`
    margin: 4px 0;
  `}
`

const MiniImage = styled.div<ImgProps>`
  position: relative;
  width: 100%;
  background-image: url(${prop('src')});
  background-color: ${palette('white')};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`

const MainContainer = styled.div`
  width: 84%;
  max-width: 430px;
  flex-grow: 1;
`

const MainSlide = styled.div`
  width: 100%;
  padding: 0 16px;
  cursor: pointer;

  &:focus {
    outline: none !important;
  }
  .demoupUI-img {
    height: 176px !important;

    ${media.md`
    height: 225px !important;
  `}
  }
`

const MainImage = styled.div<ImgProps>`
  position: relative;
  width: 100%;
  background-image: url(${prop('src')});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  ${media.sm`
    &:before {
      padding-bottom: 110%;
    }
  `}
`

const MainImageBtn = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  outline: none !important;
  background-color: transparent;
  cursor: pointer;
`

const MiniWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 94px;
`

interface TagsProps {
  children: React.ReactNode
}

const TagsWrapper = styled.div`
  display: flex;
  margin-bottom: 25px;

  ${media.md`
    flex-direction: column;
  `}
`

const TagsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
`

function Tags({children}: TagsProps): JSX.Element {
  return children ? <TagsWrapper>{children}</TagsWrapper> : null
}

interface Props {
  variant?: VariantDetail
}

export default function ProductImageGallery({variant}: Props): JSX.Element {
  const [currSlide, setCurrSlide] = useState<number>(0)
  const [miniSlider, setMiniSlider] = useState<Slider>(undefined)
  const [mainSlider, setMainSlider] = useState<Slider>(undefined)
  const [slideClicked, setSlideClicked] = useState<boolean>(false)
  const numSlides = variant.images.length

  const [bannerColor] = itemBannerColorMap[variant.banner] ?? []
  const isRecommended = variant.recommended

  const highlightColor = bannerColor
    ? bannerColor
    : isRecommended && palette('green')

  const mainSettings: Settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 400,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    beforeChange: (_: number, index: number) => setCurrSlide(Math.ceil(index)),
  }

  const mobileMiniSettings: Settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    waitForAnimate: false,
    focusOnSelect: true,
  }

  const focusNextSlide = useCallback(
    (e: React.KeyboardEvent) => {
      if (currSlide < numSlides - 1) {
        e.preventDefault()
        mainSlider?.slickNext()
        setCurrSlide(currSlide + 1)
      }
    },
    [currSlide, numSlides, setCurrSlide, mainSlider],
  )

  const focusPrevSlide = useCallback(
    (e: React.KeyboardEvent) => {
      if (currSlide > 0) {
        e.preventDefault()
        mainSlider?.slickPrev()
        setCurrSlide(currSlide - 1)
      }
    },
    [currSlide, setCurrSlide, mainSlider],
  )

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Tab' && e.shiftKey) {
        focusPrevSlide(e)
      } else if (e.key === 'Tab') {
        focusNextSlide(e)
      }
    },
    [focusPrevSlide, focusNextSlide],
  )

  function handleSlideClick() {
    setSlideClicked(true)
  }

  const miniImageArrayWithVideo = useMemo(() => {
    const imageArray = variant.images.map((img, index) => (
      <MiniSlide key={img.id} selected={index === currSlide}>
        <MiniImage src={img.image.productGallery} />
      </MiniSlide>
    ))
    return imageArray
  }, [variant.images, currSlide])

  const mainImageArrayWithVideo = useMemo(() => {
    const imageArray = variant.images.map((img, index) => (
      <MainSlide key={img.id}>
        <MainImageBtn
          onClick={() => handleSlideClick()}
          onKeyDown={onKeyDown}
          tabIndex={currSlide === index ? 0 : -1}
          aria-label="Myndagallerí"
        >
          <MainImage src={img.image.productGallery} />
        </MainImageBtn>
      </MainSlide>
    ))
    return imageArray
  }, [variant.images, currSlide, onKeyDown])

  return (
    <GalleryContainer>
      <Wrapper highlightColor={highlightColor}>
        <ProductImageBanner variant={variant} />

        <TagsContainer>
          <Tags>
            <ProductTags variant={variant} />
          </Tags>
        </TagsContainer>

        <MainContainer>
          <CarouselLayoutContainer>
            <Slider
              ref={(ref) => setMainSlider(ref)}
              asNavFor={miniSlider}
              {...mainSettings}
            >
              {mainImageArrayWithVideo}
            </Slider>
          </CarouselLayoutContainer>
        </MainContainer>
      </Wrapper>

      <MiniWrapper>
        {numSlides > 1 && (
          <MobileMiniContainer>
            {numSlides > 5 && (
              <MobileMiniArrowBtn
                left
                onClick={() => miniSlider.slickPrev()}
                disabled={currSlide <= 0}
                aria-label="previous"
              >
                <ArrowLeftSLineIcon
                  size={28}
                  color={styledTheme.palette.lightBlue}
                />
              </MobileMiniArrowBtn>
            )}

            <CarouselLayoutContainer>
              <Slider
                ref={(ref) => setMiniSlider(ref)}
                asNavFor={mainSlider}
                {...mobileMiniSettings}
              >
                {miniImageArrayWithVideo}
              </Slider>
            </CarouselLayoutContainer>

            {numSlides > 5 && (
              <MobileMiniArrowBtn
                right
                onClick={() => miniSlider.slickNext()}
                disabled={currSlide >= numSlides - 1}
                aria-label="next"
              >
                <ArrowRightSLineIcon
                  size={28}
                  color={styledTheme.palette.lightBlue}
                />
              </MobileMiniArrowBtn>
            )}
          </MobileMiniContainer>
        )}
      </MiniWrapper>

      <FullScreenModal
        isOpen={slideClicked}
        onRequestClose={() => setSlideClicked(false)}
      >
        <FullScreenImageGallery
          images={variant.images}
          currentSlide={currSlide}
          setCurrentSlide={setCurrSlide}
        />
      </FullScreenModal>
    </GalleryContainer>
  )
}
