import {gql} from '@apollo/client'

export const commonDatocmsContentQuery = gql`
  query CommonDatocmsContent {
    navigationBar {
      id
      logoMobile {
        url
        alt
      }
      logoDesktop {
        url
        alt
      }
      categoriesLabel
      searchBarPlaceholder
      loginLabel
      cartLabel
    }
    footer {
      emailAddress
      emailAddressLabel
      emailAddressHref
      phoneNumber
      phoneNumberLabel
      phoneNumberHref
    }
    loginSignUp {
      id
      loginTitle
      loginDescription
      signUpTitle
      signUpDescription
      emailPlaceholder
      passwordPlaceholder
      passwordAgainPlaceholder
      rememberMeLabel
      forgotPasswordLabel
      loginSubmitButton
      loginSwitchButtonLabel
      signUpSubmitButton
      signUpSwitchButtonLabel
    }
  }
`
