import {styledTheme} from '@festi/common/themes'
import * as LabelPrimitive from '@radix-ui/react-label'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import classNames from 'classnames'
import styled from 'styled-components'

interface RadioGroupProps extends RadioGroupPrimitive.RadioGroupProps {
  items: {label?: string | React.ReactNode; value: string; id: string}[]
  strong?: boolean
}

const RadioGroupRoot = styled(RadioGroupPrimitive.Root)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const RadioItemWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled(LabelPrimitive.Root)`
  font-size: 16px;
  font-weight: 400;
  color: ${styledTheme.palette.blue};
  padding-left: 16px;
  user-select: none;
  cursor: ${styledTheme.cursor.pointer};

  &.strong {
    font-weight: 500;
  }
`

const RadioGroupItem = styled(RadioGroupPrimitive.Item)`
  border: 1px solid ${styledTheme.palette.ui20Solid};
  background-color: ${styledTheme.palette.white};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  cursor: ${styledTheme.cursor.pointer};

  &[data-state='checked'] {
    border-color: ${styledTheme.palette.lightBlue};
  }
  &[data-disabled] {
    cursor: not-allowed;
  }
  &[data-disabled] + ${Label} {
    cursor: not-allowed;
  }
`

const RadioGroupIndicator = styled(RadioGroupPrimitive.Indicator)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${styledTheme.palette.lightBlue};
  }
`

export default function RadioGroup({
  items,
  strong,
  ...props
}: RadioGroupProps): JSX.Element {
  return (
    <RadioGroupRoot {...props}>
      {items.map((item) => (
        <RadioItem
          key={item.id}
          label={item.label}
          value={item.value}
          id={item.id}
          strong={strong}
        />
      ))}
    </RadioGroupRoot>
  )
}

interface RadioItemProps {
  label?: string | React.ReactNode
  value: string
  id: string
  strong?: boolean
}

export function RadioItem({
  label,
  value,
  id,
  strong = false,
}: RadioItemProps): JSX.Element {
  return (
    <RadioItemWrapper>
      <RadioGroupItem value={value} id={id}>
        <RadioGroupIndicator />
      </RadioGroupItem>
      {label && (
        <Label className={classNames({strong})} htmlFor={id}>
          {label}
        </Label>
      )}
    </RadioItemWrapper>
  )
}
