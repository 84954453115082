import {useState} from 'react'

import {Auth} from '@aws-amplify/auth'
import {validate as emailValid} from 'email-validator'
import {withFormik, FormikProps} from 'formik'
import {NextRouter, useRouter} from 'next/router'
import TagManager from 'react-gtm-module'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import styled from 'styled-components'

import {fieldErrorCodeMap} from '@festi/common/constants'

import {Button, OutlinedButton, TextButton} from '../buttons'
import {StatusMessage} from '../common'
import {Checkbox, FormikInput} from '../form'
import {H3, H4} from '../typography'

interface Props {
  inCheckout?: boolean
  initialEmail?: string
  onClickSwitch?: () => void
  onClickForgotPass?: () => void
}

interface FormValues {
  email: string
  password: string
  rememberMe: boolean
}

interface MyFormProps {
  router?: NextRouter
  inCheckout?: boolean
  initialEmail?: string
  onClickForgotPass: () => void
  setErrorMsg: (msg: string) => void
}

interface ErrorProps {
  email?: string
  password?: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 16px;
  }
`

const VerticalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  button {
    margin-bottom: 0;
  }
`

const LoginForm = ({
  inCheckout,
  isSubmitting,
  handleSubmit,
  onClickForgotPass,
}: FormikProps<FormValues> & MyFormProps): JSX.Element => {
  return (
    <form onSubmit={handleSubmit}>
      <FormikInput
        name="email"
        type="email"
        placeholder="Netfang"
        characterLimit={255}
      />
      <FormikInput name="password" type="password" placeholder="Lykilorð" />
      <VerticalWrapper>
        <Checkbox name="rememberMe" label="Muna eftir mér" />
        <TextButton type="button" onClick={onClickForgotPass}>
          Gleymt lykilorð?
        </TextButton>
      </VerticalWrapper>
      <Button
        type="submit"
        withBar
        loading={isSubmitting}
        icon={ArrowRightSLineIcon}
        accentColor={inCheckout ? 'green' : 'lightBlue'}
      >
        Innskrá
      </Button>
    </form>
  )
}

const LoginFormik = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: ({initialEmail}) => ({
    email: initialEmail,
    password: '',
    rememberMe: false,
  }),

  validate: ({email, password}) => {
    const errors: ErrorProps = {}

    if (!email) {
      errors.email = 'Hér vantar netfang'
    } else if (!emailValid(email)) {
      errors.email = 'Netfangið er ekki á réttu formi'
    }

    if (!password) {
      errors.password = 'Hér vantar lykilorð'
    }

    return errors
  },

  handleSubmit: (
    {email, password, rememberMe},
    {props: {setErrorMsg, inCheckout, router}},
  ) => {
    return Auth.signIn({
      username: email,
      password,
    })
      .then((res) => {
        TagManager.dataLayer({
          dataLayer: {
            category: 'login',
            user_id: res.userSub,
            event: 'minar sidur',
            event_label: rememberMe ? 'remember me true' : 'remember me false',
          },
          dataLayerName: 'dataLayer',
        })

        if (inCheckout) {
          router.push('/kaup/')
        }
      })
      .catch((err) => {
        const errorMsg = fieldErrorCodeMap[err?.code]
        setErrorMsg(errorMsg || 'Óþekkt villa, reynið aftur síðar')

        if (!errorMsg) {
          throw err
        }
      })
  },

  displayName: 'LoginForm',
})(LoginForm)

export default function LoginBlock({
  inCheckout,
  initialEmail,
  onClickSwitch,
  onClickForgotPass,
}: Props): JSX.Element {
  const router = useRouter()
  const [errorMsg, setErrorMsg] = useState<string>(null)

  return (
    <Wrapper>
      {inCheckout ? (
        <H4 withGutter>Skráðu þig inn til að auðvelda kaupin</H4>
      ) : (
        <H3 withGutter>Innskráning</H3>
      )}
      {!!errorMsg && <StatusMessage isError>{errorMsg}</StatusMessage>}
      <LoginFormik
        setErrorMsg={setErrorMsg}
        onClickForgotPass={onClickForgotPass}
        initialEmail={initialEmail || ''}
        inCheckout={inCheckout}
        router={router}
      />
      <OutlinedButton onClick={onClickSwitch}>Búa til aðgang</OutlinedButton>
    </Wrapper>
  )
}
