/* eslint-disable import/no-unresolved */
// TODO: Remove line above when cleave.js/react/props import is fixed
import React from 'react'

import Cleave from 'cleave.js/react'
import {
  Props as CleaveProps,
  ReactInstanceWithCleave,
} from 'cleave.js/react/props'
import {useField, FieldConfig} from 'formik'
import styled, {StyledComponent} from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'

import {InputProps, InputSleave, Highlight} from './Input'

export interface CleaveInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  showValidBadge?: boolean
  errorMsg?: string
  info?: string
  link?: string
  linkLabel?: string
  highlight?: Highlight
}

/**
 * Internal Cleave.js React instance API methods.
 */
interface CleaveInstanceInternalAPI {
  updateValueState: () => void
}

const StyledCleaveInput: StyledComponent<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.ComponentClass<CleaveProps, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  InputProps,
  never
> = styled(Cleave)<InputProps>`
  position: relative;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0;
  z-index: 0;
  padding: 14px 42px 0 16px;
  font-weight: 500;
  font-size: 1rem;
  color: ${palette('blue')};
  border-bottom: 1px solid ${palette('ui20Solid')};
  caret-color: ${palette('blue')};
  background-color: ${palette('white')};
  transition: opacity 0.15s;

  &:disabled {
    opacity: 0.5;
  }
`

const handleInit = (owner: ReactInstanceWithCleave) => {
  const internalApiOwner = owner as ReactInstanceWithCleave &
    CleaveInstanceInternalAPI
  internalApiOwner.updateValueState()
}

export default function CleaveInput({
  showValidBadge,
  errorMsg,
  placeholder,
  info,
  link,
  linkLabel,
  highlight,
  ...props
}: CleaveProps & InputProps): JSX.Element {
  return (
    <InputSleave
      info={info}
      link={link}
      placeholder={placeholder}
      errorMsg={errorMsg}
      linkLabel={linkLabel}
      highlight={highlight}
      showValidBadge={showValidBadge}
    >
      <StyledCleaveInput
        onInit={(owner) => handleInit(owner)}
        placeholder={placeholder}
        style={{
          borderColor: errorMsg
            ? styledTheme.palette.error
            : styledTheme.palette.ui20Solid,
        }}
        {...props}
      />
    </InputSleave>
  )
}

export function FormikCleaveInput(
  props: CleaveProps &
    CleaveInputProps &
    React.InputHTMLAttributes<HTMLInputElement> &
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FieldConfig<any>,
): JSX.Element {
  const [field, meta] = useField(props)
  const errorMsg = !!meta.touched ? meta.error : ''

  return (
    <CleaveInput
      onInit={(owner) => handleInit(owner)}
      errorMsg={errorMsg}
      {...field}
      {...props}
    />
  )
}
