import {useCallback, useMemo, useState} from 'react'

import Link from 'next/link'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {useUpdateEffect} from 'react-use'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import FunctionLineIcon from 'remixicon-react/FunctionLineIcon'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'

import {H6, P} from '@festi/common/components/typography'
import {
  CategoryTreeItem,
  buildCategoryTree,
} from '@festi/common/utils/categories'
import {hideScrollbar} from '@festi/common/styles'
import {useCommonContext} from '@festi/common/contexts/common'
import {CloseButton} from '@festi/common/components/buttons'
import {NewSearchInput} from '@festi/common/components/form'

import Toggle from './Toggle'
import HeaderLogo from '../header/HeaderLogo'
import PageNavigation from './PageNavigation'
import {CategoryItem, Item} from './CategoryItem'

const flattenCategoryTree = (tree: CategoryTreeItem[]): CategoryTreeItem[] => {
  return tree.reduce((acc: CategoryTreeItem[], item: CategoryTreeItem) => {
    acc.push(item)
    if (item.children) {
      acc = acc.concat(flattenCategoryTree(item.children))
    }
    return acc
  }, [])
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
`

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 60%;
  background-color: #000e52;
`

const Relative = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  max-width: 376px;
  padding: 12px 0;
  background-color: ${palette('ui4Solid')};
  z-index: 1000;

  ${media.md`
    position: sticky;
  `}
`

const FakeHeader = styled.div`
  display: none;
  align-items: center;
  padding: 1px 16px 16px;
  border-bottom: 1px solid ${palette('border')};

  ${media.md`
    display: flex;
  `}
`

const Click = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;
  padding: 0;
  border: none;
  background-color: transparent;
  padding-right: 30px;
  cursor: pointer;
`

const Searchbar = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 16px;
`

const Selector = styled.div`
  padding: 0 16px;
`

const Category = styled.div`
  overflow: auto;
`

const Navigation = styled.div`
  padding: 0 24px;
  overflow: auto;
`

const SubCategory = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 376px;
  padding-bottom: 20px;
  z-index: 1000;
  overflow: auto;
  background-color: #f6f7f9;
  border-left: 1px solid ${palette('border')};
  ${hideScrollbar}

  ${media.md`
    position: sticky;
    padding: 20px 0;
  `}
`

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
`

const Mobile = styled.div`
  display: block;
  ${media.md`
    display: none;
  `}
`

const CategoryHeader = styled(H6)`
  width: auto;
  color: #8086a8;
  text-transform: uppercase;

  ${media.md`
    padding-left: 26px;
  `}
`

const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 50%;
  background-color: ${palette('ui10Solid')};
  cursor: pointer;
`

const menuToggleItems = ['Vörur', 'Upplýsingar']

interface Props {
  openFromFooter?: boolean
  handleClose: () => void
}

export default function CategoryMenu({openFromFooter, handleClose}: Props) {
  const [catSearch, setCatSearch] = useState('')
  const [menuSelected, setMenuSelected] = useState(menuToggleItems[0])
  const [selectedCategory, setSelectedCategory] =
    useState<CategoryTreeItem | null>(null)
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<CategoryTreeItem | null>(null)

  const {categories} = useCommonContext()
  const categoryTree = buildCategoryTree(categories)

  const searchFilter = useMemo<CategoryTreeItem[] | []>(
    () =>
      flattenCategoryTree(categoryTree).filter((category) =>
        category.name.toLowerCase().includes(catSearch.toLowerCase()),
      ),
    [categoryTree, catSearch],
  )
  const handleSearchCategory = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCatSearch(e.target.value)
      setSelectedCategory(null)
      setSelectedSubCategory(null)
    },
    [],
  )

  const handleToggle = useCallback((value: string) => {
    setMenuSelected(value)
  }, [])

  const handleSelectCategory = useCallback(
    (category: CategoryTreeItem) => {
      setSelectedCategory(
        selectedCategory?.id !== category?.id ? category : null,
      )
      setSelectedSubCategory(null)
    },
    [selectedCategory],
  )

  const handleSelectSubCategory = useCallback(
    (category: CategoryTreeItem) => {
      setSelectedSubCategory(
        selectedSubCategory?.id !== category?.id ? category : null,
      )
    },
    [selectedSubCategory],
  )

  useUpdateEffect(() => {
    if (openFromFooter) {
      setMenuSelected(menuToggleItems[1])
    }
  }, [openFromFooter])

  return (
    <Wrapper>
      <Mask onClick={handleClose} />

      <Relative>
        <Container>
          <FakeHeader>
            <HeaderLogo />

            <Click onClick={handleClose}>
              <div>
                <CloseLineIcon size={24} />
              </div>
              <P bold>Loka</P>
            </Click>
          </FakeHeader>

          <Searchbar>
            <NewSearchInput
              type="text"
              placeholder="Leita að vöruflokk..."
              onChange={handleSearchCategory}
              small
            />

            <Mobile>
              <CloseButton onClick={handleClose} />
            </Mobile>
          </Searchbar>

          <Selector>
            <Toggle
              selected={menuSelected}
              toggleItems={menuToggleItems}
              handleToggle={handleToggle}
            />
          </Selector>

          {catSearch ? (
            <Category>
              {searchFilter?.map((category, index) => (
                <CategoryItem
                  key={index}
                  category={category}
                  selected={selectedCategory?.id === category?.id}
                  onClick={() => handleSelectCategory(category)}
                  handleClose={handleClose}
                />
              ))}
            </Category>
          ) : (
            <>
              {menuSelected === 'Vörur' && (
                <Category>
                  {categoryTree?.map((category, index) => (
                    <CategoryItem
                      key={index}
                      category={category}
                      selected={selectedCategory?.id === category?.id}
                      onClick={() => handleSelectCategory(category)}
                      handleClose={handleClose}
                    />
                  ))}
                </Category>
              )}

              {menuSelected === 'Upplýsingar' && (
                <Navigation>
                  <PageNavigation onRequestClose={handleClose} />
                </Navigation>
              )}
            </>
          )}
        </Container>

        {selectedCategory && (
          <SubCategory>
            <Heading>
              <Mobile>
                <CircleButton onClick={() => setSelectedCategory(null)}>
                  <ArrowLeftSLineIcon />
                </CircleButton>
              </Mobile>

              <CategoryHeader>{selectedCategory.name}</CategoryHeader>

              <Mobile>
                <CloseButton onClick={handleClose} />
              </Mobile>
            </Heading>

            <Link href={`/voruflokkar/${selectedCategory.slug}`}>
              <Item>
                <FunctionLineIcon size={24} />
                <span>Skoða {selectedCategory.name.toLowerCase()}</span>
              </Item>
            </Link>

            <Category>
              {selectedCategory.children?.map((category, index) => (
                <CategoryItem
                  key={index}
                  category={category}
                  selected={selectedSubCategory?.id === category?.id}
                  onClick={() => handleSelectSubCategory(category)}
                  handleClose={handleClose}
                />
              ))}
            </Category>
          </SubCategory>
        )}

        {selectedSubCategory && (
          <SubCategory>
            <Heading>
              <Mobile>
                <CircleButton onClick={() => setSelectedSubCategory(null)}>
                  <ArrowLeftSLineIcon />
                </CircleButton>
              </Mobile>

              <CategoryHeader>{selectedSubCategory.name}</CategoryHeader>

              <Mobile>
                <CloseButton onClick={handleClose} />
              </Mobile>
            </Heading>

            <Link href={`/voruflokkar/${selectedSubCategory.slug}`}>
              <Item>
                <FunctionLineIcon size={24} />
                <span>Skoða {selectedSubCategory.name.toLowerCase()}</span>
              </Item>
            </Link>

            <Category>
              {selectedSubCategory.children?.map((category, index) => (
                <CategoryItem
                  key={index}
                  category={category}
                  handleClose={handleClose}
                />
              ))}
            </Category>
          </SubCategory>
        )}
      </Relative>
    </Wrapper>
  )
}
