import React from 'react'

import cn from 'classnames'
import sanitizeHtml from 'sanitize-html'
import styled from 'styled-components'
import {palette} from 'styled-tools'

interface Props {
  point: string
  isFeatured?: boolean
}

const Point = styled.div`
  line-height: 1.2;
  font-weight: 400;
  text-align: left;
  color: ${palette('blue')};
  padding-left: 16px;
  margin-bottom: 8px;
  position: relative;
  font-size: 1rem;

  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 2px;
    border-radius: 2px;
    background-color: ${palette('lightBlue')};
    position: absolute;
    top: 10px;
    left: 0;
  }

  a {
    color: ${palette('lightBlue')};
    font-size: inherit;
    font-weight: inherit;
    cursor: pointer;
  }

  &.isFeatured {
    font-size: 1.2rem;
  }
`

export default function SellingPoint({
  point,
  isFeatured = false,
}: Props): JSX.Element {
  return (
    <Point
      dangerouslySetInnerHTML={{__html: sanitizeHtml(point)}}
      className={cn({isFeatured})}
    />
  )
}
