import React from 'react'

import {FieldConfig} from 'formik'

import {FormikCleaveInput, CleaveInputProps} from './CleaveInput'

export default function SsnInput(
  props: CleaveInputProps &
    React.InputHTMLAttributes<HTMLInputElement> &
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FieldConfig<any>,
): JSX.Element {
  return (
    <FormikCleaveInput
      {...props}
      options={{
        numericOnly: true,
        blocks: [6, 4],
        delimiter: ' ',
      }}
    />
  )
}
