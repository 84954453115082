const busnessDrawerName = 'openBusinessDrawer'

export const showBusinessDrawer = (): boolean => {
  const businessOpen = localStorage.getItem(busnessDrawerName) ?? 'true'
  return JSON.parse(businessOpen)
}

export const businessDrawerLocalStorgeShow = (show?: boolean) => {
  localStorage.setItem(busnessDrawerName, JSON.stringify(show))
}
