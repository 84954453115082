import {useCallback} from 'react'

import Link from 'next/link'
import {useRouter} from 'next/router'
import {Auth} from '@aws-amplify/auth'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import UserLineIcon from 'remixicon-react/UserLineIcon'
import Building4LineIcon from 'remixicon-react/Building4LineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

import {H5} from '@festi/common/components/typography'
import {useAuth} from '@festi/common/contexts'
import {CustomPopover} from '@festi/common/components/common'

import UtilityButton from './UtilityButton'
import CompanySelect from './CompanySelect'

export interface NavItem {
  label: string
  pagePath: string
}

const navItems: NavItem[] = [
  {
    label: 'Nótur',
    pagePath: '/minar',
  },
  {
    label: 'Mínar upplýsingar',
    pagePath: '/minar/upplysingar',
  },
  {
    label: 'Fyrirtæki',
    pagePath: '/minar/fyrirtaeki',
  },
]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 320px;
  padding: 32px;

  &.small {
    padding: 24px 32px;
  }

  ${media.md`
    width: 400px;
  `}
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${palette('ui5Solid')};
`

const User = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding-bottom: 16px;
`

const AccountType = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${palette('ui80Solid')};

  .remixicon-icon {
    fill: ${palette('ui50Solid')};
  }
`

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.125rem;
  font-weight: 500;
  color: ${palette('link')};
  padding: 8px 0;
  cursor: pointer;

  .remixicon-icon {
    fill: ${palette('ui50Solid')};
  }
`

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  font-weight: 500;
  border-radius: 50%;
  color: ${palette('blue')};
  border: 1px solid ${palette('grey')};
  background-color: ${palette('ui10Solid')};
`

const createInitials = (name?: string) =>
  name
    ?.split(' ')
    ?.map((name) => name[0])
    ?.join('') || 'N/A'

function UserImage() {
  const {user, business} = useAuth()

  const initials = business?.id
    ? createInitials(business?.name)
    : createInitials(user?.name)

  return (
    <Circle>
      <span>{initials}</span>
    </Circle>
  )
}

interface Props {
  onClick: () => void
}

export default function UserButton({onClick}: Props) {
  const {user, business, selectedBusiness, businesses} = useAuth()
  const {reload} = useRouter()

  const initials = business?.id
    ? createInitials(business?.name)
    : createInitials(user?.name)

  const handleLogout = useCallback(() => {
    Auth.signOut()
    localStorage.removeItem('backdoorToken')
    reload()
  }, [reload])

  if (!user?.id) {
    return <UtilityButton icon={UserLineIcon} onClick={onClick} />
  }

  return (
    <CustomPopover
      trigger={
        <UtilityButton
          icon={selectedBusiness ? Building4LineIcon : UserLineIcon}
          title={initials}
        />
      }
      content={
        <>
          <Container>
            <User>
              {user?.id && <UserImage />}

              <div style={{flex: 1}}>
                <H5>{business?.name || user?.name}</H5>
                <AccountType>
                  {business?.id ? (
                    <>
                      <span>Fyrirtæki</span>
                      <Building4LineIcon size={15} />
                    </>
                  ) : (
                    <>
                      <span>Persónulegur aðgangur</span>
                      <UserLineIcon size={15} />
                    </>
                  )}
                </AccountType>
              </div>
            </User>

            {!!businesses?.results?.length && <CompanySelect />}
          </Container>

          <Line />

          <Container className="small">
            {navItems.map(({label, pagePath}) => (
              <Link key={label} href={pagePath}>
                <Nav>
                  <span>{label}</span>
                  <div>
                    <ArrowRightSLineIcon />
                  </div>
                </Nav>
              </Link>
            ))}

            <Nav onClick={handleLogout}>Útskráning</Nav>
          </Container>
        </>
      }
    />
  )
}
