/* eslint-disable no-empty */
import {useRef} from 'react'

import algoliasearchHelper from 'algoliasearch-helper'
import algoliasearch from 'algoliasearch/lite'
import {addSeconds} from 'date-fns'
import fromPairs from 'lodash/fromPairs'
import mapValues from 'lodash/mapValues'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import toPairs from 'lodash/toPairs'
import useSWR from 'swr'
import type {Fetcher, Key, SWRResponse, SWRConfiguration} from 'swr'

import settings from '../constants/settings'
import {CommonContextProps} from '../contexts/common'
import {VariantList} from './rest'
import {onDutyFree} from '../constants/channels'

const clientId = settings.algoliaApplicationId
const apiKey = settings.algoliaApiKey
const envPrefix = settings.algoliaEnvPrefix
const pricePath = settings.channelPricePath
const channelListingPath = settings.channelListingPath

export const ruleContext = onDutyFree ? 'dutyfree_store' : 'elko_store'

export const searchClient =
  clientId && apiKey ? algoliasearch(clientId, apiKey) : undefined

export const searchIndexes = {
  productVariants: `${envPrefix}_products`,
}

export const productVariantSearch = searchClient
  ? algoliasearchHelper(searchClient, searchIndexes.productVariants)
  : undefined

export type AlgoliaSearchResults<T> = algoliasearchHelper.SearchResults<T>

export function useAlgoliaSWR<Data = any, Error = any>(
  key: Key,
  fetcher: Fetcher<Data> | null,
  config: SWRConfiguration<Data, Error> = {},
): SWRResponse<Data, Error> {
  // SWR wants data to always be undefined while fetching.
  // This would be no good for suggestions as it would cause
  // a flicker. Here we have a little hack to counter it.
  const dataRef = useRef<Data>()
  const {data, ...res} = useSWR(key, fetcher, {
    ...config,
    revalidateOnFocus: false,
  })
  if (data != null) {
    dataRef.current = data
  }
  return {data: dataRef.current, ...res}
}

export const productVariantSortOptions = [
  {value: 'popular', label: 'Vinsælt'},
  {value: 'sold', label: 'Mest selt'},
  {value: 'price_asc', label: 'Ódýrast efst'},
  {value: 'price_desc', label: 'Dýrast efst'},
  {value: 'new', label: 'Nýjast efst'},
]

export const productVariantSortOptionsMap = fromPairs(
  productVariantSortOptions.map((o) => [o.value, o]),
)

// TODO: Create simple polymorghic localStorage/memory cache in utils
const storage =
  typeof sessionStorage == 'undefined'
    ? {
        obj: {},
        get(key: string) {
          return this.obj[key]
        },
        set(key: string, data: any) {
          this.obj[key] = data
        },
      }
    : {
        get(key: string) {
          try {
            return JSON.parse(sessionStorage.getItem(key))
          } catch (e) {
            return undefined
          }
        },
        set(key: string, data: any) {
          sessionStorage.setItem(key, JSON.stringify(data))
        },
      }

async function getAvailableFacetKeys(indexName: string) {
  let availableFacets: {
    keys: undefined | string[]
    exp: Date
  } = storage.get('availableFacets')

  const now = new Date()

  if (!availableFacets?.keys?.length || availableFacets?.exp > now) {
    const {
      results: [{facets}],
    } = await searchClient.search<VariantList>([
      {
        indexName,
        params: {
          hitsPerPage: 1,
          analytics: false,
          analyticsTags: [],
          facets: [pricePath, 'categories', 'attributes.*'],
          clickAnalytics: true,
          ruleContexts: [ruleContext],
        },
      },
    ])

    availableFacets = {
      keys: facets && Object.keys(facets),
      exp: addSeconds(now, 60),
    }
    storage.set('availableFacets', availableFacets)
  }

  return availableFacets.keys
}

interface FetchProductVariantsProps {
  categoryId: number | undefined | null
  sortBy: string
  filters: string
  query?: string | undefined | null
  page?: number
  pageSize?: number
  userToken?: string
}

export async function fetchProductVariants({
  categoryId,
  sortBy,
  filters,
  query,
  page = 1,
  pageSize = 24,
  userToken,
}: FetchProductVariantsProps): Promise<AlgoliaSearchResults<VariantList>> {
  const indexName = `${searchIndexes.productVariants}_${sortBy || 'popular'}`

  const strFilters = [`${channelListingPath}:true`]

  if (categoryId) {
    strFilters.push(`categories:${categoryId}`)
  }

  // Do the actual search:
  const res = await productVariantSearch.searchOnce({
    query,
    index: indexName,
    disjunctiveFacets: await getAvailableFacetKeys(indexName),
    ...JSON.parse(filters),
    filters: strFilters.join(' AND '),
    hitsPerPage: pageSize,
    page: page - 1,
    clickAnalytics: true,
    ruleContexts: [ruleContext],
    userToken,
  })

  const result = {
    ...res.content,
  } as algoliasearchHelper.SearchResults<VariantList>
  delete result._state
  delete result._rawResults

  return result
}

export function compileFilterString(q: Record<string, string | undefined>) {
  const query = omit(q, ['q', 'slug', 'sort', 'page', 'priceFrom', 'priceTo'])

  // Only use filtering params
  const filteredQuery = pickBy(
    query,
    (_, key) => key.startsWith('attributes.') || key === 'categories',
  )

  const cleanQuery = mapValues(filteredQuery, (v) =>
    v
      .split(',')
      .filter((x) => x)
      .map((item: string) => item.replace('..', ',')),
  )

  const numericRefinements: {
    [facet: string]: algoliasearchHelper.SearchParameters.OperatorList
  } = {
    [pricePath]: {
      '>=': q.priceFrom ? [parseInt(q.priceFrom, 10)] : [],
      '<=': q.priceTo ? [parseInt(q.priceTo, 10)] : [],
    },
  }

  return JSON.stringify({
    disjunctiveFacetsRefinements: cleanQuery,
    numericRefinements,
  })
}

export interface ChoiceFacetOption {
  value: string | number
  label: string
  count: number
  active: boolean
}

export interface HierarchicalFacetOption extends ChoiceFacetOption {
  parent: number
}

export interface ChoiceFacet {
  type: 'choice'
  options: ChoiceFacetOption[]
}

export interface HierarchicalFacet {
  type: 'hierarchical'
  options: HierarchicalFacetOption[]
}

export interface RangeFacetType {
  type: 'range'
  label: string
  min: number
  max: number
  minValue?: number
  maxValue?: number
  minAttr: string
  maxAttr: string
  data: object
}

export type FacetType = HierarchicalFacet | ChoiceFacet | RangeFacetType

export type FacetTypeRecord = Record<string, FacetType>

export interface CompiledFacets extends FacetTypeRecord {
  categories: HierarchicalFacet
  priceRange: RangeFacetType
}

export interface FacetOrderValues {
  [facet: string]: {
    order?: string[]
    sortRemainingBy?: 'count' | 'alpha' | 'hidden'
  }
}

export interface WordSuggestion {
  id: string
  query: string
}

export function sortFacetOptions(
  key: string,
  options: ChoiceFacetOption[] | HierarchicalFacetOption[],
  facetOrderValues: FacetOrderValues,
): ChoiceFacetOption[] | HierarchicalFacetOption[] {
  if (!facetOrderValues) {
    return options
  }
  const order = facetOrderValues[key]?.order
  const remainingOrder = facetOrderValues[key]?.sortRemainingBy

  const sortOptionsMap = fromPairs(options.map((o) => [o.label, o]))

  const orderedOptions =
    order?.map((o) => sortOptionsMap[o]).filter((x) => x) || []
  const remainingOptions = options?.filter((f) => !order?.includes(f.label))

  if (remainingOptions?.length && remainingOrder === 'alpha') {
    remainingOptions.sort((a, b) => a.label.localeCompare(b.label))
  } else if (remainingOptions?.length && remainingOrder === 'count') {
    remainingOptions.sort((a, b) => b.count - a.count)
  }

  return [...orderedOptions, ...remainingOptions]
}

export interface FacetTreeItem extends HierarchicalFacetOption {
  children?: FacetTreeItem[]
  parents?: FacetTreeItem[]
}

function isFacetActive(queryParams, idStr) {
  return queryParams.categories?.includes(idStr) ?? false
}

export function compileFacets(
  results: algoliasearchHelper.SearchResults<VariantList>,
  common: Partial<CommonContextProps>,
  queryParams: Record<string, string[]>,
  activeCategorySlug?: string | undefined,
): CompiledFacets {
  const disjunctiveFacets = fromPairs(
    results?.disjunctiveFacets?.map((f) => [f.name, f]) ?? [],
  )
  const catFacet = disjunctiveFacets.categories
  delete disjunctiveFacets.categories

  const categoryMap = fromPairs(common?.categories?.map((c) => [c.id, c]))

  const categories = toPairs(catFacet?.data)
    .map(([idStr, count]) => {
      const id = parseInt(idStr, 10)
      const cat = categoryMap[id]
      if (cat && cat.slug !== activeCategorySlug) {
        return {
          value: id,
          label: cat?.name,
          count,
          active: isFacetActive(queryParams, idStr),
          parent: cat.parent,
        } as HierarchicalFacetOption
      }
      return null
    })
    .filter((cat) => cat)

  const priceStats = disjunctiveFacets[pricePath]?.stats ?? {
    min: 0,
    max: 2000000,
  }
  const priceData = disjunctiveFacets[pricePath]?.data
  delete disjunctiveFacets[pricePath]

  const priceRange: RangeFacetType = {
    type: 'range',
    label: 'Verðbil',
    min: priceStats.min,
    max: priceStats.max,
    minValue: parseInt(queryParams.priceFrom?.[0] || '0', 10) || undefined,
    maxValue: parseInt(queryParams.priceTo?.[0] || '0', 10) || undefined,
    minAttr: 'priceFrom',
    maxAttr: 'priceTo',
    data: priceData,
  }

  const attributes = fromPairs(
    toPairs(disjunctiveFacets)
      .filter(([k]) => k.indexOf('attributes.') === 0)
      .map(([_attr, {data: valueCounts}]) => {
        const attr = _attr

        const options = toPairs(valueCounts).map(([value, count]) => {
          const sanitizedValue = value.replace(',', '..')
          return {
            value: sanitizedValue,
            label: value,
            count,
            active: queryParams[attr]?.includes(sanitizedValue) ?? false,
          }
        })

        return [
          attr,
          {
            type: 'choice',
            options,
          },
        ]
      }),
  )

  return {
    categories: {
      type: 'hierarchical',
      options: categories,
    },
    priceRange,
    ...attributes,
  }
}

export interface ActiveFilterProps {
  attr: string
  value: string | number
  label: string
  min?: string
  max?: string
}

export function compileActiveFilters(
  facets: FacetTypeRecord,
): ActiveFilterProps[] {
  return toPairs(facets).reduce((acc: ActiveFilterProps[], [attr, facet]) => {
    return [
      ...acc,
      ...(facet.type === 'choice' || facet.type === 'hierarchical'
        ? facet.options
            .filter((opt) => opt.active)
            .map(({value, label}) => ({
              attr,
              value,
              label,
            }))
        : []),
      ...(facet.type === 'range' && !!facet.minValue && !!facet.maxValue
        ? [
            {
              attr,
              value: facet.label,
              label: facet.label,
              min: facet.minAttr,
              max: facet.maxAttr,
            },
          ]
        : []),
    ]
  }, [])
}
