import {validate as emailValid} from 'email-validator'
import {withFormik, FormikProps} from 'formik'
import TagManager from 'react-gtm-module'
import {toast} from 'react-toastify'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

import {restApi} from '@festi/common/api/rest'

import {Button} from '../buttons'
import {FormikInput} from '../form'
import {H4, P} from '../typography'
import {AuthFormProps} from './RegisterForms'

interface Props extends AuthFormProps {
  initialEmail?: string
}

interface FormValues {
  email: string
}

interface ErrorProps {
  email?: string
}

const Form = ({
  handleSubmit,
  isSubmitting,
}: FormikProps<FormValues> & Props): JSX.Element => {
  return (
    <form onSubmit={handleSubmit}>
      <H4 withGutter>Hvað er netfangið þitt?</H4>
      <P withGutter>
        Við þurfum netfang til að geta sent þér nótur, pöntunarstaðfestingar og
        fleira. Þetta er ekki skráning á póstlistann.
      </P>
      <FormikInput
        name="email"
        type="email"
        placeholder="Netfang"
        characterLimit={255}
      />
      <Button
        type="submit"
        withBar
        loading={isSubmitting}
        icon={ArrowRightSLineIcon}
        accentColor="lightBlue"
      >
        Áfram
      </Button>
    </form>
  )
}

const EmailForm = withFormik<Props, FormValues>({
  mapPropsToValues: ({initialEmail}) => ({
    email: initialEmail || '',
  }),

  validate: ({email}) => {
    const errors: ErrorProps = {}

    if (!email) {
      errors.email = 'Hér vantar netfang'
    } else if (!emailValid(email)) {
      errors.email = 'Netfangið er ekki á réttu formi'
    }

    return errors
  },

  handleSubmit: ({email}, {props: {onComplete}}) => {
    restApi
      .usersMeEmailPartialUpdate({email})
      .then(() => {
        !!onComplete && onComplete()

        TagManager.dataLayer({
          dataLayer: {
            category: 'login',
            event: 'account email submitted',
          },
          dataLayerName: 'dataLayer',
        })
      })
      .catch((err) => {
        toast.error('Eitthvað fór úrskeiðis, reynið aftur síðar')
        throw err
      })
  },

  displayName: 'EmailForm',
})(Form)

export default EmailForm
