import React from 'react'

import cn from 'classnames'
import {Row, Col} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {fluidRange} from '@festi/common/utils/styles'

interface Props {
  label: string
  compact?: boolean
  children: React.ReactNode
}

const Wrapper = styled.div`
  ${fluidRange('padding-top', '20px', '40px')}
  ${fluidRange('padding-bottom', '20px', '50px')}
  border-top: 1px solid ${palette('ui10Solid')};

  &.compact {
    padding-top: 0;
    border-top: 0;
  }
`

export const ProductAttributeLabel = styled.span`
  display: block;
  color: ${palette('ui50Solid')};
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  margin-bottom: 16px;
`

export default function ProductAttributeWrapper({
  label,
  compact = false,
  children,
}: Props): JSX.Element {
  return (
    <Wrapper className={cn({compact})}>
      <Row justifyContent="between">
        <Col col={12} md={2.4}>
          <ProductAttributeLabel>{label}</ProductAttributeLabel>
        </Col>
        <Col col={12} md={9.6}>
          <Row justifyContent="end">
            <Col col={12} md={12}>
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  )
}
