import React from 'react'

import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'

interface Props {
  message: string
  color: string
  icon?: RemixiconReactIconComponentType
}

export const Sticker = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background-color: ${(props) => palette(props.color[1])};
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${(props) => palette(props.color[0])};
  margin-bottom: 16px;
`

const IconContainer = styled.div`
  padding-right: 24px;
`

const Message = styled.div`
  flex: 1;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  color: ${palette('blue')};
`

export default function NoticeSticker({
  message,
  icon: Icon,
  color,
}: Props): JSX.Element {
  const styles = {
    red: ['error', 'errorBack'],
    yellow: ['warning', 'warningBack'],
    blue: ['lightBlue', 'lightBlueBack'],
    green: ['success', 'successBack'],
  }

  return (
    <Sticker color={styles[color]}>
      <IconContainer>
        <Icon size={24} color={styledTheme.palette[styles[color][0]]} />
      </IconContainer>
      <Message>{message}</Message>
    </Sticker>
  )
}
