import {useState} from 'react'

import {Auth} from '@aws-amplify/auth'
import {CognitoUser} from '@aws-amplify/auth'
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js'
import {v4 as uuidv4} from 'uuid'
import getConfig from 'next/config'
import {toast} from 'react-toastify'
import TagManager from 'react-gtm-module'

import {fieldErrorCodeMap} from '@festi/common/constants'
import {businessDrawerLocalStorgeShow} from '@festi/common/utils/localstorage'
import {parsePhoneNumberNoPrefix, cleanSSN} from '@festi/utils/strings'
import {sendDataLayerEvent,sendDataLayerLoginEvent} from '@festi/common/utils/tagmanager'

import {useAuth} from '../contexts'

const userPoolId = getConfig().publicRuntimeConfig.cognitoUserPoolId
const clientId = getConfig().publicRuntimeConfig.cognitoClientId

export interface ConfirmationResponse {
  status: string
  data: string
}

export default function useCognito() {
  const {setLastLogin} = useAuth()

  const [newLogin, setNewLogin] = useState(true)
  const [securityCode, setSecurityCode] = useState('')


  const signUp = async (value: string) => {
    return Auth.signUp(value, uuidv4())
      .then((res) => {
        if (res?.userConfirmed) {
          TagManager.dataLayer({
            dataLayer: {
              category: 'login',
              event: 'account signup started',
            },
            dataLayerName: 'dataLayer',
          })
          login(value)
        }
      })
      .catch((err) => {
        const errorMsg = fieldErrorCodeMap[err?.code]
        toast.error(errorMsg || 'Auðkenning tókst ekki')
        setSecurityCode('')

        if (!errorMsg) {
          throw err
        }
      })
  }

  const login = async (value: string) => {
    return Auth.signIn(value)
      .then(async (res) => {
        setSecurityCode(res?.challengeParam?.code)

        if (res.challengeName === 'CUSTOM_CHALLENGE') {
          return new Promise((resolve, reject) => {
            let errorCount = 0
            const challengeInterval = setInterval(async () => {
              try {
                const challengedCognitoUser =
                  await Auth.sendCustomChallengeAnswer(
                    res,
                    'the answer for the challenge',
                  )
                if (
                  challengedCognitoUser.signInUserSession?.accessToken?.jwtToken
                ) {
                  clearTimeout(challengeInterval)

                  if (newLogin) {
                    businessDrawerLocalStorgeShow(true)
                  }

                  sendDataLayerEvent('rafraen skilriki finished', 'login')
                  sendDataLayerLoginEvent()

                  setSecurityCode('')
                  setLastLogin(new Date())

                  resolve(res)
                } else if (challengedCognitoUser.challengeParam?.error) {
                  clearInterval(challengeInterval)
                  reject({
                    code: 'UserCancelledException',
                    message: challengedCognitoUser.challengeParam?.error,
                  })
                  setSecurityCode('')
                }
              } catch (err) {
                if (errorCount > 5) {
                  clearInterval(challengeInterval)
                  reject('Notandi hætti við')
                  setSecurityCode('')
                }
                errorCount++
              }
            }, 2000)
          })
        }
      })
      .catch(async (err) => {
        if (err?.code === 'UserNotFoundException') {
          return signUp(value)
        } else {
          const errorMsg = fieldErrorCodeMap[err?.code]
          toast.error(errorMsg || 'Auðkenning tókst ekki')
          setSecurityCode('')

          if (!errorMsg) {
            throw err
          }
        }
      })
  }

  const appLogin = async (ssn: string) => {
    const kennitala = cleanSSN(ssn)

    return login(kennitala)
  }

  const phoneLogin = async (phone: string) => {
    const phone_number = parsePhoneNumberNoPrefix(phone)

    return login(phone_number)
  }

  const initiateCognito = async (username: string): Promise<CognitoUser> => {
    const userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
    })
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })
    cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')

    const authenticationDetails = new AuthenticationDetails({
      Username: username,
    })

    return await new Promise((resolve, reject) => {
      cognitoUser.initiateAuth(authenticationDetails, {
        onSuccess: function () {
          resolve(cognitoUser)
        },
        onFailure: function (err) {
          reject(err)
        },
        customChallenge: function (challengeParameters) {
          setSecurityCode(challengeParameters?.code)
          resolve(cognitoUser)
        },
      })
    })
  }

  const customChallenge = async (
    cognitoUser: CognitoUser,
  ): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
      let errorCount = 0
      const challengeInterval = setInterval(async () => {
        cognitoUser.sendCustomChallengeAnswer('the answer for the challenge', {
          onSuccess: function (cognitoSession) {
            clearInterval(challengeInterval)
            resolve(cognitoSession)
          },
          onFailure: function () {
            if (errorCount > 5) {
              clearInterval(challengeInterval)
              reject('Notandi hætti við')
            }
            errorCount++
          },
          customChallenge: function (challengeParameters) {
            setSecurityCode(challengeParameters?.code)
          },
        })
      }, 2000)
    })
  }

  const confirmation = async (): Promise<ConfirmationResponse> => {
    setNewLogin(false)

    return Auth.currentAuthenticatedUser()
      .then(async (user) => {
        return initiateCognito(user?.username)
          .then(async (init) => {
            return customChallenge(init)
              .then((res: CognitoUserSession) => {
                setSecurityCode('')
                setLastLogin(new Date())
                return {
                  status: 'success',
                  data: res?.getIdToken().getJwtToken(),
                }
              })
              .catch((err) => {
                const msg = err || 'Auðkenning tókst ekki'
                setSecurityCode('')
                return {status: 'error', data: msg}
              })
          })
          .catch((_err) => {
            const msg = 'Auðkenning byrjaði ekki'
            return {status: 'error', data: msg}
          })
      })
      .catch((_err) => {
        const msg = 'Enginn notandi skráður inn'
        return {status: 'error', data: msg}
      })
  }

  return {
    appLogin,
    phoneLogin,
    confirmation,
    securityCode,
  }
}
