import React, {useCallback, useRef} from 'react'

import {useField, FieldConfig} from 'formik'
import CheckLineIcon from 'remixicon-react/CheckLineIcon'
import styled from 'styled-components'
import {palette, ifProp} from 'styled-tools'

import ErrorMsg from './ErrorMsg'

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  center?: boolean
  errorMsg?: string | false
  withGutter?: boolean
  suffix?: React.ReactNode
}

interface LabelProps {
  center?: boolean
  disabled?: boolean
}

interface BoxProps {
  checked?: boolean
}

const Label = styled.label<LabelProps>`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: ${ifProp({center: true}, 'center', 'flex-end')};
  padding: 4px;
  opacity: ${ifProp({disabled: true}, '0.4', '1')};
  user-select: none;
  cursor: ${ifProp({disabled: true}, 'not-allowed', 'pointer')};
`

const Text = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25;
  margin-left: 12px;
  color: ${palette('blue')};
`

const Box = styled.div<BoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border: 1px solid ${palette('ui20Solid')};
  background-color: transparent;
  transition: background-color 0.15s, border-color 0.15s;

  .remixicon-icon {
    fill: transparent !important;
  }
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  border-radius: 0;
  pointer-events: none;

  &:checked + ${Box} {
    background-color: ${palette('lightBlue')};
    border-color: ${palette('lightBlue')};

    .remixicon-icon {
      fill: ${palette('white')} !important;
    }
  }

  &:focus + ${Box} {
    border-color: ${palette('lightBlue')};
  }
`

export default function Checkbox({
  label,
  center,
  errorMsg,
  withGutter,
  suffix,
  ...props
}: CheckboxProps): JSX.Element {
  const checkboxRef = useRef<HTMLInputElement>(null)

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        checkboxRef.current?.click()
      }
    },
    [checkboxRef],
  )

  return (
    <div style={{marginBottom: withGutter ? '8px' : '0'}}>
      <Label center={center} disabled={props.disabled}>
        {!!label && (
          <Text>
            {label}
            {suffix}
          </Text>
        )}
        <CheckboxInput
          ref={checkboxRef}
          type="checkbox"
          onKeyPress={onKeyPress}
          {...props}
        />
        <Box checked={props.checked}>
          <CheckLineIcon size={16} />
        </Box>
      </Label>
      {!!errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
    </div>
  )
}

export function FormikCheckbox(
  props: CheckboxProps &
    React.InputHTMLAttributes<HTMLInputElement> &
    FieldConfig,
): JSX.Element {
  const [field, meta] = useField(props)
  const errorMsg = !!meta.touched && meta.error

  return (
    <Checkbox {...field} {...props} checked={field.value} errorMsg={errorMsg} />
  )
}
