import React from 'react'

import MapPinLineIcon from 'remixicon-react/MapPinLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {ifProp, palette} from 'styled-tools'
import {AnchorButton} from '@festi/common/components/buttons'
import {H5} from '@festi/common/components/typography'
import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'

interface Props {
  logo: string
  title: string
  location: string
  link?: string
  darkMode?: boolean
}

interface CardProps {
  darkMode: boolean
}

export const LocationCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 16px;
`

const Title = styled.span`
  display: block;
  color: ${palette('blue')};
  font-size: 1.125rem;
  font-weight: 500;
  ${fluidRange('margin-bottom', '8px', '16px')}

  ${media.md`
    font-size: 1.375rem;
  `}
`

const Line = styled.div`
  display: flex;
  align-items: flex-start;
  ${fluidRange('margin-bottom', '5px', '8px')}
  line-height: 150%;
`

const Text = styled.span`
  font-size: 0.875rem;
  margin-left: 16px;
`

const Card = styled.div<CardProps>`
  background-color: ${ifProp(
    {darkMode: true},
    palette('ui90'),
    palette('white'),
  )};
  border: 1px solid ${palette('ui20Solid')};
  border-left: 4px solid ${palette('lightBlue')};
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  padding: 24px;

  ${media.md`
    flex-direction: column;
  `}

  ${Title}, ${Line} {
    color: ${ifProp({darkMode: true}, palette('white'), palette('blue'))};
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 66px;
  margin-bottom: 16px;
  margin-right: 16px;
`

const Logo = styled.img`
  max-height: 45px;
  max-width: 45px;

  ${media.md`
    max-height: 66px;
    max-width: none;
    width: 100%;
  `}
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 170px;
  height: 100%;
`

const Icon = styled.div`
  width: 20px;
  margin-top: 3px;
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
`

export default function LocationCard({
  logo,
  title,
  location,
  link,
  darkMode = false,
}: Props): JSX.Element {
  return (
    <LocationCardWrapper>
      <Card darkMode={darkMode}>
        {!!logo && (
          <LogoWrapper>
            <Logo src={logo} />
          </LogoWrapper>
        )}
        <ContentWrapper>
          <div>
            <H5 withGutter darkMode={darkMode}>
              {title}
            </H5>
            <Line>
              <Icon>
                <MapPinLineIcon
                  size={20}
                  color={styledTheme.palette.lightBlue}
                />
              </Icon>
              <Text>{location}</Text>
            </Line>
          </div>
          <ButtonWrapper>
            <AnchorButton href={link} darkMode={darkMode}>
              Sjá meira
            </AnchorButton>
          </ButtonWrapper>
        </ContentWrapper>
      </Card>
    </LocationCardWrapper>
  )
}
