import React from 'react'

import Link from 'next/link'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {ArrowAnchor} from '@festi/common/components/buttons'
import {fluidRange} from '@festi/common/utils/styles'

interface ColorProps {
  color: 'pink' | 'lightBlue'
}

export const ErrorPageWrapper = styled.div`
  ${fluidRange('padding-top', '40px', '112px')}
`

export const ErrorCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette('ui20Solid')};
  margin-bottom: 56px;

  ${media.md`
    flex-direction: row;
  `}
`

export const ErrorCardHead = styled.div<ColorProps>`
  width: 100%;
  position: relative;
  background-color: ${(props) => palette(props.color)};

  &:before {
    content: '';
    display: block;
    padding-top: 40%;
  }

  ${media.md`
    width: 50%;

    &:before {
      padding-top: 80%;
    }
  `}
`

export const ErrorCardImg = styled.div`
  position: absolute;
  top: 24px;
  bottom: 24px;
  left: 24px;
  right: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${media.md`
    top: 80px;
    bottom: 80px;
    left: 80px;
    right: 80px;
  `}
`

export const ErrorCardBody = styled.div`
  width: 100%;
  padding: 40px;
  ${fluidRange('padding', '40px', '120px')}

  ${media.md`
    width: 50%;
  `}
`

export const ErrorTitleWrapper = styled.div`
  ${fluidRange('margin-bottom', '32px', '56px')}
`

export const ErrorTitle = styled.span<ColorProps>`
  position: relative;
  text-transform: uppercase;
  font-family: 'ElkjopHeadline', sans-serif;
  color: ${palette('blue')};
  margin-top: 24px;
  z-index: 2;
  ${fluidRange('font-size', '32px', '56px')}

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    bottom: -8px;
    left: -16px;
    right: -16px;
    background-color: ${(props) => palette(props.color)};
    transform: rotate(-4deg);
    z-index: -1;
  }
`

export const ErrorSubtitle = styled.div`
  text-transform: uppercase;
  font-family: 'ElkjopHeadline', sans-serif;
  color: ${palette('blue')};
  margin-bottom: 56px;
  line-height: 1.3;
  ${fluidRange('font-size', '32px', '40px')}
`

export default function NotFound(): JSX.Element {
  return (
    <ErrorPageWrapper>
      <Container>
        <ErrorCard>
          <ErrorCardHead color="pink">
            <ErrorCardImg
              style={{backgroundImage: 'url(/images/not-found.png)'}}
            />
          </ErrorCardHead>
          <ErrorCardBody>
            <ErrorTitleWrapper>
              <ErrorTitle color="pink">404 Villa</ErrorTitle>
            </ErrorTitleWrapper>
            <ErrorSubtitle>
              Góð tilraun... En þú ert ekki á réttum stað
            </ErrorSubtitle>
            <Link href="/" passHref legacyBehavior>
              <ArrowAnchor>Aftur á forsíðu</ArrowAnchor>
            </Link>
          </ErrorCardBody>
        </ErrorCard>
      </Container>
    </ErrorPageWrapper>
  );
}
