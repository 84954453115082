import {useEffect, useState} from 'react'

interface CounterProps {
  sec?: number
}

export default function Countdown({sec = 30}: CounterProps) {
  const [counter, setCounter] = useState(sec)

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  return <>{counter}</>
}
