import {styledTheme} from '@festi/common/themes'
import classNames from 'classnames'
import {FieldConfig, useField} from 'formik'
import {InputHTMLAttributes, useRef, useState, FocusEvent} from 'react'
import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'
import {MainIconButton} from '../../buttons'

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: fit-content;
`

const Label = styled.label`
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: ${styledTheme.palette.ui60Solid};
  transform: translate(16px, 13px);
  pointer-events: none;

  &.focused,
  &.hasValue {
    font-size: 12px;
    font-weight: 500;
    transform: translate(16px, 5px);
  }
  &.large {
    transform: translate(16px, 17px);
  }
`

const Input = styled.input`
  width: 100%;
  height: 48px;
  background-color: ${styledTheme.palette.white};
  border: 1px solid ${styledTheme.palette.grey};
  border-radius: 8px;
  padding-inline: 16px;
  padding-block: 13px;
  color: ${styledTheme.palette.blue};
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  transform: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &:focus-visible {
    border-color: ${styledTheme.palette.ui30Solid};
    padding-bottom: 2px;
  }
  &:hover {
    border-color: ${styledTheme.palette.ui30Solid};
  }
  &:focus {
    outline: none;
  }
  &.large {
    height: 56px;
    padding-block: 17px;
  }
  &.hasValue {
    padding-bottom: 2px;
  }
  &.withBtn {
    padding-right: 42px;
  }

`

const ActionButtonContainer = styled.div`
  position: absolute;
  right: 6px;
  top: 5px;

  &:hover + ${Input} {
    border-color: ${styledTheme.palette.ui30Solid};
  }
  &.large {
    top: 10px;
    right: 8px;
  }
`

// Note(Bjorg): There was no error message in the design, I added it in case needed
const ErrorMsg = styled.p`
  display: grid;
  grid-template-columns: 0fr;
  font-size: 12px;
  padding-inline: 8px;
  color: ${styledTheme.palette.error};
  &.expanded {
    grid-template-columns: 1fr;
  }
`

interface ActionButton {
  icon: RemixiconReactIconComponentType
  onClick?: () => void
  iconColor?: string
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  label: string
  errorMsg?: string | false
  large?: boolean
  actionButton?: ActionButton
}

export default function NewInput({
  id,
  label,
  errorMsg = false,
  large = false,
  actionButton,
  ...props
}: InputProps) {
  const ref = useRef<HTMLInputElement>(null)
  const {onFocus, onBlur} = props
  const [focused, setFocused] = useState(false)

  function handleFocus(e: FocusEvent<HTMLInputElement>) {
    setFocused(true)
    !!onFocus && onFocus(e)
  }

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    setFocused(false)
    !!onBlur && onBlur(e)
  }
  const hasValue =
    !!ref?.current?.value || !!props.value || !!props.defaultValue

  return (
    <Root>
      <Label className={classNames({hasValue, focused, large})} htmlFor={id}>
        {label}
      </Label>
      {actionButton && (
        <ActionButtonContainer className={classNames({large})}>
          <MainIconButton
            type='submit'
            onClick={actionButton.onClick}
            iconColor={actionButton.iconColor ?? styledTheme.palette.lightBlue}
            icon={actionButton.icon}
            size="small"
            buttonVariant="negative"
          />
        </ActionButtonContainer>
      )}
      <Input
        id={id}
        ref={ref}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={classNames({large, hasValue, withBtn: !!actionButton})}
        aria-invalid={!!errorMsg}
        {...props}
      />
      <ErrorMsg role="alert" className={classNames({expanded: !!errorMsg})}>
        {errorMsg}
      </ErrorMsg>
    </Root>
  )
}

export function NewFormikInput(
  props: InputProps &
    React.InputHTMLAttributes<HTMLInputElement> &
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FieldConfig<any>,
): JSX.Element {
  const [field, meta] = useField(props)
  const errorMsg = !!meta.touched && meta.error

  return <NewInput {...field} {...props} errorMsg={errorMsg} />
}
