import {useCallback, useState} from 'react'

import TagManager from 'react-gtm-module'
import {animated} from '@react-spring/web'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import styled from 'styled-components'
import {palette, prop} from 'styled-tools'

import {CheckoutLine, CheckoutLineInsurance} from '@festi/common/api/rest'
import {ProductPrices} from '@festi/common/components/cart/ProductPrices'
import {PriceText} from '@festi/common/components/typography'
import settings from '@festi/common/constants/settings'
import {useCheckout} from '@festi/common/contexts'
import {getInsuranceText} from '@festi/common/utils/checkout'
import {sendEcommerceNullEvent} from '@festi/common/utils/tagmanager'
import {onDutyFree} from '@festi/common/constants/channels'
import {formatPrice} from '@festi/utils/numbers'

import {styledTheme} from '../../themes'
import QtyPicker from './QtyPicker'
import CartItemWarning from './CartItemWarning'

interface Props {
  line: CheckoutLine
}

interface ImageProps {
  src?: string
}

interface LineInsurancesProps {
  insurances: CheckoutLineInsurance[]
}

const RemoveBtn = styled(animated.button)`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  border-radius: 12px;
  background-color: ${palette('ui10Solid')};
  transition: transform 0.15s ease-out;
  cursor: pointer;
`

const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid ${palette('ui10Solid')};
  transition: opacity 0.2s;
`

const Image = styled.div<ImageProps>`
  width: 20%;
  position: relative;
  background-image: url(${prop('src')});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &:before {
    content: '';
    padding-top: 100%;
    display: block;
  }
`

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 32px;
`

const Name = styled.a`
  font-size: 1rem;
  font-weight: 500;
  width: calc(100% - 32px);
  padding: 4px 0 12px 0;
  color: ${palette('blue')};
`

const Sku = styled.span`
  color: ${palette('ui30Solid')};
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 16px;
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 8px;
`

const LineText = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  color: ${palette('blue')};
  line-height: 1;

  &.noBreak {
    white-space: nowrap;
  }

  &:first-child {
    flex-grow: 1;
    text-align: left;
  }

  &:last-child {
    text-align: right;
    padding-left: 8px;
  }
`

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
  position: relative;
`

const ItemInner = styled.div`
  display: flex;
`

export function LineInsurances({insurances}: LineInsurancesProps): JSX.Element {
  return (
    <Line>
      <LineText>
        {insurances?.length > 1 && `${insurances?.length} x `}
        {`${getInsuranceText(insurances?.[0])}`}
      </LineText>
      <PriceText className="small">
        {formatPrice(insurances?.reduce((sum, item) => sum + item?.price, 0))}
      </PriceText>
    </Line>
  )
}

export default function MiniCartItem({line}: Props): JSX.Element {
  const {cartState, cartUpdate} = useCheckout()
  const [isRemoving, setIsRemoving] = useState<boolean>(false)

  const {variant, insurances, totalPrice, quantity} = line
  const {
    id,
    sku,
    name,
    product,
    firstImage,
    isInStockWeb,
    isInStockDutyfree,
    listings: {
      [settings.channel]: {
        price: {price},
      },
    },
  } = variant

  const linePrice = price * quantity

  const variantId = id
  const currentQty = cartState[variantId]

  const onClickRemove = useCallback(() => {
    setIsRemoving(true)
    // GA4 add/remove from cart event
    sendEcommerceNullEvent()
    TagManager.dataLayer({
      dataLayer: {
        event: 'remove_from_cart',
        ecommerce: {
          currency: 'ISK',
          value: price * currentQty,
          items: [
            {
              item_id: sku,
              item_name: name,
              item_category: product.type,
              price: price,
              quantity: currentQty,
              elko_recommends: variant.recommended,
            },
          ],
        },
      },
      dataLayerName: 'dataLayer',
    })
    cartUpdate({[variantId]: 0})
  }, [
    sku,
    name,
    price,
    variantId,
    currentQty,
    product.type,
    variant.recommended,
    cartUpdate,
  ])

  const onUpdateQty = useCallback(
    (q: number) => {
      const adding = currentQty < q

      // GA4 add/remove from cart
      sendEcommerceNullEvent()
      TagManager.dataLayer({
        dataLayer: {
          event: adding ? 'add_to_cart' : 'remove_from_cart',
          ecommerce: {
            currency: 'ISK',
            value: price * quantity,
            items: [
              {
                item_id: sku,
                item_name: name,
                item_category: product.type,
                price: price,
                quantity: quantity,
                elko_recommends: variant.recommended,
                item_list_name: 'Karfa',
              },
            ],
          },
        },
        dataLayerName: 'dataLayer',
      })

      cartUpdate({[variantId]: q})
    },
    [
      sku,
      name,
      price,
      product,
      quantity,
      variantId,
      currentQty,
      variant.recommended,
      cartUpdate,
    ],
  )

  return (
    <Item style={isRemoving ? {opacity: 0.4, pointerEvents: 'none'} : {}}>
      <ItemInner>
        <Image src={firstImage?.image?.productList} />
        <Content>
          <Name href={`/vorur/${product?.slug}/${sku}`}>{name}</Name>

          <Sku>{sku}</Sku>

          <QtyPicker qty={currentQty} onUpdate={onUpdateQty} keepSmall />

          <PriceWrapper>
            <ProductPrices
              type="small"
              price={linePrice}
              lowestPrice={totalPrice}
            />
          </PriceWrapper>
        </Content>
      </ItemInner>

      <CartItemWarning
        sku={sku}
        isInStock={onDutyFree ? isInStockDutyfree : isInStockWeb}
      />

      {!!insurances.length && <LineInsurances insurances={insurances} />}

      <RemoveBtn onClick={onClickRemove}>
        <CloseLineIcon size={16} color={styledTheme.palette.blue} />
      </RemoveBtn>
    </Item>
  )
}
