import React, {useCallback, useState} from 'react'

import {ErrorMsg} from '.'
import is from 'date-fns/locale/is'
import {FieldConfig, useField} from 'formik'
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker'
import CalendarLineIcon from 'remixicon-react/CalendarLineIcon'
import styled from 'styled-components'
import {ifProp, palette} from 'styled-tools'

import {styledTheme} from '../../themes'
import {formatInputDate} from '../../utils/dates'

registerLocale('is', is)
setDefaultLocale('is')
interface Props {
  label: string
  errorMsg?: string
  minDate?: Date
  maxDate?: Date
  selectedDate?: Date
  onChange?: (date: Date) => void
}

interface DateInputWrapperProps {
  isActive?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  .react-datepicker-popper {
    margin-top: 0;
    top: -1px !important;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top: 1px solid ${palette('lightBlue')};
    border-bottom: 1px solid ${palette('lightBlue')};
    font-family: 'ElkjopSans', sans-serif;
    padding: 8px 8px 12px 8px;
    &__triangle {
      display: none;
    }
    &__header {
      background-color: ${palette('white')};
      border-bottom: 0;
      padding-top: 0;
    }
    &__navigation {
      top: 16px;
      height: 22px;
      width: 22px;
      border: 0 !important;
      &--previous {
        left: 24px;
        &:before {
          content: '';
          height: 14px;
          width: 14px;
          position: absolute;
          top: 4px;
          left: 4px;
          background-image: url('/images/prev.png');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      &--next {
        right: 24px;
        &:before {
          content: '';
          height: 14px;
          width: 14px;
          position: absolute;
          top: 4px;
          left: 4px;
          background-image: url('/images/next.png');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      &:focus {
        outline: none;
      }
    }
    &__month {
      background-color: ${palette('white')};
      margin-top: 0;
      margin-bottom: 0;
    }
    &__current-month {
      text-transform: capitalize;
      font-weight: 500;
      font-size: 1rem;
      color: ${palette('blue')};
      padding: 12px 0 16px 0;
    }
    &__day-names {
      margin-bottom: 0;
    }
    &__day-name {
      font-size: 1rem;
      font-weight: 500;
      color: ${palette('ui50Solid')};
      margin: 4px;
      width: 40px;
      height: 30px;
    }
    &__day {
      font-size: 1rem;
      font-weight: 500;
      color: ${palette('blue')};
      margin: 4px;
      width: 40px;
      padding: 10px 0;
      line-height: 1;
      border-radius: 0;
      border: 2px solid transparent;
      box-sizing: border-box;
      transition: border-color 0.15s, background-color 0.15s;
      &--outside-month {
        color: ${palette('ui50Solid')};
      }
      &--selected {
        border-color: ${palette('lightBlue')};
        border-radius: 0;
        background-color: transparent;
      }
      &--today {
        background-color: ${palette('lightBlue20')};
        border-radius: 0;
      }
      &:hover,
      &--keyboard-selected {
        border-radius: 0;
        background-color: ${palette('lightBlue10')};
      }
      &:focus {
        outline: none;
        border-radius: 0;
      }
    }
  }
`

const DateInput = styled.input`
  visibility: hidden;
`

const DateInputWrapper = styled.label<DateInputWrapperProps>`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  height: 56px;
  box-sizing: border-box;
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 0 8px;
  cursor: pointer;
  border-color: ${ifProp(
    {isActive: true},
    palette('lightBlue'),
    palette('ui20Solid'),
  )};
  transition: border-color 0.15s;
  .remixicon-icon {
    margin-right: 20px;
  }
`

const DateInputInnerWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const InputLabelText = styled.span`
  display: block;
  font-size: 0.75rem;
  color: ${palette('lightBlue')};
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 4px;
`

const DateValue = styled.span`
  display: block;
  font-size: 1rem;
  color: ${palette('blue')};
  font-weight: 500;
  line-height: 1;
`

export default function DatePicker({
  label,
  errorMsg,
  minDate,
  maxDate,
  onChange,
  selectedDate,
}: Props): JSX.Element {
  const [isActive, setIsActive] = useState<boolean>(false)

  // See available props: https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
  return (
    <Wrapper>
      <ReactDatePicker
        tabIndex={0}
        selected={selectedDate}
        onChange={onChange}
        formatWeekDay={(weekDay: string) => weekDay[0].toUpperCase()}
        onCalendarOpen={() => setIsActive(true)}
        onCalendarClose={() => setIsActive(false)}
        minDate={minDate}
        maxDate={maxDate}
        // shouldColseOnSelect
        // todayButton
        customInput={
          <DateInputWrapper isActive={isActive}>
            <CalendarLineIcon size={24} color={styledTheme.palette.lightBlue} />
            <DateInputInnerWrapper>
              <InputLabelText>{label}</InputLabelText>
              <DateValue>
                {selectedDate
                  ? formatInputDate(selectedDate)
                  : 'Veldu dagsetningu'}
              </DateValue>
            </DateInputInnerWrapper>
            <DateInput type="hidden" />
          </DateInputWrapper>
        }
      />
      <ErrorMsg>{errorMsg}</ErrorMsg>
      {/* <input type="hidden" {...props} value={formatDutyFreeDate(currDate)} /> */}
    </Wrapper>
  )
}

export function FormikDatePicker(
  props: Props &
    React.InputHTMLAttributes<HTMLInputElement> &
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FieldConfig<any>,
): JSX.Element {
  const [field, meta, {setValue}] = useField(props)
  const errorMsg = !!meta.touched && meta.error

  const onChange = useCallback(
    (date: Date) => {
      setValue(date)
    },
    [setValue],
  )

  return (
    <DatePicker
      {...field}
      {...props}
      errorMsg={errorMsg}
      onChange={onChange}
      selectedDate={field.value}
    />
  )
}
