import React, {useCallback, useEffect, useRef, useState} from 'react'

import QuickPinchZoom, {make3dTransformValue} from 'react-quick-pinch-zoom'
import Slider from 'react-slick'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {ifProp, palette, prop} from 'styled-tools'

import {ProductVariantImage} from '@festi/common/api/rest'
import {gridTheme, styledTheme} from '@festi/common/themes'

import {ArrowBtnProps} from './ProductImageGallery'

interface ImgProps {
  src: string
  selected: boolean
}

interface Props {
  currentSlide: number
  setCurrentSlide: (index: number) => void
  images: ProductVariantImage[]
}

interface ZoomableImageProps {
  img: ProductVariantImage
}

const FullScreenMiniContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20%;
  margin-right: 16px;
  display: block;
  padding: 0 50px;

  /* Fix for adaptive height in react-slick */
  .slick-slide {
    height: 100%;

    & > div {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
  }
  .slick-track,
  .slick-list,
  .slick-slider {
    height: 100%;
  }
`

const FullScreenMiniImage = styled.div<ImgProps>`
  max-height: 100%;
  width: 100%;
  position: relative;
  background-image: url(${prop('src')});
  background-color: ${palette('white')};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border: 2px solid
    ${ifProp({selected: true}, palette('lightBlue'), 'transparent')};

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`
const FullScreenMainContainer = styled.div`
  height: 80%;

  /* Fix for adaptive height in react-slick */
  .slick-slide {
    height: 100%;

    & > div {
      height: 100%;
      & > div {
        display: flex !important;
        height: 100%;
      }
    }
  }
  .slick-track,
  .slick-list,
  .slick-slider {
    height: 100%;
  }
`

const FullScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 24px;
`

const FullScreenImage = styled.img`
  max-width: 100%;
  margin: auto;
  max-height: 100%;
`

const FullScreenMiniSlide = styled.div`
  width: 100%;
  height: 100%;
  transition: border-color 0.15s;
  cursor: pointer;
  padding: 24px 0;

  &:focus {
    outline: none !important;
  }
`

const FullScreenMainSlide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 50px;

  .react-transform-component {
    width: unset !important;
    height: unset !important;
  }

  .react-transform-element {
    width: unset !important;
    height: unset !important;
  }

  & > div {
    height: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }

  &:focus {
    outline: none !important;
  }
`

const ArrowButton = styled.button<ArrowBtnProps>`
  position: absolute;
  left: ${ifProp({left: true}, '8px', 'unset')};
  right: ${ifProp({right: true}, '8px', 'unset')};

  top: calc(50% - 16px);
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  ${media.md`
    left: ${ifProp({left: true}, '16px', 'unset')};
    right: ${ifProp({right: true}, '16px', 'unset')};
  `}
`

const FullScreenMiniSlideWrapper = styled.div``

export const isMobile = () => {
  const minScreenWidth = styledTheme.screenSize.sizeMD.slice(0, -2)
  return window.innerWidth < Number(minScreenWidth)
}

function ZoomableImage({img}: ZoomableImageProps) {
  const imgRef = useRef<HTMLImageElement>()
  const [transformStyle, setTransformStyle] = useState<React.CSSProperties>()

  const onZoom = useCallback(
    ({x, y, scale}) => {
      const {current: img} = imgRef

      if (img) {
        const value = make3dTransformValue({x, y, scale})

        setTransformStyle({transform: value})
      }
    },
    [imgRef],
  )
  return (
    <FullScreenMainSlide>
      <QuickPinchZoom
        onUpdate={onZoom}
        isTouch={() => isMobile()}
        draggableUnZoomed={false}
      >
        <FullScreenImage
          src={img.image.productGallery2x}
          ref={imgRef}
          alt={img.alt}
          style={transformStyle}
        />
      </QuickPinchZoom>
    </FullScreenMainSlide>
  )
}

export default function FullScreenImageGallery({
  images,
  currentSlide,
  setCurrentSlide,
}: Props) {
  const [miniSlider, setMiniSlider] = useState<Slider>(undefined)
  const [mainSlider, setMainSlider] = useState<Slider>(undefined)

  const numSlides: number = images.length

  const onClickPrev = useCallback(() => {
    if (currentSlide > 0) {
      miniSlider?.slickPrev()
      setCurrentSlide(currentSlide - 1)
    }
  }, [currentSlide, miniSlider, setCurrentSlide])

  const onClickNext = useCallback(() => {
    if (currentSlide < numSlides - 1) {
      miniSlider?.slickNext()
      setCurrentSlide(currentSlide + 1)
    }
  }, [numSlides, currentSlide, miniSlider, setCurrentSlide])

  const focusNextSlide = useCallback(() => {
    if (currentSlide < numSlides - 1) {
      mainSlider?.slickNext()
      setCurrentSlide(currentSlide + 1)
    }
  }, [currentSlide, numSlides, setCurrentSlide, mainSlider])

  const focusPrevSlide = useCallback(() => {
    if (currentSlide > 0) {
      mainSlider?.slickPrev()
      setCurrentSlide(currentSlide - 1)
    }
  }, [currentSlide, setCurrentSlide, mainSlider])

  const mainSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 400,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    adaptiveHeight: true,
    beforeChange: (_: number, index: number) =>
      setCurrentSlide(Math.ceil(index)),
  }

  const miniSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 400,
    slidesToShow: 7,
    slidesToScroll: 1,
    swipeToSlide: true,
    waitForAnimate: false,
    focusOnSelect: true,
    accessibility: true,
    responsive: [
      {
        breakpoint: gridTheme?.breakpoints?.md,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: gridTheme?.breakpoints?.sm,
        settings: {
          speed: 200,
          slidesToShow: 3,
        },
      },
    ],
  }

  useEffect(() => {
    if (mainSlider) {
      mainSlider.slickGoTo(currentSlide)
    }
  }, [currentSlide, mainSlider])

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Tab' && e.shiftKey) {
        e.preventDefault()
        focusPrevSlide()
      } else if (e.key === 'Tab') {
        e.preventDefault()
        focusNextSlide()
      }
    },
    [focusPrevSlide, focusNextSlide],
  )

  return (
    <FullScreenWrapper>
      <FullScreenMainContainer>
        <Slider
          ref={(ref) => setMainSlider(ref)}
          asNavFor={miniSlider}
          {...mainSettings}
        >
          {images.map((img) => (
            <ZoomableImage key={img.id} img={img} />
          ))}
        </Slider>
      </FullScreenMainContainer>
      <FullScreenMiniContainer>
        <ArrowButton
          onClick={onClickPrev}
          disabled={currentSlide <= 0}
          left
          tabIndex={-1}
        >
          <ArrowLeftSLineIcon size={32} color={styledTheme.palette.lightBlue} />
        </ArrowButton>
        <Slider
          ref={(ref) => setMiniSlider(ref)}
          asNavFor={mainSlider}
          {...miniSettings}
        >
          {images.map((img, index) => (
            <FullScreenMiniSlideWrapper key={img.id}>
              <FullScreenMiniSlide
                onKeyDown={onKeyDown}
                tabIndex={index === currentSlide ? 0 : -1}
              >
                <FullScreenMiniImage
                  src={img.image.productGallery}
                  selected={index === currentSlide}
                />
              </FullScreenMiniSlide>
            </FullScreenMiniSlideWrapper>
          ))}
        </Slider>
        <ArrowButton
          onClick={onClickNext}
          disabled={currentSlide >= numSlides - 1}
          right
          tabIndex={-1}
        >
          <ArrowRightSLineIcon
            size={32}
            color={styledTheme.palette.lightBlue}
          />
        </ArrowButton>
      </FullScreenMiniContainer>
    </FullScreenWrapper>
  )
}
