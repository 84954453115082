import React from 'react'

import styled from 'styled-components'
import {palette} from 'styled-tools'

import {InfoTooltip} from '@festi/common/components/common'
import {Property} from '@festi/common/utils/products'
import {fluidRange} from '@festi/common/utils/styles'

interface Props {
  title: string
  items: Property[]
}

const Wrapper = styled.div`
  ${fluidRange('margin-bottom', '20px', '30px')};
`

const Title = styled.span`
  display: block;
  width: 100%;
  text-align: left;
  color: ${palette('blue')};
  font-size: 1rem;
  font-weight: 700;
  border-bottom: 1px solid ${palette('lightBlue')};
  line-height: 1.3;
  padding: 0 0 5px 0;
`

const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px 0;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid ${palette('ui20Solid')};
  }
`

const ItemText = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  color: ${palette('blue')};

  &:last-child {
    text-align: right;
  }
`

const ListedItemText = styled.span`
  position: relative;
  padding-right: 4px;

  &:not(:last-child):after {
    content: ',';
  }
`

export default function ProductPropertiesTable({
  title,
  items,
}: Props): JSX.Element {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {items.flatMap(({key, value, info, isProperty}: Property) =>
        value && isProperty
          ? [
              <ListItem key={`${key}-${value}`}>
                <ItemText>
                  {key}
                  {!!info && (
                    <InfoTooltip id={`product-property-${key}`} small>
                      {info}
                    </InfoTooltip>
                  )}
                </ItemText>

                <ItemText>
                  {Array.isArray(value)
                    ? value.map((val: string) => (
                        <ListedItemText key={val}>{val}</ListedItemText>
                      ))
                    : value || '--'}
                </ItemText>
              </ListItem>,
            ]
          : [],
      )}
    </Wrapper>
  )
}
