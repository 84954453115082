import React, {useCallback} from 'react'

import Link from 'next/link'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import SubtractLineIcon from 'remixicon-react/SubtractLineIcon'
import RecordCircleFillIcon from 'remixicon-react/RecordCircleFillIcon'

import {styledTheme} from '@festi/common/themes'
import {CategoryTreeItem} from '@festi/common/utils/categories'

import {Expandable} from '../common'

const SubCategoryLinkText = styled.span`
  position: relative;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1;
  color: ${palette('blue')};

  &:before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    display: block;
    height: 2px;
    width: 0;
    background-color: ${palette('green')};
    transition: width 0.15s ease-out;
  }
`

const FirstSubCategoryLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 16px;
  border-top: 1px solid ${palette('ui10Solid')};
  cursor: pointer;

  &:hover {
    ${SubCategoryLinkText} {
      &:before {
        width: 100%;
      }
    }
  }

  ${media.md`
    border: 0;
    padding: 8px 0;
  `}
`

const Item = styled.div`
  padding: 0 16px;
  break-inside: avoid-column;

  &:nth-child(1) {
    ${FirstSubCategoryLink} {
      border-top: 0;
    }
  }

  ${media.md`
    padding: 0 8px;
  `}
`

const SecondSubCategoryLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px 8px 16px;
  font-size: 1.125rem;
  color: ${palette('blue')};

  &:last-child {
    padding-bottom: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 2px);
    left: 10px;
    display: block;
    height: 4px;
    width: 10px;
    border-radius: 2px;
    background-color: ${palette('green')};
    transform: translateX(-8px);
    opacity: 0;
    transition: transform 0.15s ease-out, opacity 0.15s;
  }

  .remixicon-icon {
    margin-right: 10px;
    transition: opacity 0.15s;
  }

  &:hover {
    &:before {
      transform: translateX(0);
      opacity: 1;
    }

    .remixicon-icon {
      opacity: 0;
    }
  }
`

const SecondSubCategoryWrapper = styled.div`
  overflow: hidden;

  ${media.md`
    display: none;
  `}
`

interface Props {
  categoryItem: CategoryTreeItem
  selectedSubCategory?: CategoryTreeItem
  onClickCategory: () => void
  setSelectedSubCategory?: React.Dispatch<
    React.SetStateAction<CategoryTreeItem | undefined>
  >
}

export default function SubMenuCategoryItem({
  categoryItem,
  selectedSubCategory,
  onClickCategory,
  setSelectedSubCategory,
}: Props): JSX.Element {
  const showSubCategories = selectedSubCategory === categoryItem

  const onClickSubCategory = useCallback(() => {
    !!setSelectedSubCategory &&
      setSelectedSubCategory(showSubCategories ? undefined : categoryItem)
  }, [categoryItem, showSubCategories, setSelectedSubCategory])

  return (
    <Item>
      {!categoryItem.children?.length ? (
        <Link
          href={`/voruflokkar/${categoryItem.slug}`}
          passHref
          legacyBehavior
        >
          <FirstSubCategoryLink onClick={onClickCategory}>
            <SubCategoryLinkText>{categoryItem.name}</SubCategoryLinkText>
          </FirstSubCategoryLink>
        </Link>
      ) : (
        <FirstSubCategoryLink onClick={onClickSubCategory}>
          <SubCategoryLinkText>{categoryItem.name}</SubCategoryLinkText>

          {showSubCategories ? (
            <SubtractLineIcon size={24} color={styledTheme.palette.green} />
          ) : (
            <AddLineIcon size={24} color={styledTheme.palette.blue} />
          )}
        </FirstSubCategoryLink>
      )}

      <SecondSubCategoryWrapper>
        <Expandable expanded={showSubCategories}>
          {categoryItem.children?.map((subCategoryItem: CategoryTreeItem) => (
            <SecondSubCategoryLink
              key={subCategoryItem.id}
              href={`/voruflokkar/${subCategoryItem.slug}`}
              passHref
              onClick={onClickCategory}
            >
              <RecordCircleFillIcon
                size={4}
                color={styledTheme.palette.ui20Solid}
              />
              {subCategoryItem.name}
            </SecondSubCategoryLink>
          ))}
        </Expandable>
      </SecondSubCategoryWrapper>
    </Item>
  )
}
