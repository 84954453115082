import React, {useCallback} from 'react'

import cn from 'classnames'
import {palette} from 'styled-tools'
import HeartFillIcon from 'remixicon-react/HeartFillIcon'
import HeartLineIcon from 'remixicon-react/HeartLineIcon'

import {CheckButton, IconButton} from '@festi/common/components/buttons'
import {ProductPrices} from '@festi/common/components/cart/ProductPrices'
import {CardSubtitle, CardTitle} from '@festi/common/components/typography'
import {useAuth, useComparison, useEvents} from '@festi/common/contexts'
import useTagmanager from '@festi/common/utils/tagmanager'
import {useWishlist} from '@festi/common/utils/wishlists'
import {getListingPrice} from '@festi/common/utils/price'

import ProductImage from './ProductImage'
import {ProductCardProps, TitleBoxProps} from './interface'
import {SlideInnerProps} from 'src/components/common/CustomSlider'
import {ProductTags, SellingPoint} from 'src/components/products'
import ProductImageBanner, {
  itemBannerColorMap,
} from 'src/components/products/ProductImageBanner'
import {
  CardBody,
  CardFoot,
  CardFootWrapper,
  CardHead,
  CardLink,
  CardText,
  CardWrapper,
  ComparisonBtnWrapper,
  OuterWrapper,
  TagWrapper,
  TitleWrapper,
} from './styles'
import {hasFewInStock, isOutOfStock} from '@festi/common/utils/products'
import {onDutyFree} from '@festi/common/constants/channels'
import {formatTags} from 'src/components/products/ProductTag'

function TitleBox({
  title,
  subtitle,
  showInMobile,
  showInDesktop,
}: TitleBoxProps): JSX.Element {
  return (
    <TitleWrapper className={cn({showInMobile, showInDesktop})}>
      <CardTitle>{title}</CardTitle>
      <CardSubtitle onClick={(e) => e.preventDefault()}>
        {subtitle}
      </CardSubtitle>
    </TitleWrapper>
  )
}

export default function ProductCard({
  origin,
  position,
  variant,
  prefetch = true,
  userPrice,
  userPriceLoading,
  isFeatured,
  isHidden = false,
  withGutter,
  onClick,
  onKeyDown,
  onMouseDown,
}: ProductCardProps & SlideInnerProps): JSX.Element {
  const {user} = useAuth()
  const {sendClickEvent} = useEvents()
  const {comparedSkus} = useComparison()
  const {sendEcommerceClickEvent} = useTagmanager()
  const {addToComparison, removeFromComparison} = useComparison()
  const {toggleVariantInWishlist, isVariantInWishlist} = useWishlist()

  const [bannerColor] = itemBannerColorMap?.[variant?.banner] ?? []

  const {
    sku,
    name,
    product,
    firstImage,
    recommended,
    inventoryStatus,
    isInStockDutyfree,
    isInStockWeb,
  } = variant

  const {slug, sellingPoints} = product

  const listings = getListingPrice(variant)
  const price = listings?.price
  const lowestPrice = listings?.lowestPrice
  const discountedPrice = listings?.discountedPrice

  const fewInStock = hasFewInStock(inventoryStatus)
  const outOfStock = isOutOfStock(inventoryStatus)
  const outOfStockWeb = !(onDutyFree ? isInStockDutyfree : isInStockWeb)

  const tags = formatTags(variant)
  const hasTag = tags?.length === 1
  const hasMultipleTags = tags?.length > 1

  const inComparison = comparedSkus?.indexOf(sku) !== -1

  const hasUserPrice = !!user && !userPriceLoading && !!userPrice

  const highlightColor = inComparison
    ? palette('lightBlue')
    : isVariantInWishlist(sku)
    ? palette('pink')
    : bannerColor
    ? bannerColor
    : recommended
    ? palette('green')
    : undefined

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      !!onClick && onClick(e)
      // TODO: Combine events for google analytics and algolia
      sendEcommerceClickEvent(variant, position, origin)

      sendClickEvent(
        'clickedObjectIDsAfterSearch',
        'Product Clicked After Search',
        sku,
        position,
      )
    },
    [
      sku,
      origin,
      variant,
      position,
      onClick,
      sendClickEvent,
      sendEcommerceClickEvent,
    ],
  )

  return (
    <OuterWrapper className={cn({withGutter})}>
      <CardWrapper highlightColor={highlightColor}>
        <CardLink
          href={`/vorur/${slug}/${sku}`}
          prefetch={prefetch ? undefined : false}
          passHref
          draggable={false}
          onClick={handleClick}
          tabIndex={isHidden ? -1 : 0}
          onKeyDown={onKeyDown}
          onMouseDown={onMouseDown}
        >
          <CardHead
            className={cn({
              isFeatured,
              hasTag,
            })}
          >
            <ProductImage
              src={firstImage?.image?.productGallery}
              alt={firstImage?.alt || name}
              isFeatured={isFeatured}
              showInMobile={isFeatured}
              fewInStock={fewInStock}
              outOfStock={outOfStock}
              outOfStockWeb={outOfStockWeb}
            />

            <TitleBox
              title={name}
              subtitle={sku}
              showInMobile
              showInDesktop={!isFeatured}
            />
          </CardHead>

          <CardBody
            className={cn({
              isFeatured,
              hasMultipleTags,
            })}
          >
            <ProductImage
              src={firstImage?.image?.productGallery}
              alt={firstImage?.alt || name}
              isFeatured={isFeatured}
              showInMobile={!isFeatured}
              fewInStock={fewInStock}
              outOfStock={outOfStock}
              outOfStockWeb={outOfStockWeb}
            />

            <CardText className={cn({isFeatured})}>
              <TitleBox
                title={name}
                subtitle={sku}
                showInMobile={false}
                showInDesktop={isFeatured}
              />
              {sellingPoints.slice(0, 4).map((point: string, index) => (
                <SellingPoint
                  key={`${sku}-${index}`}
                  point={point}
                  isFeatured={isFeatured}
                />
              ))}
            </CardText>
          </CardBody>

          {/* {isFeatured && <CardStockStatus></CardStockStatus>} */}

          <CardFootWrapper className={cn({isFeatured})}>
            <CardFoot>
              {hasUserPrice ? (
                <ProductPrices
                  price={userPrice.price.price}
                  lowestPrice={userPrice.lowestPrice}
                  discountedPrice={userPrice.userPrice}
                />
              ) : (
                <ProductPrices
                  price={price}
                  lowestPrice={lowestPrice}
                  discountedPrice={discountedPrice}
                />
              )}
            </CardFoot>
          </CardFootWrapper>

          <TagWrapper>
            <ProductTags variant={variant} />
          </TagWrapper>
        </CardLink>

        <ComparisonBtnWrapper>
          {!!user && (
            <IconButton
              className={cn('wishlist')}
              large={true}
              backgroundColor={'white'}
              accentColor={'pink'}
              icon={isVariantInWishlist(sku) ? HeartFillIcon : HeartLineIcon}
              onClick={() => toggleVariantInWishlist(variant)}
            />
          )}
          <CheckButton
            className={cn('comparison')}
            checked={inComparison}
            onClick={
              inComparison
                ? () => removeFromComparison(sku)
                : () => addToComparison(variant)
            }
            tabIndex={-1}
          >
            Setja í samanburð
          </CheckButton>
        </ComparisonBtnWrapper>

        <ProductImageBanner variant={variant} />
      </CardWrapper>
    </OuterWrapper>
  )
}
