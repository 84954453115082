import {useRouter} from 'next/router'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {useAuth, useCheckout} from '@festi/common/contexts'

import {fluidRange} from '../../utils/styles'
import {ActionAnchor} from '../buttons'
import {SideDrawer} from '../layout'
import EIDForm from './EIDForm'
import RegisterForms from './RegisterForms'

interface Props {
  isOpen?: boolean
  onRequestClose?: () => void
}

interface AuthProps {
  inCheckout: boolean
}

const ContentWrapper = styled.div`
  ${fluidRange('padding-top', '44px', '88px')}
  ${fluidRange('padding-bottom', '16px', '32px')}
  ${fluidRange('padding-left', '32px', '56px')}
  ${fluidRange('padding-right', '32px', '56px')}
`

const GuestSuggestionBox = styled.div`
  border-top: 1px solid ${palette('ui20Solid')};
  padding-top: 24px;
  padding-bottom: 24px;
  ${fluidRange('padding-left', '32px', '56px')}
  ${fluidRange('padding-right', '32px', '56px')}
`

export function AuthSection({inCheckout}: AuthProps): JSX.Element {
  const {user, missingAttributes} = useAuth()
  const {hasInsurableLines} = useCheckout()

  const showRegisterForms = user?.id && missingAttributes

  return (
    <>
      <ContentWrapper>
        {showRegisterForms ? (
          <RegisterForms />
        ) : (
          <EIDForm inCheckout={inCheckout} />
        )}
      </ContentWrapper>

      {inCheckout && (
        <GuestSuggestionBox>
          <ActionAnchor
            href={hasInsurableLines ? '/karfa/trygging' : '/kaup'}
            icon={ArrowRightSLineIcon}
          >
            Ganga frá kaupum sem gestur
          </ActionAnchor>
        </GuestSuggestionBox>
      )}
    </>
  )
}

export default function AuthDrawer({
  isOpen,
  onRequestClose,
}: Props): JSX.Element {
  const router = useRouter()

  const inCheckout = router.asPath === '/karfa'

  return (
    <SideDrawer
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      showCloseBtn={inCheckout}
    >
      <AuthSection inCheckout={inCheckout} />
    </SideDrawer>
  )
}
