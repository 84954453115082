import cn from 'classnames'
import {useSelect, UseSelectStateChange} from 'downshift'
import {RemixiconReactIconComponentType} from 'remixicon-react'
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  transition: box-shadow 0.15s;

  &.isOpen {
    box-shadow: ${theme('boxShadow.select')};
  }
`

const IconWrapper = styled.div`
  transform: translateY(-7px);
  margin-right: 8px;

  .remixicon-icon {
    color: ${palette('ui20Solid')};
  }

  &.isActive {
    .remixicon-icon {
      color: ${palette('lightBlue')};
    }

    &.isPurple {
      .remixicon-icon {
        color: ${palette('purple')};
      }
    }
  }
`

const Placeholder = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: ${palette('ui50Solid')};
  line-height: 1;
  position: absolute;
  left: 16px;
  top: 20px;
  text-align: left;
  pointer-events: none;
  transition: color 0.15s, transform 0.15s ease-out, color 0.15s;

  &.isActive {
    font-weight: 500;
    color: ${palette('lightBlue')};
    transform: scale(0.75) translateX(-10px) translateY(-18px);

    &.isPurple {
      color: ${palette('purple')};
    }

    &.noSelectedItem {
      display: none;

      ${media.sm`
        display: block;
      `}
    }
  }

  &.hasIcon {
    left: 48px;
  }
`

const Adornment = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  top: calc(50% - 12px);
  right: 16px;
  transition: transform 0.15s ease-out;

  .remixicon-icon {
    color: ${palette('ui20Solid')};
  }

  &.isOpen {
    transform: rotate(180deg);

    .remixicon-icon {
      color: ${palette('lightBlue')};
    }

    &.isPurple {
      .remixicon-icon {
        color: ${palette('purple')};
      }
    }
  }
`

const SelectBtn = styled.button`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 56px;
  border: 0;
  display: flex;
  align-items: center;
  background-color: ${palette('white')};
  z-index: 0;
  padding: 14px 42px 0 16px;
  font-weight: 500;
  font-size: 1rem;
  color: ${palette('blue')};
  text-align: left;
  border-bottom: 1px solid ${palette('ui20Solid')};
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  transition: border-color 0.15s;

  &.isOpen {
    border-color: ${palette('lightBlue')};

    &.isPurple {
      border-top-color: ${palette('purple')};
    }

    &.hasSelectedItem {
    }
  }

  &:focus&:not(:disabled),
  &:hover&:not(:disabled) {
    outline: none;
    border-color: ${palette('lightBlue')};

    &.isPurple {
      border-color: ${palette('purple')};
    }

    ${Adornment} {
      .remixicon-icon {
        fill: ${palette('lightBlue')};
      }

      &.isPurple {
        .remixicon-icon {
          fill: ${palette('purple')};
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`

const Menu = styled.ul`
  position: absolute;
  background-color: ${palette('white')};
  max-height: 280px;
  width: 100%;
  overflow-y: scroll;
  padding: 0;
  list-style: none;
  opacity: 0;
  pointer-events: none;
  z-index: ${theme('zIndex.select')};
  transition: opacity 0.15s;
  overflow: auto;

  &.isOpen {
    opacity: 1;
    pointer-events: unset;
  }

  &:focus {
    outline: none;
  }
`

const ListItem = styled.li`
  height: 56px;
  width: 100%;
  color: ${palette('blue')};
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0 16px;
  text-align: center;
  border-top: 1px solid transparent;
  border-bottom: 1px solid ${palette('border')};
  cursor: pointer;
  background-color: transparent;
  transition: border-color 0.15s, background-color 0.15s;

  &.isSelected {
    background-color: ${palette('lightBlue10')};
    border-top-color: ${palette('lightBlue')};
    border-bottom-color: ${palette('lightBlue')};

    &.isPurple {
      background-color: ${palette('purple10')};
      border-top-color: ${palette('purple')};
      border-bottom-color: ${palette('purple')};
    }
  }

  &.isHighlighted {
    background-color: ${palette('light')};
  }

  &:last-child {
    border-bottom-color: ${palette('lightBlue')};

    &.isPurple {
      border-bottom-color: ${palette('purple')};
    }
  }

  &:hover {
    background-color: ${palette('light')};
  }
`

const PlaceholderText = styled.span`
  margin-top: 0;

  &.isOpen.noSelectedItem {
    margin-top: -14px;

    ${media.sm`
      margin-top: 0;
    `}
  }
`

interface Props<Item> {
  placeholder: string
  items: Item[]
  selectedItem?: Item
  isPurple?: boolean
  disabled?: boolean
  icon?: RemixiconReactIconComponentType
  onClick?: () => void
  itemToString?: (item: Item | null) => string
  handleSelectedItemChange?: (changes: UseSelectStateChange<Item>) => void
}

export default function Select<Item>({
  placeholder,
  items,
  selectedItem: initialSelectedItem,
  icon: Icon,
  isPurple,
  disabled,
  onClick,
  itemToString = (item) => item as unknown as string,
  handleSelectedItemChange,
}: Props<Item>): JSX.Element {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useSelect({
    items,
    selectedItem: initialSelectedItem,
    itemToString,
    onSelectedItemChange: handleSelectedItemChange,
  })

  const isActive = isOpen || !!selectedItem

  return (
    <SelectContainer className={cn({isOpen})}>
      <SelectBtn
        type="button"
        className={cn({isOpen, isPurple})}
        disabled={disabled}
        {...getToggleButtonProps({onClick})}
      >
        {!!Icon && (
          <IconWrapper className={cn({isActive, isPurple})}>
            <Icon size={24} />
          </IconWrapper>
        )}

        <PlaceholderText
          className={cn({isOpen, noSelectedItem: !selectedItem})}
        >
          {isOpen && !selectedItem && 'Veldu möguleika'}
        </PlaceholderText>

        {itemToString(selectedItem)}

        <Placeholder
          className={cn({
            isActive,
            isPurple,
            hasIcon: !!Icon,
            noSelectedItem: !selectedItem,
          })}
        >
          {placeholder}
        </Placeholder>

        <Adornment className={cn({isOpen, isPurple})}>
          <ArrowDownSLineIcon size={24} />
        </Adornment>
      </SelectBtn>

      <Menu
        {...getMenuProps()}
        className={cn({isOpen})}
        aria-label="Raða eftir"
      >
        {items.map((item, index) => {
          const highlighted = highlightedIndex === index
          const props = getItemProps({item, index})
          const isSelected = props.isSelected || item === selectedItem

          return (
            <ListItem
              key={index}
              {...props}
              className={cn({
                isSelected,
                isHighlighted: highlighted,
                isPurple,
              })}
            >
              {itemToString(item)}
            </ListItem>
          )
        })}
      </Menu>
    </SelectContainer>
  )
}
