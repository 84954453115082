import {useEffect, useState} from 'react'

import {withFormik, FormikProps} from 'formik'
import TagManager from 'react-gtm-module'
import {toast} from 'react-toastify'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {client} from '@festi/common/api/datocms'
import {textQuery} from '@festi/common/api/datocms/queries/text-queries'
import {Text} from '@festi/common/api/datocms/types/Text'
import {restApi} from '@festi/common/api/rest'

import {Button} from '../buttons'
import DatoStructuredText from '../common/DatoStructuredText'
import {FormikCheckboxBtn} from '../form'
import {H4, P} from '../typography'
import {AuthFormProps} from './RegisterForms'

interface FormValues {
  terms: boolean
}

interface ErrorProps {
  terms?: string
}

const TermsWrapper = styled.div`
  margin-bottom: 20px;
`

const TermsScrollBox = styled.div`
  padding: 24px 32px;
  font-size: 0.875rem;
  color: ${palette('blue')};
  border: 1px solid ${palette('ui10Solid')};
  margin-bottom: 20px;
  height: 250px;
  overflow-y: scroll;
  line-height: 1.5;
`

const Form = ({
  handleSubmit,
  isSubmitting,
}: FormikProps<FormValues> & AuthFormProps): JSX.Element => {
  const [terms, setTerms] = useState<Text>()

  useEffect(() => {
    async function getTerms() {
      const {data} = await client().query<Text>({
        query: textQuery,
        variables: {
          name: 'terms_and_conditions',
        },
      })
      setTerms(data)
    }
    getTerms()
  })

  return (
    <form onSubmit={handleSubmit}>
      <H4 withGutter>Samþykkja skilmála</H4>
      <P withGutter>
        Vinnsamlegast samþykktu notendaskilmála til þess að geta haldið áfram
      </P>
      <TermsWrapper>
        <TermsScrollBox>
          <DatoStructuredText content={terms?.text.content} />
        </TermsScrollBox>
        <FormikCheckboxBtn name="terms" label="Samþykkja skilmála ELKO" />
      </TermsWrapper>
      <Button
        type="submit"
        withBar
        loading={isSubmitting}
        icon={ArrowRightSLineIcon}
        accentColor="lightBlue"
      >
        Áfram
      </Button>
    </form>
  )
}

const TermsForm = withFormik<AuthFormProps, FormValues>({
  mapPropsToValues: () => ({
    terms: false,
  }),

  validate: ({terms}) => {
    const errors: ErrorProps = {}

    if (!terms) {
      errors.terms =
        'Nauðsynlegt er að samþykkja skilmála til þess að halda áfram'
    }

    return errors
  },

  handleSubmit: ({terms}, {props: {onComplete}}) => {
    restApi
      .usersMeTermsCreate({
        generalTerms: terms,
      })
      .then(() => {
        !!onComplete && onComplete()

        TagManager.dataLayer({
          dataLayer: {
            category: 'login',
            event: 'account terms accepted',
          },
          dataLayerName: 'dataLayer',
        })
      })
      .catch((err) => {
        toast.error('Eitthvað fór úrskeiðis, reynið aftur síðar')
        throw err
      })
  },

  displayName: 'TermsForm',
})(Form)

export default TermsForm
