import React from 'react'

import InformationLineIcon from 'remixicon-react/InformationLineIcon'
import styled from 'styled-components'
import {css} from 'styled-components'
import {palette, switchProp} from 'styled-tools'

import {PSmall} from '@festi/common/components/typography'

interface Props {
  energyClass?: string
  oldEnergyClass?: string
}

const OldLabel = styled.div`
  ${PSmall} {
    color: ${palette('ui50Solid')};
  }
`

const common = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 38px;
  margin-bottom: 8px;
`

const OldLabelWrapper = styled.div<Props>`
  ${common}
  width: 60px;

  background-image: ${switchProp('oldEnergyClass', {
    'A+++': 'url("/energy-arrows/energy_label_left_appp.svg")',
    'A++': 'url("/energy-arrows/energy_label_left_app.svg")',
    'A+': 'url("/energy-arrows/energy_label_left_ap.svg")',
    A: 'url("/energy-arrows/energy_label_left_a.svg")',
    B: 'url("/energy-arrows/energy_label_left_b.svg")',
    C: 'url("/energy-arrows/energy_label_left_c.svg")',
    D: 'url("/energy-arrows/energy_label_left_d.svg")',
  })};
`

const NewLabelWrapper = styled.div<Props>`
  ${common}
  width: 67px;

  background-image: ${switchProp('energyClass', {
    A: 'url(/energy-arrows/energy_class_a.svg)',
    B: 'url(/energy-arrows/energy_class_b.svg)',
    C: 'url(/energy-arrows/energy_class_c.svg)',
    D: 'url(/energy-arrows/energy_class_d.svg)',
    E: 'url(/energy-arrows/energy_class_e.svg)',
    F: 'url(/energy-arrows/energy_class_f.svg)',
    G: 'url(/energy-arrows/energy_class_g.svg)',
  })};
`

const IconWrapper = styled.span`
  display: inline-block;
  padding-right: 2px;
  vertical-align: text-top;
`

export default function EnergyLabel({
  energyClass,
  oldEnergyClass,
}: Props): JSX.Element {
  if (energyClass && energyClass !== 'Ekki til staðar') {
    return <NewLabelWrapper energyClass={energyClass} />
  } else if (oldEnergyClass) {
    return (
      <OldLabel>
        <OldLabelWrapper oldEnergyClass={oldEnergyClass} />
        {energyClass === 'Ekki til staðar' && (
          <PSmall>
            <IconWrapper>
              <InformationLineIcon size={16} />
            </IconWrapper>
            Orkuflokkur á þessari vöru hefur ekki verið uppfærður í nýjan
            staðal.
          </PSmall>
        )}
      </OldLabel>
    )
  }

  return null
}
