import React, {useState} from 'react'

import AddLineIcon from 'remixicon-react/AddLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'

import {Expandable} from '../common'

interface Props {
  label: string
  children: React.ReactNode
  initiallyOpen?: boolean
}

const Card = styled.div`
  position: relative;
  ${fluidRange('margin-bottom', '16px', '24px')}
  width: 100%;
  box-sizing: border-box;
  border: 1px dashed ${palette('ui20Solid')};
  background-color: ${palette('light')};
  overflow: hidden;
  transition: border-color 0.15s, background-color 0.15s, box-shadow 0.15s;
`

const ToggleBtn = styled.button`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 24px;
  width: 100%;
  border: 0;
  background: none;
  cursor: pointer;

  .remixicon-icon {
    margin-right: 16px;
    transition: transform 0.15s ease-out;
  }
`

const BtnLabel = styled.span`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${palette('blue')};
`

const FormContainer = styled.div`
  ${fluidRange('padding', '16px', '24px')}
  padding-top: 8px !important;
`

export default function CreateFormWrapper({
  label,
  children,
  initiallyOpen = false,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(initiallyOpen)

  return (
    <Card
      style={{
        borderColor: isOpen
          ? styledTheme.palette.lightBlue
          : styledTheme.palette.ui20Solid,
        backgroundColor: isOpen
          ? styledTheme.palette.white
          : styledTheme.palette.light,
        boxShadow: isOpen ? styledTheme.boxShadow.card : '',
      }}
    >
      <ToggleBtn onClick={() => setIsOpen(!isOpen)}>
        <AddLineIcon
          style={{transform: isOpen ? 'rotate(45deg)' : ''}}
          size={32}
          color={styledTheme.palette.lightBlue}
        />
        <BtnLabel>{label}</BtnLabel>
      </ToggleBtn>
      <Expandable expanded={isOpen}>
        <FormContainer>{children}</FormContainer>
      </Expandable>
    </Card>
  )
}
