import {withFormik, FormikProps} from 'formik'
import {isValidPhoneNumber} from 'libphonenumber-js'
import TagManager from 'react-gtm-module'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

import {TabComponent} from '@festi/common/components/common'
import useCognito from '@festi/common/utils/useCognito'
import {isValidSsn, parsePhoneNumber} from '@festi/utils/strings'

import {Button} from '../buttons'
import {AudkenniLoader, FormikCleaveInput, SsnInput} from '../form'
import {P, H3, H4, H6} from '../typography'
import {AuthFormProps} from './RegisterForms'

enum EIDTabs {
  phone = 'Farsími',
  app = 'Auðkennisapp',
}

interface FormValues {
  tab?: EIDTabs
  ssn?: string
  phoneNumber: string
}

interface MyFormProps extends AuthFormProps {
  inCheckout?: boolean
  securityCode?: string
  appLogin?: (ssn: string) => Promise<unknown>
  phoneLogin?: (phone: string) => Promise<unknown>
}

interface ErrorProps {
  ssn?: string
  phoneNumber?: string
}

const Form = ({
  inCheckout,
  isSubmitting,
  securityCode,
  handleSubmit,
  setFieldValue,
}: FormikProps<FormValues> & MyFormProps): JSX.Element => {
  return (
    <form onSubmit={handleSubmit}>
      {inCheckout ? (
        <H4 withGutter>
          Skráðu þig inn með rafrænum skilríkjum til að auðvelda kaupin
        </H4>
      ) : (
        <H3 withGutter>Skráðu þig inn með rafrænum skilríkjum</H3>
      )}

      {inCheckout && (
        <P withGutter>
          Skráðu þig inn til að flýta fyrir greiðslu og
          vista upplýsingar fyrir næstu pöntun.
        </P>
      )}

      <TabComponent
        tabs={[EIDTabs.phone, EIDTabs.app]}
        onSelect={(tab) => setFieldValue('tab', tab)}
      >
        <FormikCleaveInput
          name="phoneNumber"
          type="tel"
          placeholder="Símanúmer"
          disabled={isSubmitting}
          options={{phone: true, phoneRegionCode: 'IS'}}
        />

        <SsnInput name="ssn" placeholder="Kennitala" disabled={isSubmitting} />
      </TabComponent>

      <AudkenniLoader loading={isSubmitting} withBubble />

      {securityCode && (
        <AudkenniLoader
          msg={<H6 withGutter>Öryggiskóði: {securityCode}</H6>}
          loading={isSubmitting}
        />
      )}

      <Button
        type="submit"
        withBar
        loading={isSubmitting}
        icon={ArrowRightSLineIcon}
        accentColor={inCheckout ? 'green' : 'lightBlue'}
      >
        Innskrá
      </Button>
    </form>
  )
}

const EIDForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: () => ({
    tab: EIDTabs.phone,
    ssn: '',
    phoneNumber: '',
  }),

  validate: ({tab, ssn, phoneNumber}) => {
    const errors: ErrorProps = {}

    if (tab === EIDTabs.phone) {
      if (!phoneNumber) {
        errors.phoneNumber = 'Hér vantar símanúmer'
      } else if (parsePhoneNumber(phoneNumber).length !== 11) {
        errors.phoneNumber = 'Símanúmer ekki á réttu formi'
      } else if (
        (phoneNumber.startsWith('+') && !phoneNumber.startsWith('+354')) ||
        (phoneNumber.startsWith('00') && !phoneNumber.startsWith('00 354'))
      ) {
        errors.phoneNumber = 'Forskeyti ekki rétt'
      } else if (!isValidPhoneNumber(phoneNumber, 'IS')) {
        errors.phoneNumber = 'Ekki leyfilegt símanúmer'
      }
    }
    if (tab === EIDTabs.app) {
      if (!ssn) {
        errors.ssn = 'Hér vantar kennitölu'
      } else if (!isValidSsn(ssn)) {
        errors.ssn = 'Ógild kennitala'
      }
    }

    return errors
  },

  handleSubmit: (
    {tab, ssn, phoneNumber},
    {props: {appLogin, phoneLogin}, setSubmitting},
  ) => {
    TagManager.dataLayer({
      dataLayer: {
        category: 'login',
        event: 'rafraen skilriki started',
      },
      dataLayerName: 'dataLayer',
    })

    if (tab === EIDTabs.phone) {
      phoneLogin(phoneNumber)
        .finally(() => {
          setSubmitting(false)
        })
        .catch(() => {
          setSubmitting(false)
        })
    }

    if (tab === EIDTabs.app) {
      appLogin(ssn)
        .finally(() => {
          setSubmitting(false)
        })
        .catch(() => {
          setSubmitting(false)
        })
    }
  },

  displayName: 'EIDFrom',
})(Form)

export default function EID({inCheckout}: MyFormProps) {
  const {appLogin, phoneLogin, securityCode} = useCognito()

  return (
    <EIDForm
      inCheckout={inCheckout}
      securityCode={securityCode}
      appLogin={appLogin}
      phoneLogin={phoneLogin}
    />
  )
}
