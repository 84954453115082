import React from 'react'
import {useState} from 'react'

import cn from 'classnames'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import Settings2LineIcon from 'remixicon-react/Settings2LineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {
  Expandable,
  ShowDesktop,
  ShowMobile,
  Toggle,
} from '@festi/common/components/common'
import {styledTheme} from '@festi/common/themes'

const Container = styled.div`
  border-top: 1px solid ${palette('ui10Solid')};
  border-bottom: 1px solid ${palette('ui10Solid')};
  margin-bottom: 24px;
  width: 100%;

  button {
    margin-bottom: 8px;
  }

  ${media.md`
    border: 0;
    margin-bottom: 0;
    padding-right: 16px;
  `}
`

const ToggleBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  padding: 16px 0;
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('blue')};
  cursor: pointer;
  line-height: 1;

  ${media.md`
    font-size: 1.125rem;
    pointer-events: none;
    border-bottom: 1px solid ${palette('ui10Solid')};
  `}

  .remixicon-icon {
    transition: transform 0.15s ease-out;

    &.expanded {
      transform: rotate(-45deg);
    }
  }
`

const ToggleBtnHead = styled.div`
  display: flex;
  align-items: center;

  .remixicon-icon {
    margin-right: 8px;
  }
`

const SettingsContent = styled.div`
  padding: 16px 0;
`

export default function ComparisonSettings(): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(false)

  const [showDifference, setShowDifference] = useState<boolean>(false)

  const content = (
    <SettingsContent>
      <Toggle
        label="Sýna mismun"
        checked={showDifference}
        onToggle={() => setShowDifference(!showDifference)}
      />
    </SettingsContent>
  )

  return (
    <Container>
      <ToggleBtn onClick={() => setExpanded(!expanded)}>
        <ToggleBtnHead>
          <Settings2LineIcon size={24} color={styledTheme.palette.lightBlue} />
          Stillingar
        </ToggleBtnHead>
        <ShowMobile>
          <AddLineIcon
            className={cn({expanded})}
            size={24}
            color={styledTheme.palette.blue}
          />
        </ShowMobile>
      </ToggleBtn>
      <ShowMobile>
        <Expandable expanded={expanded}>{content}</Expandable>
      </ShowMobile>
      <ShowDesktop>{content}</ShowDesktop>
    </Container>
  )
}
