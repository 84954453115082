import React, {useState} from 'react'

import {ProductAttributeWrapper} from '.'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import SubtractLineIcon from 'remixicon-react/SubtractLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import useSWR from 'swr'

import {formatPrice} from '@festi/utils/numbers'
import {useSingleUserPrice} from '@festi/common/utils/rest'

interface LettkaupPayLoan {
  AvailableLoanOptions: null
  AveragePaymentAmount: number
  DisbursementAmount: number
  DisbursementFeeAmount: number
  DisbursementFeePercentage: number
  InterestRate: number
  LoanLengthInMonths: number
  PaymentFeePerPayment: number
  TotalFeeAmount: number
  TotalInterestAmount: number
  TotalPrincipalAmount: number
  TotalRepayment: number
  YearlyCostRatio: number
}

const LettKaupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CalculatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
`

const Btn = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  border: 1px solid ${palette('ui20Solid')};
  background-color: transparent;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.15s;
  padding: 0;
  border-radius: 0;
  margin-bottom: 3px;

  .remixicon-icon {
    transition: opacity 0.15s;
  }

  &:disabled {
    cursor: not-allowed;
    border: 0;

    .remixicon-icon {
      opacity: 0.4;
    }
  }

  &:hover:not(:disabled) {
    background-color: ${palette('light')};
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 6px;
`

const PaymentText = styled.div`
  font-size: 12px;
  color: #59b8eb;
  font-weight: bold;

  ${media.md`
    font-size: 14px;
  `}
`

const PriceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 3px;

  ${media.md`
    flex-direction: row;
  `}
`

const MonthlyPriceText = styled.div`
  color: ${palette('blue')};
  font-size: 19px;
  font-weight: bold;
  margin-top: 5px;

  ${media.md`
    font-size: 27px;
  `}
`

const MonthText = styled.div`
  color: ${palette('ui40')};
  padding-top: 5px;
  font-size: 13px;

  ${media.md`
    padding-top: 12px;
    margin-left: 10px;
    font-size: 16px;
  `}
`

const BlurredText = styled.div`
  color: transparent;
  text-shadow: 0 0 6px ${palette('blue')};
`

const RightSideWrapper = styled.div`
  display: block;
  margin-left: auto;
  margin-right: 20px;
  width: 40%;
  margin-top: 10px;
`

const LettkaupImg = styled.div`
  content: url(/images/lettkaup.png);
  width: 80%;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  color: ${palette('ui60Solid')};
`

const DetailsLine = styled.div`
  margin-top: 8px;
  line-height: 140%;
`

// FIXME: There is no loading indicator for the user

const loanDataFetcher = async (
  price: number,
  count: number,
  interestFree: boolean,
): Promise<LettkaupPayLoan> => {
  return fetch(
    [
      'https://api.siminnpay.is/loan/v1/calculate',
      // The loan is be interest-free if the number of payments is 12 or less:
      interestFree ? 'interestfree' : null,
      'simple',
      price,
      count,
    ]
      .filter((x) => x)
      .join('/'),
  ).then((res) => res.json())
}

const availableMonthCounts = [3, 6, 12, 18, 24, 36]

interface Props {
  sku: string
  price: number
}

export default function PayLettkaupCalculatorWrapper({
  sku,
  price: originalPrice,
}: Props): JSX.Element {
  const {data: userPrice} = useSingleUserPrice(sku)

  const price = userPrice?.lowestPrice || originalPrice

  return <PayLettkaupCalculator price={price} />
}

interface LettkaupProps {
  price: number
}

function PayLettkaupCalculator({price}: LettkaupProps): JSX.Element {
  const [monthCountIndexState, setMonthCountIndex] = useState(2)
  const [previousLoan, setPreviousLoan] = useState<LettkaupPayLoan>(null)

  const minMonthCountIndex = 0
  const maxMonthCountIndex = price < 50000 ? 1 : availableMonthCounts.length - 1
  const monthCountIndex =
    monthCountIndexState > maxMonthCountIndex
      ? maxMonthCountIndex
      : Math.max(minMonthCountIndex, monthCountIndexState)

  const currentMonthCount = availableMonthCounts[monthCountIndex]

  const {data: loanData} = useSWR(
    price
      ? [
          'siminn-pay-load-calculator',
          price,
          currentMonthCount,
          monthCountIndex < 3,
        ]
      : null,
    ([_, price, currentMonthCount, interestFree]) =>
      loanDataFetcher(price, currentMonthCount, interestFree),
    {
      onSuccess: (res) => {
        setPreviousLoan(res)
      },
    },
  )

  const canIncrease = monthCountIndex < maxMonthCountIndex
  const canDecrease = monthCountIndex > minMonthCountIndex

  const increasePaymentCount = () => {
    setMonthCountIndex(Math.min(monthCountIndex + 1, maxMonthCountIndex))
  }

  const decreasePaymentCount = () => {
    setMonthCountIndex(Math.max(monthCountIndex - 1, minMonthCountIndex))
  }

  const getFormattedPercentage = (percentage) => {
    return (percentage * 100).toFixed(1).toString().replace('.', ',')
  }

  if (price < 20000) {
    return <></>
  }

  return (
    <ProductAttributeWrapper label="Greiðsludreifing">
      <LettKaupWrapper>
        <CalculatorWrapper>
          <ButtonWrapper>
            <Btn
              onClick={increasePaymentCount}
              disabled={!canIncrease}
              aria-label="Plus"
            >
              <AddLineIcon size={15} />
            </Btn>

            <Btn
              onClick={decreasePaymentCount}
              disabled={!canDecrease}
              aria-label="Minus"
            >
              <SubtractLineIcon size={15} />
            </Btn>
          </ButtonWrapper>

          <PaymentWrapper>
            <PaymentText> {currentMonthCount} GREIÐSLUR </PaymentText>
            <PriceWrapper>
              <MonthlyPriceText>
                {loanData ? (
                  formatPrice(loanData.AveragePaymentAmount)
                ) : (
                  <BlurredText>
                    {formatPrice(previousLoan?.AveragePaymentAmount || 0)}
                  </BlurredText>
                )}
              </MonthlyPriceText>
              <MonthText>/ mán </MonthText>
            </PriceWrapper>
          </PaymentWrapper>

          <RightSideWrapper>
            <LettkaupImg />
          </RightSideWrapper>
        </CalculatorWrapper>

        <Details>
          {loanData ? (
            <DetailsLine>
              Í {currentMonthCount} mánuði með Pay Léttkaup.{' '}
              {getFormattedPercentage(loanData.InterestRate)}% vextir,{' '}
              {getFormattedPercentage(loanData.DisbursementFeePercentage)}%
              lántökugjald og {loanData.PaymentFeePerPayment} kr./greiðslu. Alls{' '}
              {formatPrice(loanData.TotalRepayment)} ÁHK:{' '}
              {getFormattedPercentage(loanData.YearlyCostRatio)}%
            </DetailsLine>
          ) : (
            <DetailsLine>
              <BlurredText>
                Í {currentMonthCount} mánuði með Pay Léttkaup.{' '}
                {getFormattedPercentage(previousLoan?.InterestRate || 0)}%
                vextir,{' '}
                {getFormattedPercentage(
                  previousLoan?.DisbursementFeePercentage || 0,
                )}
                % lántökugjald og {previousLoan?.PaymentFeePerPayment || 0}{' '}
                kr./greiðslu. Alls{' '}
                {formatPrice(previousLoan?.TotalRepayment || 0)} ÁHK:{' '}
                {getFormattedPercentage(previousLoan?.YearlyCostRatio || 0)}%
              </BlurredText>
            </DetailsLine>
          )}
        </Details>
      </LettKaupWrapper>
    </ProductAttributeWrapper>
  )
}
