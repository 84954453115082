import {createContext, useContext} from 'react'

import cn from 'classnames'
import {media} from 'styled-bootstrap-grid'
import styled, {css} from 'styled-components'
import {palette} from 'styled-tools'
import {theme} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'

export interface Props {
  imgUrl?: string
  compact?: boolean
  greyBackground?: boolean
  darkMode?: boolean
}

export function spacing(
  from: number,
  to: number,
) {
  return css`
    padding: ${from}px 0;

    ${fluidRange('padding-top', `${from}px`, `${to}px`)}
    ${fluidRange('padding-bottom', `${from}px`, `${to}px`)}

    ${media.lg`
      padding: ${to}px 0;
    `}
  `
}

export interface BlockWrapperContextProps {
  slug?: string
}

export const BlockWrapperContext = createContext<BlockWrapperContextProps>({})

export const darkModeCSS = css`
  background-color: ${palette('blue')};
  border-top: 14px solid ${palette('green')};
`

const Anchor = styled.div`
  position: absolute;
  width: 100%;
  top: calc((${theme('navBarHeight.small')} * -1) - 56px);
  height: 0;
  @media (min-width: ${styledTheme.screenSize.sizeMD}) {
    top: calc(${theme('navBarHeight.large')} * -1);
  }
`

const BlockWrapperInner = styled.div<Props>`
  position: relative;
  ${spacing(20, 40)}

  .compact {
    ${spacing(10, 30)}
  }
  ${(props) => props.darkMode && darkModeCSS}
`

export interface BlockWrapperProps {
  darkMode?: boolean
  compact?: boolean
  children: React.ReactNode
}

export default function BlockWrapper({
  darkMode,
  compact,
  children,
}: BlockWrapperProps) {
  const {slug} = useContext(BlockWrapperContext)

  return (
    <BlockWrapperInner className={cn({darkMode, compact})}>
      {!!slug && <Anchor id={slug} />}
      {children}
    </BlockWrapperInner>
  )
}
