/* eslint no-param-reassign: off */
import {Category, ProductDetail, Variant, VariantDetail} from '../api/rest'

export interface CategoryTreeItem extends Category {
  parentNode?: CategoryTreeItem
  children?: CategoryTreeItem[]
}

export function buildCategoryTree(categories: Category[]): CategoryTreeItem[] {
  const categoryPairs = categories.map<[number, CategoryTreeItem]>((c) => [
    c.id,
    {...c, children: []},
  ])

  const tree = new Map(categoryPairs)

  tree.forEach((item: CategoryTreeItem) => {
    if (tree.has(item?.parent || 0)) {
      item.parentNode = tree.get(item?.parent || 0)
    }
  })

  tree.forEach((ref: CategoryTreeItem) => {
    ref?.parentNode?.children?.push(ref)
  })

  tree.forEach((item: CategoryTreeItem) => {
    delete item.parentNode
  })

  return Array.from(tree.values()).filter((p) => !p.parent)
}

// TODO: Test
export function getAncestry(
  categories: Category[],
  currCategory?: Category,
): Category[] {
  if (!currCategory) {
    return []
  }
  return [
    ...getAncestry(
      categories,
      categories.find((c) => c.id === currCategory.parent),
    ),
    currCategory,
  ]
}

export interface BreadCrumb {
  path: string
  label: string
}

// TODO: Test
export function getProductBreadCrumbsNEW(
  allCategories: Category[] = [],
  product: ProductDetail,
  variant: Variant,
): BreadCrumb[] {
  if (!variant) {
    return []
  }

  const variantCategoryIds = product.categories
  const variantCategories = allCategories.filter((cat) =>
    variantCategoryIds.find((vc) => vc === cat.id),
  )

  const breadCrumbs = []
  let parentId: number | null = null

  // TODO: change breadcrumbs to show the categories
  // the user selected to get there
  variantCategoryIds.forEach(() => {
    const currentLevelCategories = variantCategories.filter(
      (c) => c.parent === parentId,
    )

    const currCat = currentLevelCategories?.reduce(function (prev, current) {
      return prev.index < current.index ? prev : current
    }, currentLevelCategories[0])

    if (currCat) {
      breadCrumbs.push({
        label: currCat?.name,
        path: `/voruflokkar/${currCat?.slug}/`,
      })
      parentId = currCat?.id
    }
  })

  breadCrumbs.push({
    label: variant.name || '',
    path: `/vorur/${product.slug}/${variant.sku}/`,
  })

  return breadCrumbs
}

// TODO: Test
export function getProductBreadCrumbs(
  allCategories: Category[] = [],
  variant: VariantDetail,
): BreadCrumb[] {
  if (!variant) {
    return []
  }

  const variantCategoryIds = variant.product.categories
  const variantCategories = allCategories.filter((cat) =>
    variantCategoryIds.find((vc) => vc === cat.id),
  )

  const breadCrumbs = []
  let parentId: number | null = null

  // TODO: change breadcrumbs to show the categories
  // the user selected to get there
  variantCategoryIds.forEach(() => {
    const currentLevelCategories = variantCategories.filter(
      (c) => c.parent === parentId,
    )

    const currCat = currentLevelCategories?.reduce(function (prev, current) {
      return prev.index < current.index ? prev : current
    }, currentLevelCategories[0])

    if (currCat) {
      breadCrumbs.push({
        label: currCat?.name,
        path: `/voruflokkar/${currCat?.slug}/`,
      })
      parentId = currCat?.id
    }
  })

  breadCrumbs.push({
    label: variant.name || '',
    path: `/vorur/${variant.product.slug}/${variant.sku}/`,
  })

  return breadCrumbs
}
