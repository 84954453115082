import {FormEvent, useCallback, useState} from 'react'

import TagManager from 'react-gtm-module'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

import {restApi} from '@festi/common/api/rest'
import {AuthFormProps} from '@festi/common/components/account/RegisterForms'

import {ActionButton, Button} from '../buttons'
import {H4, P} from '../typography'

export default function MarketingForm({
  onComplete,
}: AuthFormProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSubmit = useCallback(
    async (accept: boolean, e?: FormEvent) => {
      e?.preventDefault()

      setIsLoading(true)

      TagManager.dataLayer({
        dataLayer: {
          category: 'login',
          event: 'account post list',
          event_label: accept ? 'opt in' : 'opt out',
        },
        dataLayerName: 'dataLayer',
      })

      TagManager.dataLayer({
        dataLayer: {
          category: 'login',
          event: 'account signup finished',
        },
        dataLayerName: 'dataLayer',
      })

      restApi.usersMeTermsCreate({postlist: accept})

      accept && restApi.usersMeMailingListSignupCreate({allowMarketing: accept})

      !!onComplete && onComplete()
    },
    [setIsLoading, onComplete],
  )

  return (
    <form onSubmit={(e) => onSubmit(true, e)}>
      <H4 withGutter>Viltu skrá þig á póstlista?</H4>

      <P withGutter>
        Vertu með þeim fyrstu til að fá fréttir af útsölum, tilboðsdögum,
        forsölum og spennandi nýjum vörum. Þú getur afskráð þig hvenær sem er.
      </P>

      <Button
        type="submit"
        withBar
        icon={ArrowRightSLineIcon}
        accentColor="lightBlue"
        loading={isLoading}
      >
        Já takk
      </Button>

      <ActionButton
        type="button"
        onClick={() => onSubmit(false)}
        icon={ArrowRightSLineIcon}
        disabled={isLoading}
      >
        Nei takk
      </ActionButton>
    </form>
  )
}
