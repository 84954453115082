import Link from 'next/link'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 40px;
  border-top: 1px solid ${palette('border')};

  ${media.md`
    padding: 40px 150px;
  `}
`

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${palette('ui20Solid')};
`

export default function CheckoutFooter() {
  return (
    <Wrapper>
      <Link href="/skilmalar" target="_blank">
        Skilmálar
      </Link>
      <Dot />
      <Link href="/um_elko/personuvernd" target="_blank">
        Persónuvernd
      </Link>
    </Wrapper>
  )
}
