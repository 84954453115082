// TODO: Test
export function getScrollPosition(): number {
  if (typeof window === 'undefined') {
    return 0
  }

  return (
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0
  )
}
