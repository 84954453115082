import FocusTrap from 'focus-trap-react'

interface FocusTrapProps {
  children: React.ReactNode
  isOpen: boolean
}

export default function FocusTrapWrapper({children, isOpen}: FocusTrapProps) {
  if (isOpen) {
    return (
      <FocusTrap
        focusTrapOptions={{
          escapeDeactivates: true,
          clickOutsideDeactivates: true,
          allowOutsideClick: true,
          initialFocus: false,
        }}
      >
        {children ? children : <div tabIndex={1} />}
      </FocusTrap>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
