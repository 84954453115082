import {useCallback, useState} from 'react'

import cn from 'classnames'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'

import {onDutyFree} from '@festi/common/constants/channels'

interface ImageProps {
  src: string
  alt: string
  showInMobile?: boolean
  isFeatured?: boolean
  fewInStock?: boolean
  outOfStock?: boolean
  outOfStockWeb?: boolean
}

interface CardImgProps {
  outOfStock?: boolean
}

const CardImg = styled.img<CardImgProps>`
  object-fit: contain;
  width: 100%;
  max-height: 150px;
  opacity: ${(p) => (p.outOfStock ? 0.54 : 1)};

  &.isFeatured {
    max-height: 300px;
  }

  ${media.sm`
    height: 200px;
    max-height: none;
  `}
`

const ImageWrapper = styled.div`
  display: none;
  width: 40%;

  &.showInMobile {
    display: flex;
  }

  &.isFeatured {
    width: 80%;
    margin: 0 auto 16px auto;
  }

  &:before {
    content: '';
    display: block;
    padding-bottom: 80%;
  }
  ${media.sm`
    display: flex;
    width: 100%;
    margin: 0 auto 32px auto;

    &.showInMobile {
      display: none;
    }

    &.isFeatured {
      width: 50%;
      margin: 0 auto 0 auto;

      ${CardImg} {
        height: 100%;
        max-height: 400px;
      }
    }

    &:before {
      padding-bottom: 65%;
    }
  `}
`

const CardFlag = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  height: 33px;
  padding: 0 16px;
  color: ${palette('blue')};
  background-color: ${palette('ui10Solid')};
  box-shadow: ${theme('boxShadow.card')};

  &.pink {
    background-color: ${palette('pink')};
  }

  &.green {
    background-color: ${palette('green')};
  }

  ${media.sm`
    top: 40px;
    bottom: unset;
  `}
`

export default function ProductImage({
  src,
  alt,
  showInMobile,
  isFeatured,
  fewInStock,
  outOfStock,
  outOfStockWeb,
}: ImageProps): JSX.Element {
  const [img, setImg] = useState(src || '/images/placeholder.png')

  const onError = useCallback(() => {
    setImg('/images/placeholder.png')
  }, [])

  return (
    <ImageWrapper className={cn({isFeatured, showInMobile})}>
      <CardImg
        src={img}
        alt={alt}
        outOfStock={outOfStock}
        onError={onError}
        className={cn({isFeatured})}
      />

      {outOfStock && <CardFlag>Uppselt</CardFlag>}

      {!outOfStock && outOfStockWeb && (
        <CardFlag>Uppselt í {onDutyFree ? 'flugstöð' : 'vefverslun'}</CardFlag>
      )}

    </ImageWrapper>
  )
}
