import cn from 'classnames'
import Link from 'next/link'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import {ProductTags} from 'src/components/products'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'
import {AddToCartButton} from '@festi/common/components/cart'

import {SlideInnerProps} from '../common/CustomSlider'
import {VariantDetail} from '@festi/common/api/rest'

const VariantCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${palette('ui20Solid')};
  position: relative;
  display: flex;
  flex-direction: column;

  &.minified {
  }
`

const VariantCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;

  ${media.md`
    padding: 8px 16px;
  `}
`

const RemoveButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${palette('ui10Solid')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  cursor: pointer;
`

const VariantCardImg = styled.div`
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 64%;
  margin-top: 8px;
  margin-bottom: 12px;
  max-height: 350px;
  transition: max-height 0.15s;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &.hide {
    visibility: hidden;
    max-height: 8px;
  }
`

const VariantCardInfoAnchor = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  cursor: pointer;

  ${media.md`
    align-items: flex-start;
    padding-right: 32px;
    margin-bottom: 8px;
  `}
`

const VariantCardName = styled.div`
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 500;
  color: ${palette('blue')};
  margin-bottom: 5px;
  text-align: right;

  ${media.md`
    text-align: left;
  `}
`

const VariantCardSku = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${palette('ui50Solid')};
  text-align: right;

  ${media.md`
    text-align: left;
  `}
`

const HideOnScroll = styled.div`
  display: none;
  height: 56px;
  transition: height 0.15s, visibility 0.15s;

  &.hide {
    visibility: hidden;
    height: 0px;
  }

  ${media.md`
    display: block;
  `}
`

const VariantInfo = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${media.md`
    flex-direction: column;
  `}
`

const TagWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  display: none;

  ${media.md`
    display: flex;
    flex-direction: column;
  `}
`

interface CardProps {
  variant: VariantDetail
  minified: boolean
  onClickRemove: (sku: string) => void
}

export default function ComparisonProductCard({
  variant,
  minified,
  isHidden = false,
  onKeyDown,
  onClickRemove,
}: CardProps & SlideInnerProps) {
  const {sku, name, images, product} = variant
  const {slug} = product

  // TODO: Change to correct image
  const imgSrc = images?.[0]?.image?.productGallery

  return (
    <VariantCardWrapper className={cn({minified: minified})}>
      <VariantCardContent>
        <RemoveButton
          onClick={() => onClickRemove(sku)}
          aria-label="Remove"
          tabIndex={isHidden ? -1 : 0}
        >
          <CloseLineIcon size={18} color={styledTheme.palette.blue} />
        </RemoveButton>

        <TagWrapper>
          <ProductTags variant={variant} />
        </TagWrapper>

        <VariantInfo>
          <Link href={`/vorur/${slug}/${sku}/`} passHref legacyBehavior>
            <VariantCardInfoAnchor
              tabIndex={isHidden ? -1 : 0}
              onKeyDown={onKeyDown}
            >
              <VariantCardName>{name}</VariantCardName>
              <VariantCardSku>{sku}</VariantCardSku>
            </VariantCardInfoAnchor>
          </Link>
          <VariantCardImg
            style={{backgroundImage: `url('${imgSrc}')`}}
            className={cn({hide: minified})}
          />
        </VariantInfo>
      </VariantCardContent>

      <HideOnScroll className={cn({hide: minified})}>
        <AddToCartButton variant={variant} />
      </HideOnScroll>
    </VariantCardWrapper>
  )
}
