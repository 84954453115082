import {useState} from 'react'

import styled from 'styled-components'

import {useAuth} from '@festi/common/contexts'

import {ProgressSteps} from '../common'
import EmailForm from './EmailForm'
import MarketingForm from './MarketingForm'
import PersonalExpForm from './PersonalExpForm'
import TermsForm from './TermsForm'

export enum RegisterState {
  Email = 'email',
  Terms = 'terms',
  PersonalExp = 'personalExp',
  Marketing = 'marketing',
}

export interface AuthFormProps {
  onComplete?: () => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 16px;
  }
`

const ProgressWrapper = styled.div`
  transition: opacity 0.2s;
  margin-bottom: 40px;
`

const steps = [
  RegisterState.Email,
  RegisterState.Terms,
  RegisterState.PersonalExp,
  RegisterState.Marketing,
]

export default function RegisterForms(): JSX.Element {
  const {user, fetchUser} = useAuth()
  const [registerState, setRegisterState] = useState<RegisterState>(
    RegisterState.Email,
  )

  return (
    <Wrapper>
      <ProgressWrapper>
        <ProgressSteps
          steps={4}
          currentStep={steps.indexOf(registerState) + 1}
        />
      </ProgressWrapper>

      {registerState === RegisterState.Email && (
        <EmailForm
          initialEmail={user?.email}
          onComplete={() => setRegisterState(RegisterState.Terms)}
        />
      )}
      {registerState === RegisterState.Terms && (
        <TermsForm
          onComplete={() => setRegisterState(RegisterState.PersonalExp)}
        />
      )}
      {registerState === RegisterState.PersonalExp && (
        <PersonalExpForm
          onComplete={() => setRegisterState(RegisterState.Marketing)}
        />
      )}
      {registerState === RegisterState.Marketing && (
        <MarketingForm onComplete={() => fetchUser()} />
      )}
    </Wrapper>
  )
}
