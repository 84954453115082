import React, {useCallback, useEffect, useRef, useState} from 'react'

import styled from 'styled-components'

import {MoreButton} from '@festi/common/components/buttons'
import {Expandable} from '@festi/common/components/common'

interface Props {
  children: React.ReactNode
  fadeColor?: string
  scrollIntoView?: boolean
  setScrollIntoView?: (value: React.SetStateAction<boolean>) => void
}

const Wrapper = styled.div``

const Content = styled.div`
  position: relative;
`

export default function ProductDescription({
  children,
  fadeColor,
  scrollIntoView,
  setScrollIntoView,
}: Props): JSX.Element {
  const contentRef: React.RefObject<HTMLDivElement> = useRef(null)
  const wrapperRef: React.RefObject<HTMLDivElement> = useRef(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [displayMoreBtn, setDisplayMoreBtn] = useState<boolean>(false)

  function checkContentHeight(): void {
    if (contentRef?.current?.offsetHeight > 400) {
      setDisplayMoreBtn(true)
    }
  }

  const onToggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])

  useEffect(() => {
    checkContentHeight()
  }, [])

  useEffect(() => {
    if (scrollIntoView && !isOpen) {
      setIsOpen(true)
    } else if (scrollIntoView && isOpen) {
      // This is done to make sure the content has expanded before triggering scrolling
      const yPos =
        wrapperRef?.current?.getBoundingClientRect().top + window.scrollY - 70
      window.scrollTo({
        top: yPos,
        behavior: 'smooth',
      })
      setScrollIntoView(false)
    }
  }, [isOpen, scrollIntoView, setScrollIntoView])

  return (
    <Wrapper ref={wrapperRef}>
      <Expandable expanded={isOpen || !displayMoreBtn} minHeight={240}>
        <Content ref={contentRef}>{children}</Content>
      </Expandable>

      {displayMoreBtn && (
        <MoreButton onClick={onToggle} fadeColor={fadeColor} less={isOpen}>
          {`Sjá ${isOpen ? 'minna' : 'meira'}`}
        </MoreButton>
      )}
    </Wrapper>
  )
}
