import {palette} from 'styled-tools'
import styled from 'styled-components'
import {toInteger} from 'lodash'

import {styledTheme} from '@festi/common/themes'
import {
  VariantList,
  VariantDetail,
  Variant,
  ProductDetail,
} from '@festi/common/api/rest'
import {H6} from '@festi/common/components/typography'
import {getDiscount} from '@festi/common/utils/checkout'
import {onDutyFree} from '@festi/common/constants/channels'
import {isNewProduct} from '@festi/common/utils/products'
import classNames from 'classnames'
import {fluidRange} from '@festi/common/utils/styles'

/*
const testTags: Tag[] = [
  {title: 'Nýtt', color: 'lightBlue'},
  {title: `${toInteger(0.1 * 100)}%`, color: 'pink'},
  {title: 'Uppselt', color: 'ui10Solid'},
]
 */

const ProductTagsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  pointer-events: none;

  &.isProductPage {
    border-radius: 0;
    z-index: 2;
    padding-left: 6px;
  }
`

interface TagProps {
  backgroundColor: string
}

const Tags = styled.div<TagProps>`
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  text-align: center;
  background-color: ${(p) => p.backgroundColor || palette('ui10Solid')};
  border-bottom-right-radius: 10px;
  pointer-events: auto;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -6px;
    bottom: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 30px solid
      ${(p) => p.backgroundColor || palette('ui10Solid')};
    border-bottom-left-radius: 6px;
  }

  &::after {
    content: '';
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 30px solid ${(p) => p.backgroundColor || palette('ui10Solid')};
    border-bottom-right-radius: 6px;
  }

  &.isProductPage {
    ${fluidRange('height', '24px', '36px')}
  }
`

interface Tag {
  title: string
  color: string
}

interface ProductTagsProps {
  variant: VariantList | VariantDetail | Variant
  product?: ProductDetail
  isProductPage?: boolean
}

export default function ProductTags({
  variant,
  product,
  isProductPage = false,
}: ProductTagsProps): JSX.Element {
  const {outlet, recommended, isInStockWeb, isInStockDutyfree} = variant
  const {online} = 'product' in variant ? variant?.product : product

  const discount = getDiscount(variant)
  const isNew = isNewProduct(online)
  const outOfStock = !(onDutyFree ? isInStockDutyfree : isInStockWeb)

  const tags: Tag[] = [
    isNew && {title: 'Nýtt', color: 'lightBlue'},
    !!discount && {title: `-${toInteger(discount * 100)}%`, color: 'pink'},
    outOfStock && {title: 'Uppselt', color: 'ui10Solid'},
    recommended && {title: 'ELKO mælir með', color: 'green'},
    outlet && {title: 'Tækifæri', color: 'black'},
  ]

  return (
    <ProductTagsWrapper className={classNames({isProductPage})}>
      {tags?.map(
        (tag, i) =>
          !!tag && (
            <Tags
              key={i}
              backgroundColor={styledTheme.palette[tag.color]}
              className={classNames({isProductPage})}
            >
              <H6 darkMode={tag.color === 'black'}>{tag.title}</H6>
            </Tags>
          ),
      )}
    </ProductTagsWrapper>
  )
}
