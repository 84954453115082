import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import CheckLineIcon from 'remixicon-react/CheckLineIcon'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'

import {H6, P} from '@festi/common/components/typography'

interface CardProps {
  selected?: boolean
}

const Card = styled.div<CardProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 33px;
  border: 1px solid
    ${(p) => (p.selected ? palette('green') : palette('border'))};
  cursor: pointer;

  &:hover {
    border: 1px solid ${palette('lightBlue')};
    box-shadow: ${theme('boxShadow.card')};

    .remixicon-icon {
      fill: ${palette('lightBlue')};
    }
  }
`

const Text = styled.div`
  margin-right: auto;
`

interface Props {
  ssn: string
  name?: string
  selected: boolean
  onClick: () => void
}

export default function BusinessCard({ssn, name, selected, onClick}: Props) {
  return (
    <Card selected={selected} onClick={onClick}>
      <Text>
        <H6>{name}</H6>
        <P>{ssn}</P>
      </Text>

      {selected ? <CheckLineIcon color="green" /> : <ArrowRightSLineIcon />}
    </Card>
  )
}
