import React, {useEffect, useState} from 'react'

import Link from 'next/link'
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon'
import ShoppingBagLineIcon from 'remixicon-react/ShoppingBagLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {CheckoutLine} from '@festi/common/api/rest'
import {useAuth, useCheckout} from '@festi/common/contexts'
import {onDutyFree} from '@festi/common/constants/channels'
import {formatPrice} from '@festi/utils/numbers'

import {fluidRange} from '../../utils/styles'
import {AuthSection} from '../account/AuthDrawer'
import {AnchorButton, OutlinedAnchorButton} from '../buttons'
import {DotsLoader, EmptyResults} from '../common'
import {SideDrawer} from '../layout'
import MiniCartItem from './MiniCartItem'

interface Props {
  isOpen?: boolean
  onRequestClose?: () => void
  onDutyFreeFrontPage?: boolean
}

const Content = styled.div`
  padding: 24px 16px;
  box-sizing: border-box;
`

const ActionsWrapper = styled.div`
  margin-bottom: 24px;

  button {
    margin-bottom: 16px;
  }
`

const TotalBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${palette('ui10Solid')};
  transition: opacity 0.15s;
`

const TotalLabel = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: ${palette('blue')};
  padding-right: 16px;
`

const TotalPrice = styled.span`
  font-family: 'ElkjopHeadline', sans-serif;
  color: ${palette('blue')};
  ${fluidRange('font-size', '24px', '32px')}
`

const CartCount = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  color: ${palette('ui50Solid')};
  margin-bottom: 16px;
  text-transform: uppercase;
`

const Items = styled.div``

export default function MiniCartDrawer({
  isOpen,
  onRequestClose,
  onDutyFreeFrontPage,
}: Props): JSX.Element {
  const {user} = useAuth()
  const [promptAuth, setPromptAuth] = useState<boolean>(false)

  const {restCheckout, restCheckoutLoading, cartIsUpdating, hasInsurableLines} =
    useCheckout()

  const nextStep = hasInsurableLines ? '/karfa/trygging/' : '/kaup/'

  const handleClick = (e: React.MouseEvent) => {
    if (!user) {
      e.preventDefault()
      setPromptAuth(true)
    }
  }

  useEffect(() => {
    return () => setPromptAuth(false)
  }, [])

  useEffect(() => {
    if (user && promptAuth) {
      setPromptAuth(false)
    }
  }, [promptAuth, user])

  return (
    <SideDrawer
      isOpen={isOpen}
      noFocus={restCheckoutLoading || !restCheckout?.quantity}
      onRequestClose={onRequestClose}
      onDutyFreeFrontPage={onDutyFreeFrontPage}
    >
      {promptAuth ? (
        <AuthSection inCheckout />
      ) : (
        <Content>
          {restCheckoutLoading ? (
            <DotsLoader />
          ) : !restCheckout?.quantity ? (
            <EmptyResults icon={ShoppingBagLineIcon}>
              <span data-cy="minicart-empty">Karfan þín er tóm</span>
            </EmptyResults>
          ) : (
            <div>
              <ActionsWrapper>
                <Link href="/karfa" passHref legacyBehavior>
                  <OutlinedAnchorButton
                    icon={ShoppingBagLineIcon}
                    onClick={onRequestClose}
                    style={{marginBottom: '14px'}}
                  >
                    Skoða körfu
                  </OutlinedAnchorButton>
                </Link>
                <Link href={nextStep} passHref legacyBehavior>
                  <AnchorButton
                    onClick={handleClick}
                    icon={ArrowRightLineIcon}
                    withBar
                    accentColor="green"
                  >
                    {onDutyFree ? 'Sækja við brottför' : 'Klára kaup'}
                  </AnchorButton>
                </Link>
              </ActionsWrapper>
              <TotalBox style={{opacity: cartIsUpdating ? 0.4 : 1}}>
                <TotalLabel>Verð samtals</TotalLabel>
                <TotalPrice>{formatPrice(restCheckout?.totalPrice)}</TotalPrice>
              </TotalBox>
              <CartCount>
                {restCheckout.quantity}{' '}
                {restCheckout.quantity % 100 === 11 ||
                restCheckout.quantity % 10 !== 1
                  ? 'vörur'
                  : 'vara'}{' '}
                í körfu
              </CartCount>
              <Items>
                {restCheckout?.lines?.map((line: CheckoutLine) => (
                  <MiniCartItem key={line.id} line={line} />
                ))}
              </Items>
            </div>
          )}
        </Content>
      )}
    </SideDrawer>
  )
}
