import React, {useCallback, useState} from 'react'

import dynamic from 'next/dynamic'
import classNames from 'classnames'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import SearchLineIcon from 'remixicon-react/SearchLineIcon'

import {styledTheme} from '@festi/common/themes'
import {Expandable} from '@festi/common/components/common'

import useAlgoliaSearch from 'src/components/search/redesign/utilites/useAlgoliaSearch'

const SearchHistory = dynamic(() => import('./SearchHistory'), {ssr: false})
const QuickSearchHits = dynamic(() => import('./QuickSearchHits'), {ssr: false})

const Wrapper = styled.div`
  position: relative;
  max-width: 1072px;
  margin: 0 auto;
`

const SearchForm = styled.form`
  position: relative;

  .remixicon-icon {
    position: absolute;
    top: 14px;
    left: 16px;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }
`

const SearchBox = styled.input`
  width: 100%;
  padding: 13px 16px 13px 56px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: ${palette('blue')};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid ${palette('grey')};
  background-color: ${palette('white')};

  &::placeholder {
    color: ${palette('ui40Solid')};
  }

  &:focus-visible {
    outline: 0;
  }

  &.openDropdown {
    border: 1px solid ${palette('ui30Solid')};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const Hover = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1001;
`

export default function Searchbar() {
  const ref = React.useRef<HTMLInputElement>(null)

  const [hover, setHover] = useState(false)
  const [targeted, setTargeted] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const openDropdown = hover || targeted

  const {handleSearchSubmit} = useAlgoliaSearch()

  const handleSubmitQuery = useCallback(
    (query: string) => {
      ref.current?.blur()
      setSearchQuery('')
      handleSearchSubmit(query)
    },
    [handleSearchSubmit],
  )

  return (
    <Wrapper>
      <SearchForm
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmitQuery(searchQuery || '')
        }}
      >
        <SearchLineIcon
          size={22}
          color={styledTheme.palette.blue}
          onClick={() => handleSearchSubmit(searchQuery || '')}
        />

        <SearchBox
          ref={ref}
          value={searchQuery}
          placeholder="Ég er að leita að..."
          className={classNames({openDropdown})}
          onBlur={() => setTargeted(false)}
          onFocus={() => setTargeted(true)}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </SearchForm>

      <Hover
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {!searchQuery ? (
          <Expandable expanded={openDropdown} key="quicksearch">
            <SearchHistory handleSubmitQuery={handleSubmitQuery} />
          </Expandable>
        ) : (
          <Expandable expanded={openDropdown} key="quicksearch-hits">
            <QuickSearchHits
              query={searchQuery}
              handleSubmitQuery={handleSubmitQuery}
            />
          </Expandable>
        )}
      </Hover>
    </Wrapper>
  )
}
