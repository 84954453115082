import styled from 'styled-components'
import {Container} from 'styled-bootstrap-grid'

import {
  ErrorCard,
  ErrorCardBody,
  ErrorCardHead,
  ErrorCardImg,
  ErrorPageWrapper,
  ErrorSubtitle,
} from './NotFound'

const Wrapper = styled.div`
  padding: 32px 0;
`

interface Props {
  children: React.ReactNode
}

export default function BackdoorLayout({children}: Props): JSX.Element {
  return (
    <ErrorPageWrapper>
      <Container>
        <ErrorCard>
          <ErrorCardHead color="lightBlue">
            <ErrorCardImg
              style={{backgroundImage: 'url(/images/lock-password-line.svg)'}}
            />
          </ErrorCardHead>
          <ErrorCardBody>
            <ErrorSubtitle>External Login</ErrorSubtitle>

            <Wrapper>{children}</Wrapper>
          </ErrorCardBody>
        </ErrorCard>
      </Container>
    </ErrorPageWrapper>
  )
}
