import {FormEvent, useCallback, useState} from 'react'

import TagManager from 'react-gtm-module'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

import {restApi} from '@festi/common/api/rest'
import {AuthFormProps} from '@festi/common/components/account/RegisterForms'

import {ActionButton, Button} from '../buttons'
import {H4, P} from '../typography'

export default function PersonalExpForm({
  onComplete,
}: AuthFormProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSubmit = useCallback(
    async (accept: boolean, e?: FormEvent) => {
      e?.preventDefault()

      setIsLoading(true)

      TagManager.dataLayer({
        dataLayer: {
          category: 'login',
          event: 'account marketing terms',
          event_label: accept ? 'opt in' : 'opt out',
        },
        dataLayerName: 'dataLayer',
      })

      restApi
        .usersMeTermsCreate({
          personalization: accept,
        })
        .then(() => !!onComplete && onComplete())
    },
    [setIsLoading, onComplete],
  )

  return (
    <form onSubmit={(e) => onSubmit(true, e)}>
      <H4 withGutter>Viltu persónulega upplifun?</H4>
      <P withGutter>
        Við viljum gjarnan fá að nýta kaupsögu þína og önnur gögn um þig til að
        mæla með réttu vörunum og birta þér persónulegri skilaboð. Sjá nánar í
        persónuverndarstefnu.
      </P>
      <Button
        type="submit"
        withBar
        icon={ArrowRightSLineIcon}
        accentColor="lightBlue"
        loading={isLoading}
      >
        Já takk
      </Button>
      <ActionButton
        type="button"
        onClick={() => onSubmit(false)}
        icon={ArrowRightSLineIcon}
        disabled={isLoading}
      >
        Nei takk
      </ActionButton>
    </form>
  )
}
