import React, {useCallback, useEffect, useRef, useState} from 'react'

import cn from 'classnames'
import times from 'lodash/times'
import {useRouter} from 'next/router'
import Slider, {Settings} from 'react-slick'
import {toast} from 'react-toastify'
import {useWindowSize} from 'react-use'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import ShareLineIcon from 'remixicon-react/ShareLineIcon'
import {ComparisonTable} from 'src/components/comparison'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {useThrottledCallback} from 'use-debounce'

import {FieldType} from '@festi/common/api/rest'
import {
  BackButton,
  TextButton,
  OutlinedButton,
} from '@festi/common/components/buttons'
import {AddToCartButton} from '@festi/common/components/cart'
import {
  DotsLoader,
  ShowDesktop,
  ShowMobile,
} from '@festi/common/components/common'
import {H2} from '@festi/common/components/typography'
import {useComparison} from '@festi/common/contexts'
import {gridTheme, styledTheme} from '@festi/common/themes'
import {getScrollPosition} from '@festi/common/utils/dom'
import {fluidRange} from '@festi/common/utils/styles'
import {Dictionary} from '@festi/utils/types'

import {ComparisonProductCard} from '../../components/cards'

const PageContentInner = styled.div`
  ${fluidRange('padding-top', '16px', '56px')}
  ${fluidRange('padding-bottom', '16px', '56px')}
`

const BackWrapper = styled.div`
  margin-bottom: 8px;
`

const HeadWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 24px;

  button {
    white-space: nowrap;
  }

  ${media.md`
    margin-bottom: 32px;
  `}
`

const ContentHeightFixing = styled.div`
  &.showBanner {
    overflow-anchor: none;
    position: sticky;
  }
`

const SliderContentWrapper = styled.div`
  display: flex;
  z-index: 1;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 24px;
  background-color: ${palette('white')};

  ${media.md`
    margin-bottom: 32px;
  `}

  &.showBanner {
    position: sticky;
    top: 56px;
    :host {
      display: block;
    }

    ${media.desktop`
      top: 64px;
    `}
  }
`

const SliderSideContent = styled.div`
  width: calc(20% + 8px);
`

const SliderContainer = styled.div`
  width: calc(80% - 16px);

  .slick-list {
    overflow: visible !important;
  }

  /* Fix for adaptive height in react-slick */
  .slick-slide {
    height: auto;

    & > div {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    background-color: ${palette('white')};
  }
`

const SliderOverflowWrapper = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
`

const SlideWrapper = styled.div`
  width: 100%;
  padding-right: 16px;

  &:focus {
    outline: none !important;
  }
`

const EmptyCard = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 16px;
  border: 1px solid ${palette('ui20Solid')};
  border-radius: 0;
  background-color: ${palette('ui5Solid')};
  cursor: pointer;
`

const EmptyText = styled.div`
  width: 80%;
  margin-bottom: 16px;
  color: ${palette('ui40Solid')};
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  line-height: 1.3;
`

const EmptyAdd = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('blue')};

  .remixicon-icon {
    margin-right: 5px;
  }
`

const ShareButtonContainer = styled.div`
  ${media.md`
    padding-right: 16px;
  `}
`

interface ShareProps {
  path: string
}

const ShareButton = ({path}: ShareProps) => {
  const writeToClipboard = useCallback(() => {
    if (navigator.canShare) {
      navigator
        .share({
          title: 'Samanburður - ELKO',
          text: '',
          url: `${window.location.protocol}//${window.location.host}${path}`,
        })
        .then(() => console.log('Share was successful.'))
        .catch((error) => {
          if (
            error.name === 'AbortError' ||
            error.message === 'Share cancelled' ||
            error.message === 'The user aborted a request.'
          ) {
            console.log('Share was cancelled by the user.')
          } else {
            toast.error(
              'Ekki tókst að deila samanburð, hægt er að deila vefslóðinni',
            )
          }
        })
    } else {
      navigator.clipboard
        .writeText(
          `${window.location.protocol}//${window.location.host}${path}`,
        )
        .then(() => {
          toast.success('Samanburður hefur verið afritaður í klippiborðið')
        })
        .catch((_e) => {
          toast.error(
            'Ekki tókst að afrita samanburð, hægt er að deila vefslóðinni',
          )
        })
    }
  }, [path])

  return (
    <ShareButtonContainer>
      <OutlinedButton icon={ShareLineIcon} onClick={writeToClipboard}>
        Deila samanburði
      </OutlinedButton>
    </ShareButtonContainer>
  )
}

interface Props {
  queryProducts: string[]
  fieldTypeMap: Dictionary<FieldType>
}

export default function ComparisonPage({
  queryProducts,
  fieldTypeMap,
}: Props): JSX.Element {
  const router = useRouter()
  const routerRef = useRef(router)

  const {
    comparedSkus,
    comparisonCap,
    comparedProducts,
    comparisonLoading,
    clearComparison,
    replaceComparison,
    removeFromComparison,
    toggleComparisonSearch,
  } = useComparison()

  const sliderRef = useRef<Slider>(null)
  const bottomSliderRef = useRef<Slider>(null)

  const {width} = useWindowSize()
  const [currSlide, setCurrSlide] = useState<number>(0)
  const [showBanner, setShowBanner] = useState(false)
  const [slidesToShow, setSlidesToShow] = useState<number>()

  const emptyCount = comparisonCap - comparedProducts?.length

  const settings = <div />

  const sm = 2
  const md = 3
  const lg = 4

  const sliderSettings: Settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 400,
    swipeToSlide: true,
    slidesToShow: lg,
    slidesToScroll: 1,
    waitForAnimate: false,
    initialSlide: 0,
    beforeChange: (_, next) => {
      setCurrSlide(next)
    },
    responsive: [
      {
        breakpoint: gridTheme?.breakpoints?.md,
        settings: {
          slidesToShow: md,
        },
      },
      {
        breakpoint: gridTheme?.breakpoints?.sm,
        settings: {
          slidesToShow: sm,
        },
      },
    ],
  }

  const setSkusToQueryParameter = (skus: string[]) => {
    routerRef.current.replace(
      {
        pathname: routerRef.current.pathname,
        query: {product: skus},
      },
      undefined,
      {shallow: true},
    )
  }

  const handleRemoveFromComparison = (sku: string) => {
    if (comparedSkus.length === 1) {
      setSkusToQueryParameter([])
    }
    removeFromComparison(sku)
  }

  const handleScroll = () => {
    const scrollThreshold = 150

    const currentScrollPosition = getScrollPosition()
    const shouldShow = currentScrollPosition > scrollThreshold

    setShowBanner((showBanner) => {
      if (!showBanner && shouldShow) return true
      if (showBanner && !shouldShow) return false
      return showBanner
    })
  }
  const throttledHandleScroll = useThrottledCallback(handleScroll, 150)

  const focusNextSlide = useCallback(() => {
    if (currSlide < comparisonCap) {
      if (currSlide <= comparisonCap - slidesToShow) {
        sliderRef?.current?.slickNext()
        setCurrSlide(currSlide + 1)
      } else {
        setCurrSlide(currSlide + 1)
      }
    }
  }, [currSlide, comparisonCap, slidesToShow, setCurrSlide])

  const focusPrevSlide = useCallback(() => {
    if (currSlide > 0) {
      if (currSlide <= comparisonCap - slidesToShow + 1) {
        sliderRef?.current?.slickPrev()
        setCurrSlide(currSlide - 1)
      } else {
        setCurrSlide(currSlide - 1)
      }
    }
  }, [currSlide, comparisonCap, slidesToShow, setCurrSlide])

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Tab' && e.shiftKey) {
        focusPrevSlide()
      } else if (e.key === 'Tab') {
        focusNextSlide()
      }
    },
    [focusPrevSlide, focusNextSlide],
  )

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll)

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [throttledHandleScroll])

  useEffect(() => {
    if (queryProducts.length > 0) {
      replaceComparison(queryProducts)
    }
  }, [queryProducts, replaceComparison])

  useEffect(() => {
    if (width < gridTheme?.breakpoints?.sm) {
      setSlidesToShow(sm)
    } else if (width < gridTheme?.breakpoints?.md) {
      setSlidesToShow(md)
    } else {
      setSlidesToShow(lg)
    }
  }, [width, sm, md, lg])

  useEffect(() => {
    if (comparedSkus.length !== 0) {
      setSkusToQueryParameter(comparedSkus)
    }
  }, [comparedSkus, routerRef])

  return (
    <PageContentInner>
      <Container>
        <BackWrapper>
          <BackButton onClick={() => router.back()}>Til baka</BackButton>
        </BackWrapper>

        <HeadWrapper>
          <H2>Samanburður</H2>
          <TextButton onClick={clearComparison}>Hreinsa samanburð</TextButton>
        </HeadWrapper>

        {comparisonLoading ? (
          <DotsLoader />
        ) : (
          <ContentHeightFixing className={cn({showBanner: showBanner})}>
            <SliderContentWrapper className={cn({showBanner: showBanner})}>
              <SliderOverflowWrapper>
                <SliderSideContent>
                  <ShowDesktop>
                    <ShareButton path={router.asPath} />
                  </ShowDesktop>
                  <ShowDesktop>{settings}</ShowDesktop>
                </SliderSideContent>

                <SliderContainer>
                  <Slider
                    ref={sliderRef}
                    asNavFor={bottomSliderRef?.current}
                    {...sliderSettings}
                  >
                    {comparedProducts.map((product, index) => (
                      <SlideWrapper key={product.id}>
                        <ComparisonProductCard
                          variant={product}
                          minified={showBanner}
                          isHidden={currSlide !== index}
                          onKeyDown={onKeyDown}
                          onClickRemove={handleRemoveFromComparison}
                        />
                      </SlideWrapper>
                    ))}
                    {times(emptyCount, (index: number) => (
                      <SlideWrapper key={index}>
                        <EmptyCard
                          onClick={toggleComparisonSearch}
                          onKeyDown={onKeyDown}
                          tabIndex={
                            currSlide !== index + comparedProducts.length
                              ? -1
                              : 0
                          }
                        >
                          {!showBanner && (
                            <EmptyText>
                              Þú getur borið saman allt að {comparisonCap} vörur
                            </EmptyText>
                          )}
                          <EmptyAdd>
                            <AddLineIcon
                              size={24}
                              color={styledTheme.palette.lightBlue}
                            />
                            Bæta við
                          </EmptyAdd>
                        </EmptyCard>
                      </SlideWrapper>
                    ))}
                  </Slider>
                </SliderContainer>
              </SliderOverflowWrapper>
            </SliderContentWrapper>

            <ShowMobile>
              <ShareButton path={router.asPath} />
            </ShowMobile>
            <ShowMobile>{settings}</ShowMobile>

            {!!comparedProducts?.length && (
              <ComparisonTable
                fieldTypeMap={fieldTypeMap}
                currSlide={currSlide}
                sliderSettings={sliderSettings}
              />
            )}

            <SliderContentWrapper>
              <SliderOverflowWrapper>
                <SliderSideContent />

                <SliderContainer>
                  <Slider
                    ref={bottomSliderRef}
                    swipe={false}
                    {...sliderSettings}
                  >
                    {comparedProducts.map((product) => (
                      <SlideWrapper key={product.id}>
                        <AddToCartButton variant={product} />
                      </SlideWrapper>
                    ))}
                    {times(emptyCount, (index: number) => (
                      <SlideWrapper key={index}>
                        {/* This is important so that this slider maches the other one */}
                        <div />
                      </SlideWrapper>
                    ))}
                  </Slider>
                </SliderContainer>
              </SliderOverflowWrapper>
            </SliderContentWrapper>
          </ContentHeightFixing>
        )}
      </Container>
    </PageContentInner>
  )
}
