import React, {useEffect, useState} from 'react'

import Link from 'next/link'
import ShoppingCartLineIcon from 'remixicon-react/ShoppingCartLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {CheckoutLine} from '@festi/common/api/rest'
import {useAuth, useCheckout} from '@festi/common/contexts'
import {onDutyFree} from '@festi/common/constants/channels'
import {formatPrice} from '@festi/utils/numbers'
import {fluidRange} from '@festi/common/utils/styles'
import {AuthSection} from '@festi/common/components/account/AuthDrawer'
import {DotsLoader, EmptyResults} from '@festi/common/components/common'
import {SideDrawer} from '@festi/common/components/layout'
import {styledTheme} from '@festi/common/themes'

import NewMiniCartItem from './NewMiniCartItem'

interface Props {
  isOpen?: boolean
  onRequestClose?: () => void
  onDutyFreeFrontPage?: boolean
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const CartWrapper = styled.div`
  width: 100%;
`

const CartHeadWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 24px;
  ${fluidRange('padding-top', '20px', '24px')};
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-bottom', '20px', '24px')};
  ${fluidRange('padding-left', '16px', '40px')};
  border-bottom: 1px solid ${palette('ui10Solid')};
`

const CartHead = styled.div`
  display: flex;
  font-weight: 500;
  color: ${palette('blue')};
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-left', '16px', '40px')};
  gap: 8px;
`

const ViewCartButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 100%;
  padding: 7px 16px;
  border: 1px solid #d8dde6;
  border-radius: 8px;
  background-color: transparent;
  color: ${palette('blue')};
  font-size: 16px;
  font-weight: 500;
  z-index: 1;
  cursor: ${styledTheme.cursor.pointer};

  &:hover {
    background-color: ${styledTheme.palette.ui5Solid};
    border-color: ${styledTheme.palette.ui20Solid};
  }

  svg {
    margin-right: 8px;
  }
`

const CheckoutButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 100%;
  padding: 7px 16px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: ${palette('blue')};
  color: ${palette('white')};
  font-size: 16px;
  font-weight: 500;
  z-index: 1;
  cursor: ${styledTheme.cursor.pointer};

  &:hover {
    background-color: #1a2663;
  }

  svg {
    margin-left: 8px;
  }
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
  ${fluidRange('gap', '8px', '12px')};
  ${fluidRange('padding-top', '24px', '40px')};
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-left', '16px', '40px')};
  ${fluidRange('padding-bottom', '24px', '40px')};
`

const TotalBox = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  ${fluidRange('padding-top', '20px', '24px')};
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-bottom', '20px', '24px')};
  ${fluidRange('padding-left', '16px', '40px')};
  border-top: 1px solid ${palette('ui10Solid')};
  background-color: ${palette('backgroundGrey')};
  transition: opacity 0.15s;
  z-index: 1;
`

const TotalLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${palette('ui60Solid')};
  padding-right: 16px;
`

const TotalPrice = styled.span`
  font-family: 'ElkjopHeadline', sans-serif;
  color: ${palette('blue')};
  font-size: 32px;
`

export default function NewMiniCartDrawer({
  isOpen,
  onRequestClose,
  onDutyFreeFrontPage,
}: Props): JSX.Element {
  const {user} = useAuth()
  const [promptAuth, setPromptAuth] = useState<boolean>(false)

  const {restCheckout, restCheckoutLoading, cartIsUpdating, hasInsurableLines} =
    useCheckout()

  const nextStep = hasInsurableLines ? '/karfa/trygging/' : '/kaup/'

  const handleClick = (e: React.MouseEvent) => {
    if (!user) {
      e.preventDefault()
      setPromptAuth(true)
    }
  }

  useEffect(() => {
    if (user && promptAuth) {
      setPromptAuth(false)
    }
  }, [promptAuth, user])

  return (
    <SideDrawer
      isOpen={isOpen}
      noFocus={restCheckoutLoading || !restCheckout?.quantity}
      onRequestClose={onRequestClose}
      onDutyFreeFrontPage={onDutyFreeFrontPage}
      newDesign
    >
      {promptAuth ? (
        <AuthSection inCheckout />
      ) : (
        <Content>
          {restCheckoutLoading ? (
            <DotsLoader />
          ) : !restCheckout?.quantity ? (
            <EmptyResults icon={ShoppingCartLineIcon}>
              <span data-cy="minicart-empty">Karfan þín er tóm</span>
            </EmptyResults>
          ) : (
            <CartWrapper>
              <CartHeadWrapper>
                <CartHead>
                  <span>Yfirlit körfu</span>
                </CartHead>
              </CartHeadWrapper>

              <ButtonsWrapper>
                <Link href="/karfa" passHref legacyBehavior>
                  <ViewCartButton onClick={onRequestClose}>
                    <ShoppingCartLineIcon size={20} />

                    <span>Skoða körfu</span>
                  </ViewCartButton>
                </Link>

                <Link href={nextStep} passHref legacyBehavior>
                  <CheckoutButton onClick={handleClick}>
                    {onDutyFree ? 'Sækja við brottför' : 'Klára kaup'}

                    <ArrowRightSLineIcon size={20} />
                  </CheckoutButton>
                </Link>
              </ButtonsWrapper>

              <Items>
                {restCheckout?.lines?.map((line: CheckoutLine) => (
                  <NewMiniCartItem key={line.id} line={line} />
                ))}
              </Items>
              <TotalBox style={{opacity: cartIsUpdating ? 0.4 : 1}}>
                <TotalLabel>samtals</TotalLabel>

                <TotalPrice>{formatPrice(restCheckout?.totalPrice)}</TotalPrice>
              </TotalBox>
            </CartWrapper>
          )}
        </Content>
      )}
    </SideDrawer>
  )
}
