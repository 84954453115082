import {OldPriceText, PriceText} from '@festi/common/components/typography'
import { fluidRange } from '@festi/common/utils/styles'
import {formatPrice} from '@festi/utils/numbers'

interface Props {
  type?: 'small' | 'large' | 'grey'
  price: number
  lowestPrice: number
  discountedPrice?: number | null
  size?: string | ReturnType<typeof fluidRange>
  oldPriceSize?: string | ReturnType<typeof fluidRange>
}

export function ProductPrices({
  type,
  price,
  lowestPrice,
  discountedPrice,
  size,
  oldPriceSize,
}: Props): JSX.Element {
  return (
    <>
      {(!!discountedPrice || lowestPrice < price) && (
        <OldPriceText className={type ? type : ""} oldPriceSize={oldPriceSize}>
          {formatPrice(price)}
        </OldPriceText>
      )}
      <PriceText className={type ? type : ""} size={size}>
        {formatPrice(lowestPrice)}
      </PriceText>
    </>
  )
}
