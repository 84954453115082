// This is rounding function that works the same way as lodash.round
function round(num: number, precision = 0): number {
  const factor = Math.pow(10, precision)
  return Math.round(num * factor) / factor
}

export function formatNumber(num: number, precision = 0): string {
  return round(num, precision).toLocaleString('de-DE')
}

export function formatPrice(price: number | string): string {
  if (typeof price === 'string') {
    return `${formatNumber(parseFloat(price))} kr.`
  }

  return `${formatNumber(price)} kr.`
}

export function formatPriceWithSuffix(num: number): string {
  if (num >= 1000000) {
    return `${formatNumber(num / 1000000, 2)} milj.`
  }
  if (num <= 1000) {
    return `${formatNumber(num)} kr.`
  }
  return `${formatNumber(num / 1000, 1)} þús.`
}
