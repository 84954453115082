import React, {useCallback} from 'react'

import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import styled from 'styled-components'

import {NewActionButton} from '@festi/common/components/buttons'
import {fluidRange} from '@festi/common/utils/styles'

interface Props {
  children: React.ReactNode
  title?: string
  setViewMore: React.Dispatch<React.SetStateAction<boolean>>
}

const Wrapper = styled.div`
  ${fluidRange('padding-block', '24px', '20px')};
`

const Title = styled.h5`
  font-size: 20px;
  font-weight: 500;
  ${fluidRange('padding-bottom', '20px', '28px')};
`

const Content = styled.div`
  ${fluidRange('padding-bottom', '20px', '28px')};
`

export default function ViewMoreWrapper({
  children,
  title,
  setViewMore,
}: Props): JSX.Element {
  const onViewMore = useCallback(() => {
    setViewMore(true)
  }, [setViewMore])

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      <Content>{children}</Content>

      <NewActionButton icon={ArrowRightSLineIcon} onClick={onViewMore}>
        Sjá meira
      </NewActionButton>
    </Wrapper>
  )
}
