import React from 'react'

import sanitizeHtml from 'sanitize-html'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {fluidRange} from '@festi/common/utils/styles'
import settings from '@festi/common/constants/settings'

interface Props {
  html: string
}

const Desc = styled.div`
  color: ${palette('blue')};
  line-height: 1.5;
  font-size: 1.125rem;

  p {
    margin-bottom: 12px;
  }

  iframe {
    width: 100%;
    height: 250px;

    ${media.sm`
      height: 300px;
    `}

    ${media.md`
      height: 350px;
    `}
  }

  img {
    height: auto;
    max-width: 100%;
  }

  b,
  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  a {
    font-size: 1rem;
    font-weight: 500;
    color: ${palette('lightBlue')};

    img {
      content: attr(alt);
      display: block;
      margin: 8px 0;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 8px;

    li {
      position: relative;
      padding-left: 16px;

      &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        display: block;
        width: 8px;
        height: 2px;
        border-radius: 2px;
        background-color: ${palette('lightBlue')};
      }
    }
  }

  ol {
    list-style: decimal;
    padding-left: 2rem;
    margin-bottom: 8px;

    li::marker {
      color: ${palette('lightBlue')};
    }
  }

  li {
    font-weight: 400;
    text-align: left;
    line-height: 1.2;
    color: ${palette('blue')};
    margin-bottom: 8px;
    ${fluidRange('font-size', '15px', '18px')}
  }
`

export default function ProductRichText({html}: Props): JSX.Element {
  return (
    <Desc
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(html, {
          allowedTags: sanitizeHtml.defaults?.allowedTags.concat([
            'img',
            'iframe',
          ]),
          allowedAttributes: {
            iframe: ['src'],
            img: ['src'],
            a: ['href', 'target'],
          },
          allowedIframeHostnames: [
            'www.youtube.com',
            'player.vimeo.com',
            'www.youtube-nocookie.com',
            'youtube-nocookie.com',
            'www.youtube-nocookie.com',
            'youtube.com',
            'youtu.be',
            'www.youtu.be',
          ],
          transformTags: {
            a: (tagName, attribs) => {
              // Force all anchor tags to have target="_blank"
              return {
                tagName,
                attribs: {
                  ...attribs,
                  target: attribs?.href?.includes(settings.baseUrl)
                    ? ''
                    : '_blank',
                },
              }
            },
          },
        }),
      }}
    />
  )
}
