import styled from 'styled-components'
import {palette} from 'styled-tools'

const TermsLink = styled.a`
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('lightBlue')};
  border-bottom: 2px solid ${palette('lightBlue')};
  padding: 0;
  cursor: pointer;
  background: none;
  transition: color 0.15s, border-color 0.15s;

  &:hover {
    color: ${palette('lightBlue60')};
    border-color: ${palette('lightBlue60')};
  }
`

export default TermsLink
