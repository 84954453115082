import Image from 'next/image'
import styled from 'styled-components'
import {useUpdateEffect, useWindowSize} from 'react-use'
import {useControls} from 'react-zoom-pan-pinch'

import {Coordinates} from '@festi/common/api/rest'

import PricerPin from './PricerPin'

interface MapProps {
  width: number
  height: number
}

const Map = styled.div<MapProps>`
  position: relative;
  height: ${(p) => (p.height ? `${p.height}px` : '800px')};
  margin: 0 auto;

  img {
    width: ${(p) => (p.width ? `${p.width}px` : '425px')};
    height: ${(p) => (p.height ? `${p.height}px` : '800px')};
  }
`

export const storeInfo = {
  skeifan: {image: '/images/skeifan-store1.png', storeId: 1861},
  grandi: {image: '/images/grandi-store.png', storeId: 2443},
  kringlan: {image: '/images/kringlan-store.png', storeId: 1861},
  akureyri: {image: '/images/akureyri-store.png', storeId: 1861},
}

const mapSizes = {
  skeifan: {
    width: 1600,
    height: 3000,
  },
  grandi: {
    width: 1700,
    height: 2900,
  },
  kringlan: {
    width: 4000,
    height: 6823,
  },
  akureyri: {
    width: 4000,
    height: 6800,
  },
}

interface Props {
  pulse: boolean
  selected: string
  locations: Coordinates[]
}

export default function PricerMap({pulse, selected, locations}: Props) {
  const {resetTransform} = useControls()

  const {height} = useWindowSize()

  const maxHeight = 1000

  const selectedImage = storeInfo[selected].image

  const baseWidth = mapSizes[selected].width
  const baseHeight = mapSizes[selected].height

  const calculatedHeight = (height > maxHeight ? maxHeight : height) - 240
  const calculateWidth = baseWidth / (baseHeight / calculatedHeight)

  useUpdateEffect(() => {
    resetTransform()
  }, [selected])

  return (
    <Map width={calculateWidth} height={calculatedHeight}>
      <Image
        src={selectedImage}
        alt={`Map for ${selected}`}
        width={baseWidth}
        height={baseHeight}
      />

      <PricerPin
        store={selected}
        pulse={pulse}
        locations={locations}
        imageSize={{
          width: calculateWidth,
          height: calculatedHeight,
        }}
      />
    </Map>
  )
}
