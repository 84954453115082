import Link from 'next/link'
import Image from 'next/image'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'

import {LogoDesktopDark, LogoMobile} from '@festi/common/images'

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 16px;

  ${media.md`
    padding: 0 32px;
  `}
`

const MobileLogo = styled(Image)`
  display: block;
  width: auto;
  height: 32px;

  ${media.md`
    display: none;
  `}
`

const DesktopLogo = styled(Image)`
  display: none;
  width: auto;
  height: 40px;

  ${media.md`
    display: block;
  `}
`

export default function HeaderLogo() {
  return (
    <LogoLink href="/">
      <MobileLogo src={LogoMobile} alt="Elko" />
      <DesktopLogo src={LogoDesktopDark} alt="Elko" />
    </LogoLink>
  )
}
