import {useCallback} from 'react'

import cn from 'classnames'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {KeepScale} from 'react-zoom-pan-pinch'

import {Coordinates} from '@festi/common/api/rest'

const Positioner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
`

const Pinner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const YellowCircle = styled.div`
  position: absolute;
  bottom: 5px;
  left: -20px;
  width: 40px;
  height: 40px;
  z-index: 1;
  background-color: ${palette('warning')};
  border-radius: 50%;

  &.pulse {
    transform: scale(1);
    animation: pulse 2s infinite;
    box-shadow: 0 0 0 0 ${palette('warning')};

    @keyframes pulse {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 ${palette('warning')};
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 20px rgba(246, 131, 156, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(246, 131, 156, 0);
      }
    }
  }
`

const Dot = styled.div`
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 16px;
  height: 16px;
  z-index: 2;
  background-color: ${palette('black')};
  border-radius: 50%;
  transform: translate(-50%, -50%);
`

const Triangle = styled.div`
  position: absolute;
  bottom: 4px;
  left: -3px;
  width: 12px;
  height: 12px;
  border-top-right-radius: 30%;
  z-index: 1;
  background-color: ${palette('warning')};
  transform: rotate(0deg) skewX(-30deg) scale(1, 0.866);

  &::before {
    content: '';
    position: absolute;
    background-color: inherit;
    width: 12px;
    height: 12px;
    border-top-right-radius: 30%;
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
  }
  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-top-right-radius: 30%;
    background-color: inherit;
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
`

const WhiteCircle = styled.div`
  position: absolute;
  bottom: 2px;
  left: -23px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: white;
`

interface PinProps {
  pulse?: boolean
}

export function Pin({pulse}: PinProps) {
  return (
    <Pinner>
      <YellowCircle className={cn({pulse})} />
      <Dot />
      <Triangle />
      <Triangle
        style={{
          bottom: 0,
          backgroundColor: 'white',
          zIndex: 0,
        }}
      />
      <WhiteCircle />
    </Pinner>
  )
}

export const originalSize = {
  skeifan: {
    width: 2250,
    height: 4218,
  },
  grandi: {
    width: 4000,
    height: 6823,
  },
  kringlan: {
    width: 4000,
    height: 6823,
  },
  akureyri: {
    width: 4000,
    height: 6800,
  },
}

const centerPlacement = {
  skeifan: {
    x: 1,
    y: 0.5,
  },
  grandi: {
    x: 0.5,
    y: 0.29,
  },
  kringlan: {
    x: 0,
    y: 1,
  },
  akureyri: {
    x: 0,
    y: 1,
  },
}

const offset = {
  skeifan: {
    x: -100,
    y: 4,
  },
  grandi: {
    x: 0,
    y: 4,
  },
  kringlan: {
    x: -40,
    y: 4,
  },
  akureyri: {
    x: -40,
    y: 4,
  },
}

interface PinInterface {
  store: string
  pulse?: boolean
  locations?: Coordinates[]
  imageSize: {
    width: number
    height: number
  }
}

export default function PricerPin({
  store,
  pulse,
  locations,
  imageSize,
}: PinInterface) {
  const position = useCallback(
    (type: string, l: Coordinates) => {
      const width = imageSize.width
      const height = imageSize.height
      const centerX = width * centerPlacement[type].x
      const centerY = height * centerPlacement[type].y

      const offsetX = offset[type].x
      const offsetY = offset[type].y

      const widthRatio = originalSize[type].width / width
      const heightRatio = originalSize[type].height / height

      const longitude = (l.x * 100) / widthRatio
      const latitude = (l.y * 100) / heightRatio

      const store = {
        skeifan: {
          left: centerX + latitude + offsetX / widthRatio,
          bottom: centerY - longitude + offsetY,
        },
        grandi: {
          left: centerX + longitude + offsetX,
          bottom: centerY + latitude + offsetY,
        },
        kringlan: {
          left: centerX + longitude + offsetX,
          bottom: centerY + latitude + offsetY,
        },
        akureyri: {
          left: centerX + longitude + offsetX,
          bottom: centerY + latitude + offsetY,
        },
      }

      return store[type]
    },
    [imageSize],
  )

  return (
    <>
      {locations?.map((l, i) => (
        <Positioner
          key={i}
          style={{
            marginLeft: `${position(store, l).left}px`,
            marginBottom: `${position(store, l).bottom}px`,
          }}
        >
          <KeepScale>
            <Pin pulse={pulse} />
          </KeepScale>
        </Positioner>
      ))}
    </>
  )
}
