import React from 'react'

import cn from 'classnames'
import times from 'lodash/times'
import Link from 'next/link'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import ArrowLeftRightLineIcon from 'remixicon-react/ArrowLeftRightLineIcon'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'

import {AnchorButton, TextButton} from '@festi/common/components/buttons'
import {ShowDesktop, ShowMobile} from '@festi/common/components/common'
import {useComparison} from '@festi/common/contexts'
import {styledTheme} from '@festi/common/themes'
import {VariantDetail} from '@festi/common/api/rest'

interface Props {
  visible?: boolean
}
const Wrapper = styled.div`
  z-index: ${theme('zIndex.comparisonBanner')};
  box-shadow: ${theme('boxShadow.comparisonBanner')};
  background-color: ${palette('white')};
  pointer-events: all;
  position: fixed;
  opacity: 0;
  bottom: -120px;
  left: 0;
  right: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  transition: opacity 0.2s, bottom 0.2s ease-out;

  &.visible {
    opacity: 1;
    bottom: 0;
  }
`

const BannerHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`

const Title = styled.div`
  font-size: 1rem;
  line-height: 1;
  color: ${palette('blue')};
  font-weight: 500;
`

const MobileText = styled.div`
  font-size: 1rem;
  color: ${palette('ui60Solid')};
  font-weight: 500;
  padding: 20px;
`

const AddToComparisonField = styled.button`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px dashed ${palette('ui20Solid')};
  border-radius: 0;
  background-color: ${palette('ui5Solid')};
  cursor: pointer;
  transition: background-color 0.15s;

  .remixicon-icon {
    height: 24px;
    width: 24px;
    color: ${palette('ui40Solid')};
    opacity: 0;
    transition: opacity 0.15s;
  }

  &:hover {
    background-color: ${palette('white')};

    .remixicon-icon {
      opacity: 1;
    }
  }
`

const ComparedVariant = styled.div`
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border: 1px solid ${palette('ui20Solid')};
  background-color: ${palette('white')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
`

const ComparedVariantHead = styled.a`
  display: flex;
  align-items: center;
  max-width: calc(100% - 32px);
  width: calc(100% - 32px);
  cursor: pointer;
`

const ComparedVariantImg = styled.div`
  width: 24px;
  min-width: 24px;
  height: 32px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 8px;
`

const ComparedVariantName = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3;
  color: ${palette('blue')};
  margin-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  flex-grow: 1;
`

const ComparedVariantNameFade = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 32px;
  background: ${palette('white')};
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 96%
  );
`

const RemoveButton = styled.button`
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 12px;
  background-color: ${palette('ui10Solid')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  cursor: pointer;
`

interface ComparisonFieldProps {
  variant: VariantDetail
  onClickRemove: (sku: string) => void
}

// TODO: Fill with data
function ComparisonField({variant, onClickRemove}: ComparisonFieldProps) {
  const {sku, name, images, product} = variant
  const {slug} = product
  const imgSrc = images?.[0]?.image?.productList

  return (
    <ComparedVariant>
      <Link href={`/vorur/${slug}/${sku}/`} passHref legacyBehavior>
        <ComparedVariantHead>
          <ComparedVariantImg
            style={{
              backgroundImage: `url('${imgSrc}')`,
            }}
          />
          <ComparedVariantName>
            {name}
            <ComparedVariantNameFade />
          </ComparedVariantName>
        </ComparedVariantHead>
      </Link>
      <RemoveButton onClick={() => onClickRemove(sku)}>
        <CloseLineIcon size={20} color={styledTheme.palette.blue} />
      </RemoveButton>
    </ComparedVariant>
  )
}

export default function ComparisonBanner({visible}: Props): JSX.Element {
  const {
    comparisonCap,
    comparedProducts,
    clearComparison,
    removeFromComparison,
    toggleComparisonSearch,
  } = useComparison()

  const emptyCount = comparisonCap - comparedProducts?.length

  return (
    <Wrapper className={cn({visible})}>
      <Container>
        <BannerHead>
          <Title>Samanburður</Title>
          <TextButton onClick={clearComparison}>Hreinsa samanburð</TextButton>
        </BannerHead>
        <Row>
          <Col col={3} sm={6} md={9}>
            <ShowMobile>
              <MobileText>
                {comparedProducts?.length || 0}/{comparisonCap}
              </MobileText>
            </ShowMobile>
            <ShowDesktop>
              <Row>
                {comparedProducts?.map((variant) => (
                  <Col key={variant.sku} col={3}>
                    <ComparisonField
                      variant={variant}
                      onClickRemove={removeFromComparison}
                      aria-label="Remove"
                    />
                  </Col>
                ))}
                {times(emptyCount, (index: number) => (
                  <Col key={index} col={3}>
                    <AddToComparisonField
                      onClick={toggleComparisonSearch}
                      aria-label="Bæta við í samanburð"
                    >
                      <AddLineIcon />
                    </AddToComparisonField>
                  </Col>
                ))}
              </Row>
            </ShowDesktop>
          </Col>
          <Col col={9} sm={6} md={3}>
            <Link href="/samanburdur/" passHref legacyBehavior>
              <AnchorButton icon={ArrowLeftRightLineIcon}>
                Skoða samanburð
              </AnchorButton>
            </Link>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}
