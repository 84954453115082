import {useCallback, useEffect, useState} from 'react'

import cn from 'classnames'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

import {useCommonContext} from '@festi/common/contexts/common'
import {
  CategoryTreeItem,
  buildCategoryTree,
} from '@festi/common/utils/categories'
import {styledTheme} from '@festi/common/themes'

import {H3} from '../typography'
import DrawerTransition from './DrawerTransition'
import SubMenuCategoryItem from './SubMenuCategoryItem'

const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`

const ScrollFrame = styled.div`
  display: flex;
  justify-content: center;
`

const Menu = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: calc(100vw - 60px);
  background-color: ${palette('blue')};
  transition: width 200ms ease-out;
  overflow-y: auto;

  &.hasSelectedCategory {
    max-width: 48px;
    border-right: 8px solid ${palette('green')};

    ${media.md`
      max-width: 544px;
    `}
  }

  ${media.sm`
    max-width: 544px;
  `}
`

const MenuList = styled.div`
  padding: 30px 0;
  overflow: hidden;

  &.isVisible {
    display: none;
    overflow: hidden;

    ${media.md`
      display: block;
      overflow: visible;
    `}
  }
`

const MenuItemText = styled(H3)`
  color: ${palette('white')};
  transition: transform 0.2s ease-out;
`

const MenuItemBtn = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  background: none;
  cursor: pointer;

  .remixicon-icon {
    position: absolute;
    top: calc(50% - 16px);
    left: -8px;
    transform: scale(0);
    transition: transform 0.2s ease-out;
  }

  &:hover,
  &.selected {
    outline: none;

    .remixicon-icon {
      transform: scale(1);
    }

    ${MenuItemText} {
      transform: translateX(24px);
    }
  }
`

const SelectedCategoryNameText = styled.span`
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  color: ${palette('white')};
`

const SelectedCategoryName = styled.div`
  position: absolute;
  top: 240px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  opacity: 0;
  transform-origin: 50% 0;
  transform: rotate(-90deg);
  transition: opacity 0.15s;

  &.isVisible {
    opacity: 1;
  }

  ${media.md`
    display: none;
  `}
`

const SubMenu = styled.div`
  position: relative;
  width: 0;
  max-width: 354px;
  background-color: ${palette('white')};
  transition: width 200ms ease-out;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.isVisible {
    width: 100%;

    &.subSub {
      border-left: 4px solid ${palette('green')};
    }
  }

  &.subSub {
    display: none;

    ${media.md`
      display: block;
    `}
  }
`

const SubMenuContent = styled.div`
  padding: 10px 20px 0 20px;

  ${media.md`
    padding: 50px 50px 0 50px;
  `}
`

const BackBtn = styled.button`
  position: sticky;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  width: 100%;
  height: 56px;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: left;
  z-index: 1;
  border: 0;
  padding: 0 10px;
  color: ${palette('blue')};
  background-color: ${palette('white')};
  border-bottom: 1px solid ${palette('ui10Solid')};
  cursor: pointer;

  span {
    padding-left: 8px;
  }

  &.isVisible {
    display: flex;
  }

  ${media.md`
    &.isVisible {
      display: none;
    }
  `}
`

const CLOSE_TARGET_ID = 'close-target'

interface Props {
  isOpen: boolean
  onDutyFreeFrontPage: boolean
  onRequestClose: () => void
}

export default function NewCategoriesDrawer({isOpen, onRequestClose}: Props) {
  const {categories} = useCommonContext()

  const [selectedCategory, setSelectedCategory] = useState<CategoryTreeItem>()
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<CategoryTreeItem>()

  const categoryTree = buildCategoryTree(categories)

  const shouldClose = useCallback(() => {
    onRequestClose()
  }, [onRequestClose])

  const handleClickMenuItem = useCallback(
    (category?: CategoryTreeItem) => {
      setSelectedCategory(
        category?.id !== selectedCategory?.id ? category : undefined,
      )
      setSelectedSubCategory(undefined)
    },
    [selectedCategory],
  )

  const handleClickOutsideContent = useCallback(
    (e: React.BaseSyntheticEvent) => {
      if (e.target.id === CLOSE_TARGET_ID) {
        shouldClose()
      }
    },
    [shouldClose],
  )

  // Prevent scrolling when drawer is open
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'
  }, [isOpen])

  return (
    <DrawerTransition id="categories-drawer" isOpen={isOpen}>
      <MenuWrapper id={CLOSE_TARGET_ID} onClick={handleClickOutsideContent}>
        <Menu className={cn({hasSelectedCategory: selectedCategory})}>
          <ScrollFrame>
            <MenuList className={cn({isVisible: selectedCategory})}>
              {categoryTree.map((category: CategoryTreeItem) => (
                <MenuItemBtn
                  key={category.id}
                  onClick={() => handleClickMenuItem(category)}
                  className={cn(
                    selectedCategory?.id === category.id ? 'selected' : 'faded',
                  )}
                >
                  <ArrowRightSLineIcon
                    size={32}
                    color={styledTheme.palette.green}
                  />
                  <MenuItemText>{category.name}</MenuItemText>
                </MenuItemBtn>
              ))}
            </MenuList>

            <SelectedCategoryName
              className={cn({isVisible: selectedCategory})}
              onClick={() => handleClickMenuItem(undefined)}
            >
              <SelectedCategoryNameText>
                {selectedCategory?.name}
              </SelectedCategoryNameText>
            </SelectedCategoryName>
          </ScrollFrame>
        </Menu>

        <SubMenu className={cn({isVisible: selectedCategory})}>
          <BackBtn
            onClick={() => setSelectedCategory(undefined)}
            className={cn({isVisible: selectedCategory})}
          >
            <ArrowLeftSLineIcon size={24} color={styledTheme.palette.green} />
            <span>Til baka</span>
          </BackBtn>

          <SubMenuContent>
            {selectedCategory?.children?.map(
              (subCategory: CategoryTreeItem) => (
                <SubMenuCategoryItem
                  key={subCategory.id}
                  categoryItem={subCategory}
                  selectedSubCategory={selectedSubCategory}
                  onClickCategory={onRequestClose}
                  setSelectedSubCategory={setSelectedSubCategory}
                />
              ),
            )}
          </SubMenuContent>
        </SubMenu>

        <SubMenu className={cn({subSub: true, isVisible: selectedSubCategory})}>
          <SubMenuContent>
            {selectedSubCategory?.children?.map(
              (subCategory: CategoryTreeItem) => (
                <SubMenuCategoryItem
                  key={subCategory.id}
                  categoryItem={subCategory}
                  selectedSubCategory={selectedSubCategory}
                  onClickCategory={onRequestClose}
                />
              ),
            )}
          </SubMenuContent>
        </SubMenu>
      </MenuWrapper>
    </DrawerTransition>
  )
}
