import React from 'react'

import styled from 'styled-components'
import {palette} from 'styled-tools'

import {OutlinedButton} from '@festi/common/components/buttons'
import {fluidRange} from '@festi/common/utils/styles'

interface Props {
  currentCount: number
  totalCount: number
  onClickMore?: () => void
}

interface IndicatorProps {
  percentage: number
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${fluidRange('padding-top', '20px', '40px')}
  ${fluidRange('padding-bottom', '30px', '50px')}
`

const ContentContainer = styled.div`
  width: calc(100% - 32px);
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100% !important;
  }
`

const Label = styled.span`
  font-weight: 500;
  color: ${palette('blue')};
  text-align: center;
  ${fluidRange('font-size', '14px', '16px')}
  ${fluidRange('margin-bottom', '24px', '32px')}
`

const Indicator = styled.div<IndicatorProps>`
  width: 100%;
  margin-bottom: 16px;
  height: 2px;
  background-color: ${palette('ui20Solid')};
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: 0;
    height: 6px;
    background-color: ${palette('blue')};
    width: ${(p) => `${p.percentage}%`};
  }
`

export default function PaginationIndicator({
  currentCount,
  totalCount,
  onClickMore,
}: Props): JSX.Element {
  return (
    <Wrapper>
      <ContentContainer>
        <Label>{`Þú hefur séð ${currentCount} af ${totalCount}`}</Label>
        <Indicator percentage={(currentCount / (totalCount || 1)) * 100} />
        <OutlinedButton onClick={onClickMore}>Sjá fleiri vörur</OutlinedButton>
      </ContentContainer>
    </Wrapper>
  )
}
