import React, {useCallback, useEffect, useState} from 'react'

import {UseComboboxStateChange} from 'downshift'
import {useField, FieldConfig} from 'formik'
import toPairs from 'lodash/toPairs'

import {PostalCode} from '@festi/common/api/rest'
import {POSTAL_CODES} from '@festi/common/utils/postalCodes'

import InputSelect from '../form/InputSelect'

export default function PostalCodeSelect(
  props: React.SelectHTMLAttributes<HTMLSelectElement> &
    FieldConfig<PostalCode>,
): JSX.Element {
  const [_, meta, helpers] = useField(props.name)
  const postalCodes = toPairs(POSTAL_CODES).map((p) => ({
    code: p[0],
    name: p[1],
  }))
  const [selectedItem, setSelectedItem] = useState<PostalCode>({
    code: '',
    name: '',
  })

  const {setValue, setTouched} = helpers
  const value = meta?.value as PostalCode
  const errorMsg = !!meta.touched && meta.error

  const onSelect = useCallback(
    (changes: UseComboboxStateChange<PostalCode>) => {
      setTimeout(() => setValue(changes?.selectedItem), 0)
    },
    [setValue],
  )

  const onClear = useCallback(() => {
    setValue({code: '', name: ''})
  }, [setValue])

  useEffect(() => {
    if (value) {
      setSelectedItem(value)
    }
  }, [value])

  return (
    <InputSelect
      itemToString={(item) => item.name}
      items={postalCodes}
      selectedItem={selectedItem}
      placeholder="Staður"
      handleSelectedItemChange={onSelect}
      handleClearSelection={onClear}
      errorMsg={errorMsg}
      setTouched={setTouched}
    />
  )
}
