import ShoppingCartLineIcon from 'remixicon-react/ShoppingCartLineIcon'
import FlightTakeoffLineIcon from 'remixicon-react/FlightTakeoffLineIcon'

import {onDutyFree} from '@festi/common/constants/channels'

import UtilityButton from './UtilityButton'
import {useCheckout} from '@festi/common/contexts'

interface Props {
  onClick: () => void
}

export default function CheckoutButton({onClick}: Props) {
  const {restCheckout} = useCheckout()
  return (
    <UtilityButton
      icon={onDutyFree ? FlightTakeoffLineIcon : ShoppingCartLineIcon}
      counter={restCheckout?.quantity}
      onClick={onClick}
    />
  )
}
