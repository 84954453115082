import React, {useState} from 'react'

import {formatSSN} from '@festi/utils/strings'
import FileList3LineIcon from 'remixicon-react/FileList3LineIcon'
import UserLineIcon from 'remixicon-react/UserLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {UserAddresses_me_addresses} from '@festi/common/api/saleor/types/UserAddresses'
import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'

interface Props {
  address: UserAddresses_me_addresses
  isBilling?: boolean
  selected?: boolean
  onSelect: () => void
  onRemove?: () => void
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 16px;
  transition: opacity 0.2s;
`

const RadioContainer = styled.div`
  width: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
`

const RadioDotBtn = styled.button`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: none;
  border: 1px solid ${palette('ui20Solid')};
  transition: border-color 0.15s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

const RadioDot = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.15s;
`

const Card = styled.div`
  flex-grow: 1;
  position: relative;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${palette('ui20Solid')};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${palette('white')};
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.15s;
`

const RemoveWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 16px 20px;
  background-color: ${palette('white')};
  z-index: 1;
`

const ActionBtn = styled.button`
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: underline;
  color: ${palette('ui50Solid')};
  transition: color 0.15s;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0 4px;

  &:hover,
  &:focus {
    color: ${palette('ui80Solid')};
    outline: none;
  }
`

const PromptText = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${palette('ui60Solid')};
  margin-right: 4px;
`

const Content = styled.div`
  display: flex;
`

const IconBox = styled.div`
  ${fluidRange('height', '48px', '56px')}
  ${fluidRange('width', '48px', '56px')}
  ${fluidRange('min-width', '48px', '56px')}
  ${fluidRange('margin-right', '24px', '32px')}
  background-color: ${palette('light')};
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${fluidRange('padding-right', '52px', '78px')}
`

const ListItem = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: ${palette('blue')};
  margin-bottom: 5px;
  line-height: 1.2;

  strong {
    font-weight: 500;
  }
`

export default function AddressCard({
  address,
  isBilling,
  selected = false,
  onSelect,
  onRemove,
}: Props): JSX.Element {
  const [isRemoving, setIsRemoving] = useState<boolean>(false)
  const [removePromptActive, setRemovePromptActive] = useState<boolean>(false)

  const {
    firstName,
    lastName,
    streetAddress1,
    streetAddress2,
    postalCode,
    city,
    phone,
  } = address

  return (
    <Wrapper style={isRemoving ? {opacity: 0.4, pointerEvents: 'none'} : {}}>
      <RadioContainer>
        <RadioDotBtn
          onClick={onSelect}
          style={{
            borderColor: selected
              ? styledTheme.palette.lightBlue
              : styledTheme.palette.ui20Solid,
          }}
        >
          <RadioDot
            style={{
              backgroundColor: selected
                ? styledTheme.palette.lightBlue
                : 'transparent',
            }}
          />
        </RadioDotBtn>
      </RadioContainer>
      <Card
        style={{
          borderColor: selected
            ? styledTheme.palette.lightBlue
            : styledTheme.palette.ui20Solid,
          boxShadow: selected ? styledTheme.boxShadow.card : '',
        }}
        onClick={onSelect}
      >
        <Content>
          <IconBox>
            {isBilling ? (
              <FileList3LineIcon
                size={24}
                color={styledTheme.palette.lightBlue}
              />
            ) : (
              <UserLineIcon size={24} color={styledTheme.palette.lightBlue} />
            )}
          </IconBox>
          <ContentContainer>
            <ListItem>
              <strong>{firstName}</strong>
            </ListItem>
            <ListItem>{formatSSN(lastName)}</ListItem>
            <ListItem>
              {streetAddress1}
              {!!streetAddress2 && `, ${streetAddress2}`}, {postalCode} {city}
            </ListItem>
            {!isBilling && <ListItem>{phone}</ListItem>}
          </ContentContainer>
        </Content>
      </Card>
      <RemoveWrapper>
        {!!onRemove && !removePromptActive && (
          <ActionBtn onClick={() => setRemovePromptActive(true)}>
            Eyða
          </ActionBtn>
        )}
        {removePromptActive && (
          <>
            <PromptText>Ertu viss?</PromptText>
            <ActionBtn
              onClick={() => {
                setIsRemoving(true)
                onRemove()
              }}
            >
              Já, Eyða
            </ActionBtn>
            <ActionBtn onClick={() => setRemovePromptActive(false)}>
              Hætta við
            </ActionBtn>
          </>
        )}
      </RemoveWrapper>
    </Wrapper>
  )
}
