import {useCallback, useState} from 'react'

import cn from 'classnames'
import {capitalize} from 'lodash'
import styled from 'styled-components'
import {toast} from 'react-toastify'
import {palette} from 'styled-tools'
import TagManager from 'react-gtm-module'
import {media} from 'styled-bootstrap-grid'
import {useLocalStorage, useMedia} from 'react-use'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'
import StoreLineIcon from 'remixicon-react/StoreLineIcon'
import Focus3LineIcon from 'remixicon-react/Focus3LineIcon'
import ShareBoxLineIcon from 'remixicon-react/ShareBoxLineIcon'

import {H5, H6} from '@festi/common/components/typography'
import {Modal, ModalSize} from '@festi/common/components/common'
import {styledTheme} from '@festi/common/themes'
import {
  ProductDetail,
  restApi,
  Variant,
  VariantDetail,
} from '@festi/common/api/rest'
import settings from '@festi/common/constants/settings'
import {MainButton, MainIconButton} from '@festi/common/components/buttons'

import Countdown from './Countdown'
import PricerEmptyMap from './PricerEmptyMap'
import PricerMap, {storeInfo} from './PricerMap'

const Headline = styled(H6)`
  color: ${palette('ui50Solid')};
  font-size: 14px;
  font-weight: 180;
  line-height: 20px;
  margin-bottom: 12px;
`

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${palette('link')};
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`

const Icon = styled.div`
  padding: 7px;
  border-radius: 8px;
  background-color: ${palette('ui5Solid')};
  fill: ${palette('link')};
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 20px;

  ${media.md`
    flex-direction: row;
  `}
`

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 24px 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  ${media.md`
    padding: 24px;
    max-width: 360px;
    background-color: ${palette('ui5Solid')};
  `}
`

const ProductTitle = styled(H5)`
  margin-bottom: 24px;

  span {
    font-size: 14px;
    font-weight: 500;
    padding: 0 6px;
    color: ${palette('ui40Solid')};
  }
`

const MainContent = styled.div`
  width: 100%;

  .react-transform-wrapper {
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    > div {
      width: 100%;
      padding: 20px;
      margin-bottom: 60px;
      border-radius: 20px;
    }
  }
  ${media.md`
    padding-bottom: 0;

    .react-transform-wrapper {
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 20px;
    }
  `}
`

const PulseButton = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`

const StoreWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  padding-bottom: 8px;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.md`
    flex-direction: column;
    padding-bottom: 0;
  `};
`

const StoreButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  font-weight: 500;
  color: ${palette('blue')};
  border: 1px solid #dbdde6;
  border-radius: 12px;
  background-color: transparent;
  cursor: pointer;

  &.active {
    color: ${palette('link')};
    border: 2px solid ${palette('link')};

    .remixicon-icon {
      fill: ${palette('link')};
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const ExternalMap = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;

  ${media.md`
    position: relative;
    left: initial;
    bottom: initial;
    margin-top: auto;
  `}
`

interface Props {
  variant: VariantDetail | Variant
  product?: ProductDetail
}

export default function QsPricer({variant, product}: Props) {
  const [open, setOpen] = useState(false)
  const [pulse, setPulse] = useState('')
  const [selected, setSelected] = useLocalStorage('selectedStore', 'skeifan')

  const {id} = 'product' in variant ? variant?.product : product

  const isSM = useMedia('(max-width: 700px)', false)

  const locations = variant.shelfLabels.find(
    (sl) => sl.store === selected,
  )?.locations

  const handleAnalytics = useCallback(
    (event: string, store: string) => {
      TagManager.dataLayer({
        dataLayer: {
          event,
          event_label: variant.sku,
          event_location: store,
        },
        dataLayerName: 'dataLayer',
      })
    },
    [variant.sku],
  )

  const handleOpenModal = useCallback(() => {
    setOpen(true)
    setPulse('')
    handleAnalytics('pricer_map_click', selected)
  }, [selected, handleAnalytics])

  const handleCloseModal = useCallback(() => {
    setOpen(false)
    setPulse('')
  }, [])

  const handlePulsePins = useCallback(() => {
    setPulse(selected)

    handleAnalytics('pricer_flash_click', selected)

    !pulse &&
      restApi
        .productsFlashShelfLabelCreate(id, {
          warehouseSlug: selected,
        })
        .then(() => {
          toast.success(
            `Grænt ljós blikkar á verðmerkingu í verslun (${capitalize(
              selected,
            )}) næstu 30 sekúndur`,
          )
        })
        .catch((e) => {
          toast.error(
            e.status === 429
              ? 'Of mikið af fyrirspurnum, reynið aftur síðar'
              : 'Ekki tókst að blikka verðmerkingu í verslun',
          )
          setPulse('')
        })

    setTimeout(() => {
      setPulse('')
    }, 30000)
  }, [pulse, selected, id, setPulse, handleAnalytics])

  const handleSelectStore = useCallback(
    (store: string) => {
      setSelected(store)

      handleAnalytics('pricer_map_switch_location', store)
    },
    [setSelected, handleAnalytics],
  )

  if (!variant?.shelfLabels?.length) return null

  return (
    <>
      <Button onClick={handleOpenModal}>
        <Icon>
          <Focus3LineIcon size={18} color={styledTheme.palette.link} />
        </Icon>
        <span>Sjá staðsetningu í verslun</span>
      </Button>

      <Modal
        isOpen={open}
        size={ModalSize.Large}
        roundBorder
        onRequestClose={handleCloseModal}
      >
        <Inner>
          <Sidebar>
            {!isSM && (
              <>
                <Headline headline>Vara</Headline>

                <ProductTitle>
                  {variant?.name}

                  <span>({variant.sku})</span>
                </ProductTitle>
              </>
            )}

            <div>
              <Headline headline>Verslanir</Headline>

              <StoreWrapper>
                {['skeifan', 'grandi'].map((store, i) => (
                  <StoreButton
                    key={i}
                    onClick={() => handleSelectStore(store)}
                    className={cn({active: store === selected})}
                    style={{textTransform: 'capitalize'}}
                  >
                    <StoreLineIcon
                      size={20}
                      color={styledTheme.palette.ui40Solid}
                    />
                    <span>{store}</span>
                  </StoreButton>
                ))}
              </StoreWrapper>
            </div>

            {settings.redesign && (
              <ExternalMap>
                <a
                  href={`https://qs.pricer.com/qs/${storeInfo?.[selected]?.storeId}?hideSearchPanel=true&itemIds=${variant?.sku}`}
                  target="_blank"
                >
                  {!isSM ? (
                    <MainButton
                      label={'Opna kort'}
                      suffixIcon={ShareBoxLineIcon}
                      buttonVariant="ghost"
                      size="small"
                      fullWidth
                    />
                  ) : (
                    <MainIconButton
                      icon={ShareBoxLineIcon}
                      buttonVariant="ghost"
                      size="small"
                    />
                  )}
                </a>
              </ExternalMap>
            )}
          </Sidebar>

          <MainContent>
            {!!locations?.length ? (
              <TransformWrapper
                pinch={{step: 5, excluded: ['pinchDisabled']}}
                wheel={{step: 5, excluded: ['pinchDisabled']}}
                panning={{excluded: ['panningDisabled']}}
                doubleClick={{step: 5, mode: 'toggle'}}
              >
                <TransformComponent>
                  <PricerMap
                    pulse={pulse === selected}
                    selected={selected}
                    locations={locations}
                  />
                </TransformComponent>

                <PulseButton>
                  <MainButton
                    label={
                      !!pulse ? (
                        <span>
                          Blikkar í&nbsp;
                          <Countdown />
                          &nbsp;sek.
                        </span>
                      ) : (
                        <span>Blikka {!isSM && <>verðmerkingu</>}</span>
                      )
                    }
                    prefixIcon={Focus3LineIcon}
                    buttonVariant="ghost"
                    size={isSM ? 'small' : 'medium'}
                    fullWidth
                    onClick={handlePulsePins}
                    disabled={!!pulse}
                  />
                </PulseButton>
              </TransformWrapper>
            ) : (
              <PricerEmptyMap />
            )}
          </MainContent>
        </Inner>
      </Modal>
    </>
  )
}
