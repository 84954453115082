import {useCallback} from 'react'

import qs from 'query-string'
import {useRouter} from 'next/router'
import {useLocalStorage} from 'react-use'
import {ParsedUrlQueryInput} from 'querystring'
import {isArray, mapValues, take, uniq} from 'lodash'

export default function useAlgoliaSearch() {
  const router = useRouter()
  const queryParams = mapValues(
    qs.parse(router.asPath.split('?')[1] ?? ''),
    (v) => (isArray(v) ? v : v.split(',')),
  )

  const [searchHistory, setSearchHistory] = useLocalStorage<string[]>(
    'searchHistory',
    [],
  )

  const activeCategorySlug = router.query.slug as string | undefined
  const inCategoryRoute = router.pathname.indexOf('/voruflokkar') === 0

  const updateQueryParams = useCallback(
    (params) => {
      if (inCategoryRoute) {
        params.slug = activeCategorySlug
      }

      const search = qs.stringify(params, {arrayFormat: 'comma'})

      router.replace({search}, undefined, {
        shallow: true,
      })
    },
    [router, activeCategorySlug, inCategoryRoute],
  )

  const updateSearchHistory = useCallback(
    (query: string) => {
      if (!searchHistory.find((item: string) => item === query)) {
        const newHistory = take(uniq([query, ...searchHistory]), 3)
        setSearchHistory(newHistory)
      }
    },
    [searchHistory, setSearchHistory],
  )

  const setSorting = useCallback(
    (sort: string) => {
      updateQueryParams({...queryParams, sort})
    },
    [updateQueryParams, queryParams],
  )

  const handleSearchSubmit = (q: string) => {
    if (!q) return

    let query: ParsedUrlQueryInput = {
      q,
    }

    if (queryParams.sort) {
      query = {sortBy: queryParams.sort}
    }

    updateSearchHistory(q)

    router.push({
      pathname: '/leit',
      query,
    })
  }

  return {
    queryParams,
    searchHistory,
    setSorting,
    handleSearchSubmit,
  }
}
