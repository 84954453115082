import {useCallback} from 'react'

import {useRouter} from 'next/router'
import {toast} from 'react-toastify'

import {handleRestResponse, restApi} from '@festi/common/api/rest'
import {useAuth, useCheckout} from '@festi/common/contexts'


export default function useBusiness() {
  const {business, mutateBusiness, handleLoadingBusiness} = useAuth()
  const {refetchRestCheckout} = useCheckout()

  const router = useRouter()

  const handleBusinessSelect = useCallback(
    async (ssn?: string) => {
      handleLoadingBusiness(ssn)

      await handleRestResponse(
        restApi.usersMeBusinessUpdate({businessSsn: ssn}),
      )
        .then((res) => {
          mutateBusiness(res)
          refetchRestCheckout()

          router?.pathname?.includes('kaup') && router.push('/kaup')
        })
        .catch((err) => toast.error(`Eitthvað fór úrskeiðis ${err}`))
    },
    [router, mutateBusiness, refetchRestCheckout, handleLoadingBusiness],
  )

  return {business, handleBusinessSelect}
}
