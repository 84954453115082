/* eslint-disable react/display-name */
import React, {useEffect, useRef} from 'react'

import Link from 'next/link'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {BreadCrumb} from '@festi/common/utils/categories'
import {fluidRange} from '@festi/common/utils/styles'
import settings from '@festi/common/constants/settings'
import classNames from 'classnames'

const newDesign = settings.redesign

interface Props {
  breadCrumbs: BreadCrumb[]
  disableIndex?: number
}

type BreadCrumbProps = React.AnchorHTMLAttributes<HTMLAnchorElement>

const BreadCrumbItem = styled.a<BreadCrumbProps>`
  display: flex;
  align-items: center;
  ${fluidRange('font-size', '14px', '18px')}
  font-weight: 500;
  line-height: 1.1;
  color: ${palette('ui40Solid')};
  padding: 5px 8px 5px 0;
  transition: color 0.15s;

  .remixicon-icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 8px;
    color: ${palette('ui20Solid')};
  }

  &:first-child {
    .remixicon-icon {
      display: none;
    }
  }

  &:last-child {
    color: ${palette('blue')};
    pointer-events: none;
  }

  &:hover {
    color: ${palette('ui60Solid')};
  }

  ${media.md`
    padding: 8px 12px 8px 0;

    .remixicon-icon {
      margin-right: 12px;
    }
  `}

  &.newDesign {
    font-size: 14px;
    font-weight: 400;
    color: ${palette('blue')};
  }
`

const Bar = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  background-color: ${palette('white')};
  border-bottom: 1px solid ${palette('border')};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`

const NewBar = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  background-color: transparent;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`

const InnerBar = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;

  ${media.md`
    padding: 12px 0;
  `}
`

const BreadCrumbAnchor = React.forwardRef<HTMLAnchorElement, BreadCrumbProps>(
  ({children, ...props}: BreadCrumbProps, ref) => (
    <BreadCrumbItem {...props} ref={ref} className={classNames({newDesign})}>
      <ArrowRightSLineIcon />
      {children}
    </BreadCrumbItem>
  ),
)

export default function BreadCrumbsBar({
  breadCrumbs,
  disableIndex,
}: Props): JSX.Element {
  const containerRef = useRef(null)

  useEffect(() => {
    // Starts a new Breadcrumb all the way over to the right
    const container = containerRef.current
    if (container) {
      container.scrollLeft = container.scrollWidth
    }
  }, [breadCrumbs])

  const CrumbBar = newDesign ? NewBar : Bar

  if (!breadCrumbs?.length) return null

  return (
    <CrumbBar ref={containerRef}>
      <Container>
        <InnerBar>
          {breadCrumbs.map(({path, label}: BreadCrumb, index) => {
            if (index === disableIndex) {
              return <BreadCrumbAnchor key={path}>{label}</BreadCrumbAnchor>
            } else {
              return (
                <Link key={path} href={path} passHref legacyBehavior>
                  <BreadCrumbAnchor>{label}</BreadCrumbAnchor>
                </Link>
              )
            }
          })}
        </InnerBar>
      </Container>
    </CrumbBar>
  )
}
