import React from 'react'
import {useState} from 'react'

import cn from 'classnames'
import Link from 'next/link'
import ReactModal from 'react-modal'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import ListCheckIcon from 'remixicon-react/ListCheckIcon'
import SearchLineIcon from 'remixicon-react/SearchLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'

import {
  ruleContext,
  searchClient,
  searchIndexes,
  useAlgoliaSWR,
} from '@festi/common/api/algolia'
import {VariantList} from '@festi/common/api/rest'
import {ArrowAnchor} from '@festi/common/components/buttons'
import {EmptyResults} from '@festi/common/components/common'
import {useComparison} from '@festi/common/contexts'
import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'
import {formatPrice} from '@festi/utils/numbers'
import {getListingPrice} from '@festi/common/utils/price'

interface Props {
  isOpen?: boolean
  onClose?: () => void
}

interface CustomModalStyleProps {
  content?: React.CSSProperties
  overlay?: React.CSSProperties
}

const CustomModal = styled(ReactModal)`
  background-color: transparent;
  width: calc(100% - 32px);
  max-width: 800px;
  overflow: auto;
  outline: none;
  position: relative;
  top: 56px;
  margin-bottom: 56px;
  padding: 0;

  ${media.sm`
    ${fluidRange('top', '56px', '120px')}
  `}
`

const CloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 8px;

  ${media.md`
    margin-bottom: 12px;
  `}
`

const CloseBtn = styled.button`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: 0;
  background-color: ${palette('ui10SOlid')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
`

const SearchWrapper = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  margin-bottom: 8px;
  box-shadow: ${theme('boxShadow.card')};

  ${media.md`
    margin-bottom: 12px;
  `}
`

const SearchInput = styled.input`
  position: relative;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0;
  z-index: 0;
  vertical-align: center;
  padding: 0 56px;
  font-weight: 500;
  font-size: 1rem;
  color: ${palette('blue')};
  caret-color: ${palette('blue')};
  background-color: ${palette('white')};

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 1rem;
    color: ${palette('ui30Solid')};
    font-weight: 500;
  }
`

const SearchIcon = styled.div`
  position: absolute;
  left: 16px;
  top: calc(50% - 12px);
  z-index: 1;

  .remixicon-icon {
    height: 24px;
    width: 24px;
  }
`

const ResultsWrapper = styled.div`
  background-color: ${palette('white')};
  box-shadow: ${theme('boxShadow.card')};
  opacity: 0;
  transition: opacity 0.15s;

  &.visible {
    opacity: 1;
  }
`

const ResultsVariant = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${palette('ui10Solid')};
`

const ResultsVariantHead = styled.div`
  display: flex;
  align-items: center;
`

const ResultsVariantImg = styled.div`
  margin-right: 16px;
  width: 56px;
  height: 56px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

const ResultsVariantInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const ResultsVariantName = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('blue')};
  margin-bottom: 8px;
`

const ResultsVariantPrice = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${palette('ui50Solid')};
`

const ResultsVariantAddBtn = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('blue')};
  padding: 5px 16px;
  cursor: pointer;

  .remixicon-icon {
    margin-right: 5px;
  }
`

const SeeAllWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px;
`

const ResCount = styled.span`
  padding-left: 5px;
  color: ${palette('ui40Solid')};
`

const modalStyles: CustomModalStyleProps = {
  overlay: {
    position: 'fixed',
    overflowY: 'scroll',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    boxSizing: 'border-box',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: styledTheme.palette.ui60,
    zIndex: styledTheme.zIndex.modal,
    paddingBottom: 100,
  },
  content: {
    WebkitOverflowScrolling: 'touch',
  },
}

async function suggestionsFetcher(query: string) {
  const {
    results: [products],
  } = await searchClient.search<VariantList>([
    {
      indexName: `${searchIndexes.productVariants}_popular`,
      query,
      params: {
        hitsPerPage: 4,
        page: 0,
        attributesToRetrieve: [
          'id',
          'sku',
          'slug',
          'name',
          'lowestPrice',
          'firstImage.image',
          'listings',
        ],
        attributesToHighlight: ['name'],
        facets: ['categories'],
        ruleContexts: [ruleContext],
      },
    },
  ])
  return {products}
}

interface SearchResultsItemProps {
  variant?: VariantList
  inComparison?: boolean
  onToggle?: () => void
}

function SearchResultsItem({
  variant,
  inComparison,
  onToggle,
}: SearchResultsItemProps) {
  const {name, firstImage} = variant
  const lowestPrice = getListingPrice(variant)?.lowestPrice || 0

  const handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onToggle()
  }

  return (
    <ResultsVariant onClick={handleClick}>
      <ResultsVariantHead>
        <ResultsVariantImg
          style={{
            backgroundImage: `url('${firstImage?.image?.productList}')`,
          }}
        />
        <ResultsVariantInfo>
          <ResultsVariantName>{name}</ResultsVariantName>
          <ResultsVariantPrice>{formatPrice(lowestPrice)}</ResultsVariantPrice>
        </ResultsVariantInfo>
      </ResultsVariantHead>
      <ResultsVariantAddBtn onClick={handleClick}>
        {inComparison ? (
          <CloseLineIcon color={styledTheme.palette.error} size={24} />
        ) : (
          <AddLineIcon color={styledTheme.palette.green} size={24} />
        )}
        {inComparison ? 'Taka úr samanburði' : 'Bæta við'}
      </ResultsVariantAddBtn>
    </ResultsVariant>
  )
}

export default function ComparisonSearchModal({
  isOpen,
  onClose,
}: Props): JSX.Element {
  const {comparedSkus, addToComparison, removeFromComparison} = useComparison()

  const [searchValue, setSearchValue] = useState<string>('')
  const {data} = useAlgoliaSWR(searchValue, suggestionsFetcher)
  const searchResults = data?.products?.hits

  const totalHits = data?.products?.nbHits

  return (
    <CustomModal
      isOpen={isOpen}
      style={modalStyles}
      onAfterOpen={() => {
        document.body.style.overflow = 'hidden'
      }}
      onAfterClose={() => {
        document.body.style.overflow = 'unset'
      }}
      onRequestClose={onClose}
      closeTimeoutMS={200}
      ariaHideApp={false}
    >
      <CloseContainer>
        <CloseBtn onClick={onClose}>
          <CloseLineIcon size={20} color={styledTheme.palette.blue} />
        </CloseBtn>
      </CloseContainer>
      <SearchWrapper>
        <SearchInput
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          placeholder="Hvaða vöru viltu bæta við samanburðinn?"
          maxLength={240}
        />
        <SearchIcon>
          <SearchLineIcon color={styledTheme.palette.blue} />
        </SearchIcon>
      </SearchWrapper>
      <ResultsWrapper className={cn({visible: !!searchValue})}>
        {totalHits ? (
          <>
            {searchResults?.map((variant) => {
              const {sku} = variant
              const inComparison = comparedSkus?.indexOf(variant.sku) !== -1
              return (
                <SearchResultsItem
                  key={variant.id}
                  variant={variant}
                  inComparison={inComparison}
                  onToggle={
                    inComparison
                      ? () => removeFromComparison(sku)
                      : () => addToComparison(variant)
                  }
                />
              )
            })}
            <SeeAllWrapper onClick={onClose}>
              <Link href={`/leit?q=${searchValue}`} passHref legacyBehavior>
                <ArrowAnchor>
                  Sjá allar niðurstöður<ResCount>({totalHits})</ResCount>
                </ArrowAnchor>
              </Link>
            </SeeAllWrapper>
          </>
        ) : (
          <EmptyResults icon={ListCheckIcon}>Engar niðurstöður</EmptyResults>
        )}
      </ResultsWrapper>
    </CustomModal>
  )
}
