import {createContext, Dispatch, SetStateAction, useContext} from 'react'

import {Category} from '@festi/common/api/rest'

import {
  Webpage_allSpecialHoursPeriods,
  Webpage_openingHoursDutyfree,
  Webpage_openingHoursGeneral,
} from '../api/datocms/types/WebPage'
import {AllPageNavigations} from '../api/datocms/types/AllPageNavigations'

export interface CommonContextProps {
  categories: Category[]
  openingHours?: Webpage_openingHoursGeneral
  specialHours?: Webpage_allSpecialHoursPeriods[]
  openingHoursDutyfree?: Webpage_openingHoursDutyfree
  pageNavigation?: AllPageNavigations
  activeNavItem?: string | null
  setActiveNavItem: Dispatch<SetStateAction<string | null>>
}

export const CommonContext = createContext<Partial<CommonContextProps>>({})

export function useCommonContext() {
  return useContext(CommonContext) as CommonContextProps
}
