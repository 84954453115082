import cn from 'classnames'
import {useSpring, animated} from '@react-spring/web'
import styled from 'styled-components'
import {palette} from 'styled-tools'

const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  transition: height 0.2s ease-out;
`

const LoadingMsg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1;
  color: ${palette('ui60Solid')};
  transition: opacity 0.15s;
`

const Bubble = styled(animated.div)`
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${palette('ui60Solid')};

  &.large {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
`

interface BubbleProps {
  large?: boolean
  loading: boolean
}

export function LoadingBubble({large, loading}: BubbleProps) {
  const bubbleSpring = useSpring({
    from: {transform: 'scale(1)'},
    transform: loading ? 'scale(1.1)' : 'scale(1)',
    config: {mass: 1, tension: large ? 50 : 100, friction: 0},
    reset: true,
  })

  return <Bubble style={bubbleSpring} className={cn({large})} />
}

interface Props {
  msg?: React.ReactNode
  loading: boolean
  withBubble?: boolean
}

export default function AudkenniLoader({
  msg = 'Auðkenning send, kíktu á símann þinn.',
  loading,
  withBubble,
}: Props): JSX.Element {
  return (
    <LoadingWrapper style={{height: loading ? 56 : 0}}>
      <LoadingMsg style={{opacity: loading ? 1 : 0}}>
        {withBubble && <LoadingBubble loading={loading} />}
        {msg}
      </LoadingMsg>
    </LoadingWrapper>
  )
}
