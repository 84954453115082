import {useCallback, useEffect, useState} from 'react'

import {
  DocumentNode,
  TypedDocumentNode,
  OperationVariables,
} from '@apollo/client'

import {client} from '../api/datocms'

export default function useDatoCMS<TData = unknown, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: TVariables
) {
  const [data, setData] = useState<TData>()

  const getData = useCallback(async () => {
    const {data: newData} = await client().query({query, ...options})

    setData(newData)
  }, [query, options])

  useEffect(() => {
    if (!data) {
      getData()
    }
  }, [data, getData])

  return {data}
}
