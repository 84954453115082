import HeartLineIcon from 'remixicon-react/HeartLineIcon'
import ShoppingCartLineIcon from 'remixicon-react/ShoppingCartLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'
import {useUserPrices} from '@festi/common/utils/rest'
import {useWishlist} from '@festi/common/utils/wishlists'

import {AddToCartButton} from '../cart'
import {DotsLoader, EmptyResults} from '../common'
import {SideDrawer} from '../layout'
import WishlistItemCard from './WishlistItemCard'

interface Props {
  isOpen?: boolean
  onRequestClose?: () => void
}

const ContentWrapper = styled.div`
  padding: 24px 16px;
`

const WishlistWrapper = styled.div``

const WishlistHeadWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
`

const WishlistHead = styled.div`
  display: flex;
  font-weight: 500;

  svg {
    margin-right: 10px;
  }
`

const WishlistCount = styled.div`
  font-size: 1rem;
  color: ${palette('ui40Solid')};
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 16px;
`

const WishlistProducts = styled.div`
  margin-bottom: 16px;
`

const WishlistButtons = styled.div``

const Spacer = styled.div`
  height: 8px;
`

export default function WishlistDrawer({
  isOpen,
  onRequestClose,
}: Props): JSX.Element {
  const {wishlistItems, isValidating} = useWishlist()

  const {data: userPrices, loading: userPriceLoading} = useUserPrices(
    wishlistItems?.map((w) => w.sku),
  )

  const len = wishlistItems?.length
  const loading = !wishlistItems && isValidating

  return (
    <SideDrawer
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      noFocus={loading || !len}
    >
      <ContentWrapper>
        <WishlistWrapper>
          <WishlistHeadWrapper>
            <WishlistHead>
              <HeartLineIcon size={18} color={styledTheme.palette.pink} />
              <span>Óskalisti</span>
            </WishlistHead>
          </WishlistHeadWrapper>
          {loading ? (
            <DotsLoader />
          ) : !len ? (
            <EmptyResults icon={HeartLineIcon}>
              <span data-cy="wishlist-empty">Listinn er tómur</span>
            </EmptyResults>
          ) : (
            <>
              <WishlistCount>
                {len} <span>{len === 1 ? 'vara' : 'vörur'}</span>
                <span> í lista</span>
              </WishlistCount>
              {wishlistItems?.map((item) => (
                <WishlistProducts key={item.sku}>
                  <WishlistItemCard
                    item={item}
                    userPrice={userPrices?.[item.sku]}
                    userPriceLoading={userPriceLoading}
                    onClick={onRequestClose}
                  />
                </WishlistProducts>
              ))}
              <WishlistButtons>
                {/* <OutlinedButton icon={ShareLineIcon}>Deila óskalista</OutlinedButton> */}
                <Spacer />
                <AddToCartButton
                  icon={ShoppingCartLineIcon}
                  buttonText={'Bæta öllu við í körfu'}
                  variants={wishlistItems?.map((wi) => wi.variant) || []}
                />
              </WishlistButtons>
            </>
          )}
        </WishlistWrapper>
      </ContentWrapper>
    </SideDrawer>
  )
}
