import {useRef, useLayoutEffect} from 'react'

import {useRouter} from 'next/router'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  border-top: 4px solid ${palette('pink')};

  ${media.md`
    z-index: initial;
  `}
`

const Label = styled.span`
  position: absolute;
  top: -8px;
  left: 16px;
  font-family: 'ElkjopHeadline', sans-serif;
  font-size: 1rem;
  color: ${palette('blue')};
  text-transform: uppercase;
  z-index: 1;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -8px;
    right: -8px;
    background-color: ${palette('pink')};
    transform: rotate(-4deg);
    z-index: -1;
  }

  ${media.md`
    font-size: 1.25rem;
    left: 80px;
  `}
`

const ChangeSiteBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette('lightPink')};
  border-bottom: 1px solid ${palette('pink')};
  transition: opacity 0.25s ease-out;
`

const ChangeSiteText = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${palette('blue')};
  padding: 5px 24px 5px 88px;
  line-height: 1.3;

  ${media.md`
    padding: 12px 0;
    font-size: 1rem;
  `}
`

const ChangeSiteAnchor = styled.a`
  position: relative;
  margin: 0 5px;
  color: ${palette('lightBlue')};
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${palette('lightBlue')};
  }
`

interface Props {
  categoriesOpen?: boolean
  setPageTopMargin: (height?: number) => void
}

export default function DutyFreeBanner({
  categoriesOpen,
  setPageTopMargin,
}: Props): JSX.Element {
  const {pathname} = useRouter()

  const ref = useRef<HTMLDivElement>(null)
  const height = ref?.current?.getBoundingClientRect()?.height || 33

  const onFrontPage = pathname === '/'

  useLayoutEffect(() => {
    setPageTopMargin(height)
  }, [height, setPageTopMargin])

  return (
    <Wrapper ref={ref}>
      <Label>Duty Free</Label>
      {onFrontPage && (
        <ChangeSiteBanner style={{opacity: categoriesOpen ? 0 : 1}}>
          {/* TODO: What happens when you click 'hér'? */}
          <ChangeSiteText>
            Þú ert að skoða ELKO Duty Free á Keflavíkurflugvelli. Smelltu
            <ChangeSiteAnchor href="https://elko.is">hér</ChangeSiteAnchor>til
            að fara á aðalvef.
          </ChangeSiteText>
        </ChangeSiteBanner>
      )}
    </Wrapper>
  )
}
