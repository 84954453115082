import {useCallback, useEffect} from 'react'

import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import styled from 'styled-components'

import BusinessCard from '@festi/common/components/account/BusinessCard'
import {ActionButton} from '@festi/common/components/buttons'
import {SideDrawer} from '@festi/common/components/layout'
import {H4, P} from '@festi/common/components/typography'
import {useAuth} from '@festi/common/contexts'
import {
  showBusinessDrawer,
  businessDrawerLocalStorgeShow,
} from '@festi/common/utils/localstorage'
import {fluidRange} from '@festi/common/utils/styles'
import useBusiness from '@festi/common/utils/useBusiness'
import {formatBusinessName, formatSSN} from '@festi/utils/strings'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: -webkit-fill-available;
  ${fluidRange('padding-top', '12px', '48px', 'xs', 'md')};
  ${fluidRange('padding-bottom', '12px', '48px', 'xs', 'md')};
  ${fluidRange('padding-left', '16px', '56px', 'xs', 'md')};
  ${fluidRange('padding-right', '16px', '56px', 'xs', 'md')};
`

const AuthWrapper = styled.div`
  display: grid;
  gap: 16px;
`

interface Props {
  isOpen?: boolean
  onUserLogin?: () => void
  onRequestClose?: () => void
  onReturnToUser?: () => void
}

export default function BusinessDrawer({
  isOpen,
  onUserLogin,
  onRequestClose,
  onReturnToUser,
}: Props) {
  const {user, business, businesses, selectedBusiness, missingAttributes} =
    useAuth()
  const {handleBusinessSelect} = useBusiness()

  const handleAuthItemSelect = useCallback(
    (ssn?: string) => {
      handleBusinessSelect(ssn)
      !!onRequestClose && onRequestClose()
    },
    [onRequestClose, handleBusinessSelect],
  )

  useEffect(() => {
    const showDrawer =
      !missingAttributes &&
      !!businesses?.results?.length &&
      showBusinessDrawer()

    if (showDrawer) {
      !!onUserLogin && onUserLogin()
      businessDrawerLocalStorgeShow(false)
    }
  }, [
    businesses,
    missingAttributes,
    business?.id,
    business?.invoicingAllowed,
    onUserLogin,
  ])

  return (
    <SideDrawer isOpen={isOpen} onRequestClose={onRequestClose} noFocus>
      <ContentWrapper>
        {!!onReturnToUser && (
          <ActionButton icon={ArrowRightSLineIcon} onClick={onReturnToUser}>
            Til baka
          </ActionButton>
        )}

        <H4 withGutter>Veldu aðgang</H4>

        <P withGutter>
          Hér getur þú valið hvaða aðgang þú ætlar að skrá þig inn á. Þú getur
          alltaf skipt um aðgang þegar þér hentar.
        </P>

        <AuthWrapper>
          <BusinessCard
            ssn={formatSSN(user?.ssn)}
            name={user?.name}
            selected={!selectedBusiness}
            onClick={() => handleAuthItemSelect()}
          />

          {businesses?.results
            ?.sort((a, b) => a.id - b.id)
            ?.map((b) => (
              <BusinessCard
                key={b.id}
                ssn={formatSSN(b.ssn)}
                name={formatBusinessName(b.name, b.ssn, true)}
                selected={selectedBusiness === b?.ssn}
                onClick={() => handleAuthItemSelect(b.ssn)}
              />
            ))}
        </AuthWrapper>
      </ContentWrapper>
    </SideDrawer>
  )
}
