import React from 'react'

import {Col, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {useAuth} from '@festi/common/contexts'
import {removeSpaces} from '@festi/utils/strings'

import {Checkbox, CleaveInput, FormikCleaveInput} from '../form'

export interface CreditCardFormValues {
  cardNumber: string
  expDate: string
  cvc: string
  store?: boolean
}

export interface CreditCardFormErrorProps {
  cardNumber?: string
  expDate?: string
  cvc?: string
}

interface CreditCardFormFieldsProps {
  isFormik?: boolean
  errors?: CreditCardFormErrorProps
  values?: CreditCardFormValues
  onChange?: (e: React.BaseSyntheticEvent) => void
  isChecked?: boolean
}

const CheckboxWrapper = styled.div`
  margin-top: 32px;
`

export function validateCreditCardFormFields({
  cardNumber,
  expDate,
  cvc,
}: CreditCardFormValues): CreditCardFormErrorProps {
  const errors: CreditCardFormErrorProps = {}

  if (!cardNumber) {
    errors.cardNumber = 'Hér vantar kortanúmer'
  } else if (
    removeSpaces(cardNumber).length < 13 ||
    removeSpaces(cardNumber).length > 19
  ) {
    errors.cardNumber = 'Kortanúmer þarf að vera á milli 13 og 19 tölustafir'
  }

  if (!expDate) {
    errors.expDate = 'Hér vantar gildistíma'
  } else if (expDate.length < 5) {
    errors.expDate = 'Gildistími þarf að vera á forminu MM/ÁÁ'
  }

  if (!cvc) {
    errors.cvc = 'Hér vantar CVC'
  } else if (cvc.length < 3) {
    errors.cvc = 'CVC þarf að vera 3 tölustafir'
  }

  return errors
}

export default function ValitorPayCreditCardFormFields({
  isFormik,
  errors,
  values,
  onChange,
}: CreditCardFormFieldsProps): JSX.Element {
  const {user} = useAuth()

  const Input = isFormik ? FormikCleaveInput : CleaveInput
  const props = isFormik
    ? {}
    : {
        cardNumber: {
          value: values?.cardNumber,
          onChange,
          errorMsg: errors?.cardNumber,
        },
        expDate: {
          value: values?.expDate,
          onChange,
          errorMsg: errors?.expDate,
        },
        cvc: {
          value: values?.cvc,
          onChange,
          errorMsg: errors?.cvc,
        },
        store: {
          defaultChecked: values?.store,
          onClick: onChange,
        },
      }

  return (
    <Row>
      <Col col={12}>
        <Input
          name="cardNumber"
          placeholder="Kortanúmer"
          options={{creditCard: true, creditCardStrictMode: true}}
          {...props.cardNumber}
        />
      </Col>
      <Col col={12} md={6}>
        <Input
          name="expDate"
          placeholder="Gildistími (MM/ÁÁ)"
          maxLength={5}
          options={{
            date: true,
            datePattern: ['m', 'y'],
          }}
          {...props.expDate}
        />
      </Col>
      <Col col={12} md={6}>
        <Input
          name="cvc"
          placeholder="CVC"
          maxLength={3}
          options={{numericOnly: true, numeralPositiveOnly: true}}
          {...props.cvc}
        />
      </Col>
      {user && (
        <Col col={12} md={8}>
          <CheckboxWrapper>
            <Checkbox
              type="checkbox"
              name="store"
              withGutter
              label="Vista kortaupplýsingar"
              {...props.store}
            />
          </CheckboxWrapper>
        </Col>
      )}
    </Row>
  )
}
