import React, {useCallback, useEffect, useRef, useState} from 'react'

import cn from 'classnames'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import Image from 'next/image'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import FlightTakeoffFillIcon from 'remixicon-react/FlightTakeoffFillIcon'
import HeartLineIcon from 'remixicon-react/HeartLineIcon'
import MenuLineIcon from 'remixicon-react/MenuLineIcon'
import ShoppingBagLineIcon from 'remixicon-react/ShoppingBagLineIcon'
import UserLineIcon from 'remixicon-react/UserLineIcon'
import InformationLineIcon from 'remixicon-react/InformationLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {ifProp, palette, theme} from 'styled-tools'

import {useAuth, useCheckout} from '../../contexts'
import {LogoMobile, LogoDesktop} from '../../images'
import {styledTheme} from '../../themes'
import DutyFreeBanner from './DutyFreeBanner'
import settings from '../../constants/settings'

const UserNav = dynamic(() => import('./UserNav'), {ssr: false})
const SearchBar = dynamic(() => import('../search/SearchBar'), {ssr: false})

interface NavBtnProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  accentColor: 'green' | 'lightBlue' | 'pink' | 'purple'
  prefixWidth?: number
}

const Hidden = styled.div`
  position: absolute;
  visibility: hidden;
  pointer-events: none;
`

const Wrapper = styled.div`
  position: relative;
  z-index: ${theme('zIndex.header')};
  pointer-events: none;
`

const FillerBar = styled.div`
  position: relative;
  width: 100%;
  height: calc(${theme('navBarHeight.small')} + 56px);

  @media (min-width: ${styledTheme.screenSize.sizeMD}) {
    height: ${theme('navBarHeight.large')};
  }
`

const NavBarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  pointer-events: all;
`

const NavBar = styled.nav`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: ${theme('navBarHeight.small')};
  z-index: 1;
  background-color: ${palette('blue')};
  border-bottom: 1px solid ${palette('border')};

  /* Using regular media since Typescript gives weird error when using media.md here, since we are using the 'prop' function */
  @media (min-width: ${styledTheme.screenSize.sizeMD}) {
    height: ${theme('navBarHeight.large')};
  }
`

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 16px;
  border-right: 1px solid ${palette('ui80Solid')};

  ${media.md`
    padding: 0 32px;
  `}
`

const MobileLogo = styled(Image)`
  display: block;
  width: auto;
  height: 32px;

  ${media.md`
    display: none;
  `}
`

const DesktopLogo = styled(Image)`
  display: none;
  width: auto;
  height: 40px;

  ${media.md`
    display: block;
  `}
`

const CategoriesBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 0;
  padding: 0 16px;
  background-color: transparent;
  cursor: pointer;

  .remixicon-icon {
    transition: transform 0.15s ease-out;
    margin-right: 8px;
  }

  ${media.sm`
    padding: 0 32px;

    .remixicon-icon {
      margin-right: 16px;
    }
  `}

  &:active {
    .remixicon-icon {
      transform: scale(0.8);
    }
  }
`

const CategoriesBtnLabel = styled.span`
  display: inline;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  color: ${palette('white')};

  @media screen and (max-width: 430px) {
    display: none;
  }

  ${media.sm`
    display: inline;
    font-size: 1.1rem;
  `}

  ${media.md`
    display: inline;
    font-size: 1.25rem;
  `}
`

const Navigation = styled.div`
  display: flex;
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.md`
    flex-grow: 1;
    max-width: 60%;
  `}
`

interface SearchBarContainerProps {
  mobile?: boolean
  pageTopMargin?: number
}

const SearchBarContainer = styled.div<SearchBarContainerProps>`
  position: relative;
  display: ${ifProp({mobile: true}, 'block', 'none')};
  flex-grow: 1;
  z-index: 0;
  transition: margin 0.15s ease-out;

  &.mobileHidden {
    margin-top: ${(p) =>
      p.pageTopMargin ? `-${p.pageTopMargin + 56}px` : '-56px'};
  }

  ${media.md`
    display: ${ifProp({mobile: true}, 'none', 'block')};

    &.mobileHidden {
      margin-top: 0;
    }
  `}
`

const NavAnchorPrefix = styled.span`
  display: none;
  width: 0;
  font-size: 1.25rem;
  font-weight: 500;
  color: ${palette('blue')};
  line-height: 1.2;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  transition: width 0.15s ease-out;

  ${media.md`
    display: inline;
  `}
`

const CartCount = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 49%;
  right: 18px;
  width: 20px;
  height: 16px;
  font-size: 0.7rem;
  font-weight: 700;
  color: ${palette('blue')};
  transition: transform 0.15s ease-out;

  &.onDutyFree {
    right: 4px;
  }

  ${media.md`
    font-size: 0.8rem;
    top: calc(50% - 2px);
    right: 22px;

    &.onDutyFree {
      right: 4px;
    }
  `}
`

export const NavBtn = styled.button<NavBtnProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;
  padding: 0 14px;
  margin: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background-color: ${(p) => palette(p.accentColor)};

  .remixicon-icon {
    width: 28px;
    height: 28px;
    color: ${palette('blue')};
    transition: transform 0.15s ease-out;
  }

  &.activePrefix {
    /* NavAnchorPrefix */
    & > span {
      width: unset;
      padding-right: 12px;
    }
  }

  &:hover&:not(:disabled) {
    cursor: pointer;

    ${media.sm`
      .remixicon-icon {
        transform: scale(0.8);
      }
    `}

    ${CartCount} {
      transform: scale(0.8);
      top: calc(50% - 3px);
    }
  }

  /* Using regular media since Typescript gives weird error when using media.md here, since we are using the 'prop' function */
  @media (min-width: ${styledTheme.screenSize.sizeMD}) {
    padding: 0 16px;

    .remixicon-icon {
      height: 32px;
      width: 32px;
    }

    &:hover&:not(:disabled) {
      /* NavAnchorPrefix */
      & > span {
        width: ${(p) => `${p.prefixWidth}px`};
      }
    }

    &.activePrefix {
      &:hover&:not(:disabled) {
        /* NavAnchorPrefix */
        & > span {
          width: unset;
        }
      }
    }
  }
`

interface Props {
  path: string
  onWeb: boolean
  authOpen?: boolean
  cartOpen?: boolean
  infoOpen?: boolean
  onDutyFree: boolean
  wishlistOpen?: boolean
  pageTopMargin: number
  categoriesOpen?: boolean
  hideMobileSearch?: boolean
  hideSearchAndCategories?: boolean
  onSearch: (searchValue: string) => void
  onClickCart?: () => void
  onClickLogin?: () => void
  closeDrawers: () => void
  onClickWishlist?: () => void
  onClickViewUser?: () => void
  onClickCategories?: () => void
  onClickInformation?: () => void
  setPageTopMargin: (height?: number) => void
}

export default function Header({
  path,
  onWeb,
  authOpen,
  cartOpen,
  infoOpen,
  onDutyFree,
  wishlistOpen,
  pageTopMargin,
  categoriesOpen,
  hideMobileSearch = false,
  onSearch,
  onClickCart,
  onClickLogin,
  closeDrawers,
  onClickWishlist,
  onClickViewUser,
  onClickCategories,
  onClickInformation,
  setPageTopMargin,
}: Props): JSX.Element {
  const {user} = useAuth()
  const {restCheckout} = useCheckout()

  const userPrefixRef: React.RefObject<HTMLDivElement> = useRef(null)
  const cartPrefixRef: React.RefObject<HTMLDivElement> = useRef(null)
  const infoPrefixRef: React.RefObject<HTMLDivElement> = useRef(null)
  const wishlistPrefixRef: React.RefObject<HTMLDivElement> = useRef(null)
  const [userPrefixWidth, setUserPrefixWidth] = useState<number>(0)
  const [cartPrefixWidth, setCartPrefixWidth] = useState<number>(0)
  const [infoPrefixWidth, setInfoPrefixWidth] = useState<number>(0)
  const [wishlistPrefixWidth, setWishlistPrefixWidth] = useState<number>(0)

  const prevScrollYRef = useRef<number>(0)
  const [showSearch, setShowSearch] = useState<boolean>(true)

  const handleScroll = useCallback(() => {
    const prevScrollY = prevScrollYRef.current
    const currentScrollPos = window.pageYOffset

    const shouldShow = prevScrollY >= currentScrollPos || currentScrollPos < 60

    if (shouldShow !== showSearch) {
      setShowSearch(shouldShow)
    }

    prevScrollYRef.current = currentScrollPos
  }, [showSearch])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  function loadWidths(): void {
    setUserPrefixWidth((userPrefixRef?.current?.offsetWidth || 0) + 10)
    setCartPrefixWidth((cartPrefixRef?.current?.offsetWidth || 0) + 10)
    setInfoPrefixWidth((infoPrefixRef?.current?.offsetWidth || 0) + 10)
    setWishlistPrefixWidth((wishlistPrefixRef?.current?.offsetWidth || 0) + 10)
  }

  useEffect(() => {
    loadWidths()
  }, [])

  const searchBar = (
    <SearchBar
      onSearch={onSearch}
      path={path}
      placeholder="Ég er að leita að..."
      closeDrawers={closeDrawers}
    />
  )

  return (
    <Wrapper>
      {/* Used to calculate width of anchors for animation */}
      {/* These are rendered in the DOM to help us get the size of which we need to animate to on hover */}
      <Hidden>
        <NavAnchorPrefix ref={userPrefixRef}>Innskráning</NavAnchorPrefix>
        <NavAnchorPrefix ref={cartPrefixRef}>Karfa</NavAnchorPrefix>
        <NavAnchorPrefix ref={infoPrefixRef}>Upplýsingar</NavAnchorPrefix>
        <NavAnchorPrefix ref={wishlistPrefixRef}>Óskalisti</NavAnchorPrefix>
      </Hidden>

      {/* Used to fill height behind header since its position is fixed */}

      <FillerBar />

      <NavBarWrapper>
        <NavBar>
          <Navigation>
            <LogoLink href="/">
              <MobileLogo src={LogoMobile} alt="Elko" />
              <DesktopLogo src={LogoDesktop} alt="Elko" />
            </LogoLink>

            <CategoriesBtn
              onClick={onClickCategories}
              aria-haspopup="true"
              aria-expanded={categoriesOpen}
            >
              {categoriesOpen ? (
                <CloseLineIcon size={24} color={styledTheme.palette.white} />
              ) : (
                <MenuLineIcon size={24} color={styledTheme.palette.white} />
              )}

              <CategoriesBtnLabel>
                {categoriesOpen ? 'Loka valmynd' : 'Vöruflokkar'}
              </CategoriesBtnLabel>
            </CategoriesBtn>
          </Navigation>

          <Actions>
            <SearchBarContainer>{searchBar}</SearchBarContainer>

            {settings.ffPageNav && (
              <NavBtn
                data-cy="header-info"
                aria-label="Upplýsingar"
                accentColor="purple"
                prefixWidth={infoPrefixWidth}
                className={classNames({activePrefix: infoOpen})}
                onClick={onClickInformation}
              >
                <NavAnchorPrefix>Upplýsingar</NavAnchorPrefix>
                {infoOpen ? <CloseLineIcon /> : <InformationLineIcon />}
              </NavBtn>
            )}

            {user && <UserNav onClickViewUser={onClickViewUser} />}

            {!user && onClickLogin && (
              <NavBtn
                data-cy="header-login"
                className={classNames({activePrefix: authOpen})}
                accentColor="lightBlue"
                prefixWidth={userPrefixWidth}
                onClick={onClickLogin}
                aria-label="Login"
              >
                <NavAnchorPrefix>Innskráning</NavAnchorPrefix>
                {authOpen ? <CloseLineIcon /> : <UserLineIcon />}
              </NavBtn>
            )}

            {!!user && (
              <NavBtn
                data-cy="header-wishlist"
                className={classNames({activePrefix: wishlistOpen})}
                accentColor="pink"
                prefixWidth={wishlistPrefixWidth}
                onClick={onClickWishlist}
                aria-label="Óskalisti"
              >
                <NavAnchorPrefix>Óskalisti</NavAnchorPrefix>
                {wishlistOpen ? <CloseLineIcon /> : <HeartLineIcon />}
              </NavBtn>
            )}

            {!!onClickCart && (
              <NavBtn
                data-cy="header-cart"
                type="button"
                className={cartOpen ? 'activePrefix' : ''}
                accentColor="green"
                prefixWidth={cartPrefixWidth}
                onClick={onClickCart}
                tabIndex={0}
              >
                <NavAnchorPrefix>Karfa</NavAnchorPrefix>
                {cartOpen ? (
                  <CloseLineIcon />
                ) : (
                  <>
                    {onDutyFree ? (
                      <FlightTakeoffFillIcon />
                    ) : (
                      <ShoppingBagLineIcon />
                    )}
                    {!!restCheckout && (
                      <CartCount className={cn({onDutyFree})}>
                        {restCheckout.quantity}
                      </CartCount>
                    )}
                  </>
                )}
              </NavBtn>
            )}
          </Actions>
        </NavBar>

        {onDutyFree && onWeb && (
          <DutyFreeBanner
            categoriesOpen={categoriesOpen}
            setPageTopMargin={setPageTopMargin}
          />
        )}

        {onWeb && (
          <SearchBarContainer
            mobile
            pageTopMargin={pageTopMargin}
            className={classNames({
              mobileHidden: !showSearch || hideMobileSearch,
            })}
          >
            {searchBar}
          </SearchBarContainer>
        )}
      </NavBarWrapper>
    </Wrapper>
  )
}
