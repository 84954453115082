import {Dispatch, SetStateAction} from 'react'

import Link from 'next/link'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import SubtractLineIcon from 'remixicon-react/SubtractLineIcon'

import {styledTheme} from '@festi/common/themes'
import {H4, H5} from '@festi/common/components/typography'
import {useCommonContext} from '@festi/common/contexts/common'
import {DynamicIcon, Expandable} from '@festi/common/components/common'
import {AllPageNavigations_allPageNavigations} from '@festi/common/api/datocms/types/AllPageNavigations'

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`

const BorderBottom = styled.div`
  border-bottom: 1px solid ${palette('ui10Solid')};
`

const LinkBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
`

const NoLink = styled.div`
  width: 100%;
  cursor: pointer;
`

const MainLinkPadding = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 20px 23px 0;

  .remixicon-icon {
    margin-right: 8px;
  }
`

const ExpandIcon = styled.div`
  padding: 23px 0 23px 20px;
  cursor: pointer;
`

const SublinkBox = styled.div`
  padding: 0 20px 20px;
`

const Sublink = styled.div`
  position: relative;
  text-decoration: elepsis;
  padding: 10px 0;

  &:before {
    content: '• ';
    position: absolute;
    top: 11px;
    left: -20px;
    color: ${palette('ui10Solid')};
  }
`

interface LinkProps {
  page: AllPageNavigations_allPageNavigations
  active: boolean
  setActive: Dispatch<SetStateAction<string | null>>
  onRequestClose?: () => void
}

function MainLink({page, active, setActive, onRequestClose}: LinkProps) {
  const stuff = (
    <MainLinkPadding>
      {page?.icon && <DynamicIcon icon={page.icon} size={22} />}
      <H4>{page.heading}</H4>
    </MainLinkPadding>
  )

  return (
    <LinkBox>
      {page.pageLink?.path ? (
        <Link href={page.pageLink.path} onClick={onRequestClose}>
          {stuff}
        </Link>
      ) : (
        <NoLink onClick={() => setActive(active ? null : page.id)}>
          {stuff}
        </NoLink>
      )}

      {!!page.children?.length &&
        (active ? (
          <ExpandIcon onClick={() => setActive(null)}>
            <SubtractLineIcon size={24} color={styledTheme.palette.green} />
          </ExpandIcon>
        ) : (
          <ExpandIcon onClick={() => setActive(page.id)}>
            <AddLineIcon size={24} color={styledTheme.palette.blue} />
          </ExpandIcon>
        ))}
    </LinkBox>
  )
}

interface Props {
  onRequestClose?: () => void
}

export default function PageNavigation({onRequestClose}: Props): JSX.Element {
  const {activeNavItem, pageNavigation, setActiveNavItem} = useCommonContext()

  return (
    <InfoWrapper>
      {pageNavigation?.allPageNavigations?.map((page) => (
        <BorderBottom key={page.id}>
          <MainLink
            page={page}
            active={activeNavItem === page.id}
            setActive={setActiveNavItem}
            onRequestClose={onRequestClose}
          />

          <Expandable expanded={activeNavItem === page.id}>
            <SublinkBox>
              {page?.children?.map((child) => (
                <Sublink key={child?.id} onClick={onRequestClose}>
                  <Link href={child?.pageLink?.path || '/'}>
                    <H5>{child?.heading}</H5>
                  </Link>
                </Sublink>
              ))}
            </SublinkBox>
          </Expandable>
        </BorderBottom>
      ))}
    </InfoWrapper>
  )
}
