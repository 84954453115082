import {useCallback} from 'react'

import {Auth} from '@aws-amplify/auth'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {RemixiconReactIconComponentType} from 'remixicon-react'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon'
import FileList3LineIcon from 'remixicon-react/FileList3LineIcon'
import ShutDownLineIcon from 'remixicon-react/ShutDownLineIcon'
import UserLineIcon from 'remixicon-react/UserLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {Business} from '@festi/common/api/rest'
import BusinessCard from '@festi/common/components/account/BusinessCard'
import {ActionButton, OutlinedButton} from '@festi/common/components/buttons'
import {SideDrawer} from '@festi/common/components/layout'
import {H4} from '@festi/common/components/typography'
import {useAuth} from '@festi/common/contexts'
import {fluidRange} from '@festi/common/utils/styles'
import useBusiness from '@festi/common/utils/useBusiness'
import {formatBusinessName, formatSSN} from '@festi/utils/strings'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: -webkit-fill-available;
  ${fluidRange('padding-top', '12px', '48px', 'xs', 'md')};
  ${fluidRange('padding-bottom', '12px', '48px', 'xs', 'md')};
  ${fluidRange('padding-left', '16px', '56px', 'xs', 'md')};
  ${fluidRange('padding-right', '16px', '56px', 'xs', 'md')};
`

const AuthContainer = styled.div`
  margin-bottom: 24px;
  border-bottom: 1px solid ${palette('border')};
`

const AuthWrapper = styled.div`
  display: grid;
  gap: 16px;
`

const NavWrapper = styled.div`
  display: grid;
  gap: 32px;
  padding-bottom: 32px;
  margin-top: 23px;
`

const LogoutWrapper = styled.div`
  margin-top: auto;
`

export interface NavItem {
  icon: RemixiconReactIconComponentType
  label: string
  pagePath: string
}

const openNavItems: NavItem[] = [
  {
    icon: FileList3LineIcon,
    label: 'Nótur',
    pagePath: '/minar',
  },
  {
    icon: UserLineIcon,
    label: 'Mínar upplýsingar',
    pagePath: '/minar/upplysingar',
  },
]

const hiddenNavItem = {
  icon: BuildingLineIcon,
  label: 'Fyrirtæki',
  pagePath: '/minar/fyrirtaeki',
}

export const navItems = [...openNavItems, hiddenNavItem]

const sortBusinesses = (a: Business, b: Business, id: number) => {
  if ((a.id || b.id) === id) return -1
  return 0
}

interface Props {
  isOpen?: boolean
  onWeb?: boolean
  onRequestClose?: () => void
  onShowAllBusiness?: () => void
}

export default function UserDrawer({
  isOpen,
  onRequestClose,
  onShowAllBusiness,
}: Props) {
  const {user, business, businesses, selectedBusiness} = useAuth()
  const {handleBusinessSelect} = useBusiness()

  const {reload} = useRouter()

  const handleAuthItemSelect = useCallback(
    (ssn?: string) => {
      handleBusinessSelect(ssn)
      !!onRequestClose && onRequestClose()
    },
    [onRequestClose, handleBusinessSelect],
  )

  const handleLogout = useCallback(() => {
    Auth.signOut()
    localStorage.removeItem('backdoorToken')
    reload()
    !!onRequestClose && onRequestClose()
  }, [reload, onRequestClose])

  const handleShowAllBusinesses = useCallback(() => {
    !!onShowAllBusiness && onShowAllBusiness()
  }, [onShowAllBusiness])

  return (
    <SideDrawer isOpen={isOpen} onRequestClose={onRequestClose} noFocus={true}>
      <ContentWrapper>
        {!!businesses?.results?.length && (
          <AuthContainer>
            <H4 withGutter>Aðrir aðgangar</H4>

            <AuthWrapper>
              <BusinessCard
                ssn={formatSSN(user?.ssn)}
                name={user?.name}
                selected={!selectedBusiness}
                onClick={() => handleAuthItemSelect()}
              />

              {businesses?.results
                ?.sort((a, b) => sortBusinesses(a, b, business?.id || 0))
                ?.slice(0, 2)
                ?.map((b) => (
                  <BusinessCard
                    key={b.id}
                    ssn={formatSSN(b.ssn)}
                    name={formatBusinessName(b.name, b.ssn, true)}
                    selected={selectedBusiness === b?.ssn}
                    onClick={() => handleAuthItemSelect(b.ssn)}
                  />
                ))}
            </AuthWrapper>

            {businesses?.results?.length > 2 && (
              <ActionButton
                icon={ArrowRightSLineIcon}
                onClick={handleShowAllBusinesses}
                center
              >
                Sjá alla aðganga
              </ActionButton>
            )}
          </AuthContainer>
        )}

        <NavWrapper>
          {navItems?.map((nav, index) => (
            <Link key={index} href={nav.pagePath} onClick={onRequestClose}>
              <H4>{nav.label}</H4>
            </Link>
          ))}
        </NavWrapper>

        <LogoutWrapper>
          <OutlinedButton
            icon={ShutDownLineIcon}
            borderColor="pink"
            onClick={handleLogout}
          >
            Útskráning
          </OutlinedButton>
        </LogoutWrapper>
      </ContentWrapper>
    </SideDrawer>
  )
}
