import React from 'react'

import {useField, FieldConfig} from 'formik'
import CheckLineIcon from 'remixicon-react/CheckLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'

import {checkButtonStyles} from '../buttons/buttonStyles'
import ErrorMsg from './ErrorMsg'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  center?: boolean
  errorMsg?: string
  suffix?: React.ReactNode
}

const Label = styled.label`
  ${checkButtonStyles}
`

const Text = styled.div`
  font-weight: 500;
  font-size: 1rem;
  color: ${palette('blue')};
  line-height: 1;
  margin-left: 12px;
  flex-grow: 1;
  text-align: center;
  padding: 0 24px;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border: 1px solid ${palette('lightBlue')};
  margin-right: 24px;
  background-color: ${palette('white')};
  transition: background-color 0.15s;
`

const CheckboxInput = styled.input`
  position: absolute;
  border-radius: 0;
  opacity: 0;
  pointer-events: none;

  &:checked + ${Box} {
    background-color: ${palette('lightBlue')};
    border-color: ${palette('lightBlue')};
  }
`

export default function CheckboxBtn({
  label,
  errorMsg,
  ...props
}: Props): JSX.Element {
  return (
    <div>
      <Label>
        <Text>{label}</Text>
        <CheckboxInput type="checkbox" {...props} />
        <Box>
          <CheckLineIcon size={16} color={styledTheme.palette.white} />
        </Box>
      </Label>
      {!!errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
    </div>
  )
}

export function FormikCheckboxBtn(
  props: Props & React.InputHTMLAttributes<HTMLInputElement> & FieldConfig,
): JSX.Element {
  const [field, meta] = useField(props)
  const errorMsg = !!meta.touched && meta.error

  return (
    <CheckboxBtn
      {...field}
      {...props}
      checked={field.value}
      errorMsg={errorMsg}
    />
  )
}
