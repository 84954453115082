import React from 'react'

import Cleave from 'cleave.js/react'
import {Props as CleaveProps} from 'cleave.js/react/props'
import {useField, FieldConfig} from 'formik'
import SearchLineIcon from 'remixicon-react/SearchLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '../../themes'
import ErrorMsg from '../form/ErrorMsg'

interface InputProps extends CleaveProps {
  errorMsg?: string
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 56px;
  min-height: 56px;
  box-sizing: border-box;
`

const PrefixIcon = styled.div`
  position: absolute;
  left: 16px;
  top: calc(50% - 8px);
  z-index: 1;
  transition: transform 0.15s ease-out;

  ${media.sm`
    left: 28px;
    top: calc(50% - 12px);

    svg {
      height: 24px !important;
      width: 24px !important;
    }
  `}
`

const SearchBarInput = styled(Cleave)`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid ${palette('border')};
  border-radius: 0;
  z-index: 0;
  vertical-align: center;
  padding-left: 55px;
  padding-right: 32px;
  font-weight: 500;
  font-size: 1.125rem;
  color: ${palette('blue')};
  caret-color: ${palette('blue')};
  background-color: transparent;
  transition: border-color 0.15s;

  &:focus {
    outline: none;
    border-color: ${palette('lightBlue')};

    & ~ ${PrefixIcon} {
      transform: scale(0.8);
    }
  }

  &::placeholder {
    font-size: 1.125rem;
    color: ${palette('ui30Solid')};
    font-weight: 500;
  }

  ${media.sm`
    padding-left: 80px;
  `}
`

export default function SearchInput({
  errorMsg,
  ...props
}: InputProps & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element {
  return (
    <>
      <Container>
        <SearchBarInput {...props} />
        <PrefixIcon>
          <SearchLineIcon size={20} color={styledTheme.palette.blue} />
        </PrefixIcon>
      </Container>
      <ErrorMsg>{errorMsg}</ErrorMsg>
    </>
  )
}

export function FormikSearchInput(
  props: InputProps &
    React.InputHTMLAttributes<HTMLInputElement> &
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FieldConfig<any>,
): JSX.Element {
  const [field, meta] = useField(props)
  const errorMsg = !!meta.touched && meta.error

  return <SearchInput {...field} {...props} errorMsg={errorMsg} />
}
