import React from 'react'

import {useRouter} from 'next/router'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {BackButton} from '@festi/common/components/buttons'

const Wrapper = styled.div`
  display: none;
  padding: 32px 0;
  background-color: ${palette('white')};

  ${media.md`
    display: inherit;
  `}
`

export default function ProductHeader(): JSX.Element {
  const router = useRouter()

  return (
    <Wrapper>
      <Container>
        <BackButton onClick={() => router.back()}>Til baka</BackButton>
      </Container>
    </Wrapper>
  )
}
