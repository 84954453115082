import {useCallback, useEffect, useState} from 'react'

import {RemixiconReactIconComponentType} from 'remixicon-react'
import ShoppingBagLineIcon from 'remixicon-react/ShoppingBagLineIcon'
import styled from 'styled-components'

import {CheckoutLine, VariantList} from '@festi/common/api/rest'
import {useCheckout, useEvents} from '@festi/common/contexts'
import {onDutyFree} from '@festi/common/constants/channels'
import useTagmanager from '@festi/common/utils/tagmanager'

import {IconButton, MainIconButton} from '../buttons'

const ActionBtn = styled(IconButton)`
  position: absolute;
  bottom: 0;
  left: 0;

  &.wishlistCard {
    position: static;
  }
`

interface Props {
  icon?: RemixiconReactIconComponentType
  origin?: string
  variant: VariantList
  searchInsights?: () => void
  placement?: 'wishlistCard' | 'productCard'
  newDesign?: boolean
  accentColor?: 'green' | 'lightBlue' | 'pink' | 'blue'
  hoverBackgroundColor?: 'green' | 'lightBlue' | 'pink' | 'blue'
  backgroundColor?: 'green' | 'lightBlue' | 'pink' | 'blue'
  borderRadius?: boolean
}

export default function MiniAddToCartButton({
  icon = ShoppingBagLineIcon,
  origin,
  variant,
  searchInsights,
  placement = 'productCard',
  newDesign = false,
  accentColor = 'green',
  backgroundColor = 'blue',
  hoverBackgroundColor = 'green',
  borderRadius = false,
}: Props): JSX.Element {
  const {restCheckout, restCheckoutLoading, cartIsUpdating, cartUpdate} =
    useCheckout()
  const {sendAddToCartEvent} = useEvents()
  const {sendEcommerceAddToCartEvent} = useTagmanager()

  const {id, sku} = variant

  // Used to prevent all cart buttons from animating on comparison page
  const [buttonPressed, setButtonPressed] = useState(false)

  const qtyInCart =
    restCheckout?.lines.find((line: CheckoutLine) => line.variant.sku === sku)
      ?.quantity || 0

  const addToCart = useCallback(() => {
    setButtonPressed(true)
    sendEcommerceAddToCartEvent([variant], origin)
    cartUpdate({
      [id]: qtyInCart + 1,
    })
    !!searchInsights && searchInsights()
    sendAddToCartEvent(
      'convertedObjectIDsAfterSearch',
      'Product Added to Cart',
      variant.sku || '',
    )
  }, [
    id,
    qtyInCart,
    origin,
    variant,
    cartUpdate,
    searchInsights,
    sendAddToCartEvent,
    sendEcommerceAddToCartEvent,
  ])

  useEffect(() => {
    if (!cartIsUpdating && buttonPressed) {
      setButtonPressed(false)
    }
  }, [cartIsUpdating, buttonPressed])

  if (newDesign) {
    return (
      <MainIconButton
        icon={icon}
        buttonVariant="secondary"
        loading={cartIsUpdating && buttonPressed}
        disabled={
          restCheckoutLoading ||
          !(onDutyFree ? variant?.isInStockDutyfree : variant?.isInStockWeb)
        }
        onClick={addToCart}
      />
    )
  }

  return (
    <ActionBtn
      accentColor={accentColor}
      icon={icon}
      onClick={addToCart}
      loading={cartIsUpdating && buttonPressed}
      className={placement ? placement : ''}
      backgroundColor={backgroundColor}
      hoverBackgroundColor={hoverBackgroundColor}
      borderRadius={borderRadius}
      disabled={
        restCheckoutLoading ||
        !(onDutyFree ? variant?.isInStockDutyfree : variant?.isInStockWeb)
      }
    />
  )
}
