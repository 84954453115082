import {
  format,
  isSaturday,
  isSunday,
  isWithinInterval,
  startOfDay,
  endOfDay,
} from 'date-fns'

import {OpeningHoursGeneral_openingHoursGeneral} from '../api/datocms/types/OpeningHoursGeneral'

export function formatDatocmsDate(date: string): string {
  return format(Date.parse(date), 'dd MMMM yyyy')
}

export function getNoon(ofDate?: Date): Date {
  const noon = ofDate || new Date()
  noon.setDate(noon.getDate())
  noon.setHours(12, 0, 0, 0)

  return noon
}

export function formatInputDate(date: Date): string {
  return format(date, 'dd.MM.yy')
}

// TODO: Test
export function formatRestDate(date: string): string {
  return format(Date.parse(date), 'dd/MM/yyyy')
}

// TODO: Test
export function isActivePeriod(activeFrom: string, activeTo: string) {
  return isWithinInterval(Date.now(), {
    start: startOfDay(Date.parse(activeFrom)),
    end: endOfDay(Date.parse(activeTo)),
  })
}

export function isWithinTimeRange(start: number, end: number) {
  const startMinutes = start * 60
  const endMinutes = end * 60
  const date = new Date()
  const now = date.getHours() * 60 + date.getMinutes()

  return startMinutes <= now && now <= endMinutes
}

export interface OpeningHoursToday {
  occurences: number
  storeName: string
  openingHours: string
}

//TODO: Test
export function getOpeningHoursToday(
  openingHours: OpeningHoursGeneral_openingHoursGeneral,
): OpeningHoursToday[] {
  const today = Date.now()
  const key = isSaturday(today)
    ? 'saturdays'
    : isSunday(today)
    ? 'sundays'
    : 'weekDays'

  const reduced = openingHours?.stores.reduce((map, openingHour) => {
    const prevOccurence = map[openingHour[key]]?.occurences || 0
    const prevStoreName = map[openingHour[key]]?.storeName

    map[openingHour[key]] = {
      occurences: prevOccurence + 1,
      storeName:
        openingHour.storeName + (prevStoreName ? ', ' + prevStoreName : ''),
      openingHours: openingHour[key],
    }

    return map
  }, {})
  return reduced ? Object.values(reduced) : null
}
