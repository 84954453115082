import {useRef} from 'react'

import {Transition} from 'react-transition-group'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'

interface MapProps<L> {
  [key: string]: L
}

const DrawerWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${theme('zIndex.sideDrawer')};
  background-color: ${palette('ui60')};

  /* Safari fix for background flickering */
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  transition: opacity 0.25s;
  backface-visibility: hidden;
  perspective: 1;
`

const DrawerInner = styled.div`
  height: 100%;
  padding-top: ${styledTheme.navBarHeight.small};
  z-index: 1;
  outline: none;
  overflow: hidden;
  transition: transform 0.25s;
  transition-timing-function: cubic-bezier(0.33, 0.48, 0.13, 0.9);
  -webkit-overflow-scrolling: 'touch';
  background-color: transparent;

  ${media.md`
    padding-top: ${styledTheme.navBarHeight.large};
  `}
`

interface Props {
  id: string
  isOpen: boolean
  children: React.ReactNode
}

export default function DrawerTransition({
  id,
  isOpen,
  children,
}: Props): JSX.Element {
  const contentRef: React.RefObject<HTMLDivElement> = useRef(null)

  return (
    <Transition
      id={id}
      nodeRef={contentRef}
      in={isOpen}
      timeout={{enter: 240, exit: 0}}
    >
      {(state) => {
        const opacityMap: MapProps<1 | 0> = {
          entering: 0,
          entered: 1,
          exiting: 1,
          exited: 0,
          unmounted: 0,
        }

        const pointerMap: MapProps<'none' | 'auto'> = {
          entering: 'none',
          entered: 'auto',
          exiting: 'auto',
          exited: 'none',
          unmounted: 'none',
        }

        const transformMap: MapProps<string> = {
          entering: 'translateX(-100%)',
          entered: 'translateX(0)',
          exiting: 'translateX(0)',
          exited: 'translateX(-100%)',
          unmounted: 'translate3d(0, 0, 0)',
        }

        return (
          <DrawerWrapper
            style={{
              opacity: opacityMap[state],
              pointerEvents: pointerMap[state],
            }}
          >
            <DrawerInner style={{transform: transformMap[state]}}>
              {children}
            </DrawerInner>
          </DrawerWrapper>
        )
      }}
    </Transition>
  )
}
