import {useCallback, useEffect, useMemo, useState} from 'react'

import {RemixiconReactIconComponentType} from 'remixicon-react'
import FlightTakeoffFillIcon from 'remixicon-react/FlightTakeoffFillIcon'
import ShoppingBagLineIcon from 'remixicon-react/ShoppingBagLineIcon'
import ShoppingCartLineIcon from 'remixicon-react/ShoppingCartLineIcon'

import {
  VariantList,
  VariantDetail,
  CheckoutLine,
  Variant,
  ProductDetail,
} from '@festi/common/api/rest'
import {useCheckout, useEvents} from '@festi/common/contexts'
import {onDutyFree} from '@festi/common/constants/channels'
import useTagmanager from '@festi/common/utils/tagmanager'
import {styledTheme} from '@festi/common/themes'

import {Button, MainButton} from '../buttons'

type addToCardVariant = (
  | (VariantDetail & {
      qtyInCart: number
    })
  | (VariantList & {
      qtyInCart: number
    })
)[]

interface VariantProps {
  variant: VariantList | VariantDetail | Variant
  product?: ProductDetail
  buttonText?: string
  newDesign?: boolean
  large?: boolean
}
interface VariantListProps {
  icon?: RemixiconReactIconComponentType
  buttonText?: string
  newDesign?: boolean
  large?: boolean
  variants: VariantList[]
}

function AddToCartButton({variant, product}: VariantProps): JSX.Element
function AddToCartButton({variants}: VariantListProps): JSX.Element
function AddToCartButton({
  icon,
  variant,
  variants,
  product,
  newDesign,
  buttonText,
  large,
}: Partial<VariantProps & VariantListProps>): JSX.Element {
  const {restCheckout, restCheckoutLoading, cartUpdate, cartIsUpdating} =
    useCheckout()
  const {sendAddToCartEvent} = useEvents()
  const {sendEcommerceAddToCartEvent} = useTagmanager()

  const variantsToAdd = useMemo(
    () => variants || [variant],
    [variant, variants],
  )

  const outOfStockWeb = variantsToAdd?.some(
    (p) => !(onDutyFree ? p?.isInStockDutyfree : p?.isInStockWeb),
  )

  const defaultText = outOfStockWeb
    ? `Uppselt í ${onDutyFree ? 'flugstöð' : 'vefverslun'}`
    : onDutyFree
    ? 'Sækja við brottför'
    : 'Setja í körfu'

  const addToCartText = buttonText ?? defaultText

  // Used to prevent all cart buttons from animating on comparison page
  const [buttonPressed, setButtonPressed] = useState(false)

  const addToCart = useCallback(() => {
    setButtonPressed(true)
    sendEcommerceAddToCartEvent(variantsToAdd, undefined, product)

    const addProducts = variantsToAdd.map((product) => {
      const qtyInCart =
        restCheckout?.lines.find(
          (line: CheckoutLine) => line.variant.sku === product?.sku,
        )?.quantity || 0
      return {
        ...product,
        qtyInCart,
      }
    }) as addToCardVariant

    const updateObject = Object.fromEntries(
      addProducts.map((p) => [p.id, p.qtyInCart + 1]),
    )
    cartUpdate(updateObject)

    sendAddToCartEvent(
      'convertedObjectIDsAfterSearch',
      'Product Added to Cart',
      addProducts?.[0]?.sku || 'multi-add',
    )
  }, [
    restCheckout,
    variantsToAdd,
    cartUpdate,
    sendAddToCartEvent,
    sendEcommerceAddToCartEvent,
  ])

  useEffect(() => {
    if (!cartIsUpdating && buttonPressed) {
      setButtonPressed(false)
    }
  }, [cartIsUpdating, buttonPressed])

  if (newDesign) {
    return (
      <MainButton
        label={addToCartText}
        size={large ? 'large' : 'medium'}
        prefixIcon={onDutyFree ? FlightTakeoffFillIcon : ShoppingCartLineIcon}
        iconColor={styledTheme.palette.green}
        loading={cartIsUpdating && buttonPressed}
        disabled={restCheckoutLoading || outOfStockWeb}
        onClick={addToCart}
        fullWidth={large}
      />
    )
  }

  return (
    <Button
      withBar
      accentColor="green"
      icon={
        icon ? icon : onDutyFree ? FlightTakeoffFillIcon : ShoppingBagLineIcon
      }
      onClick={addToCart}
      loading={cartIsUpdating && buttonPressed}
      disabled={restCheckoutLoading || outOfStockWeb}
    >
      {addToCartText}
    </Button>
  )
}

export default AddToCartButton
