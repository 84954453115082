import {useState} from 'react'

import useSWR from 'swr'

import {useAuth} from '@festi/common//contexts'
import {cleanSSN, isValidSsn} from '@festi/utils/strings'
import {handleRestResponse, restApi} from '@festi/common/api/rest'

const INVALID_SSN_MESSAGE = 'Þessi kennitala finnst ekki'

export default function useRegistry(ssn?: string) {
  const {user} = useAuth()
  const loggedIn = !!user?.id

  const [message, setMessage] = useState<string>('')

  const clean = cleanSSN(ssn)
  const isValid = isValidSsn(clean)

  const {data: businessRegisty} = useSWR(
    (isValid && loggedIn) ? [clean, message, 'businessRegistry'] : null,
    () => handleRestResponse(restApi.businessRegistryRetrieve(clean)), {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: () => {
        setMessage('')
      },
      onError: () => {
        setMessage(INVALID_SSN_MESSAGE)
      },
    }
  )

  const {data: registry} = useSWR(
    (isValid && !loggedIn) ? [clean, message, 'registry'] : null,
    () => handleRestResponse(restApi.registryVerifyRetrieve(clean)), {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (res) => {
        setMessage(res.valid ? '' : INVALID_SSN_MESSAGE)
      },
      onError: () => {
        setMessage('Kerfi niðri')
      },
    }
  )

  const registryName = businessRegisty?.name ?? ''
  const ssnValid = isValid && (registry?.valid || !!registryName)
  const registryMessage = isValid ? message : ''

  return {ssnValid, registryName, registryMessage}
}
