import {useEffect, useState} from 'react'

import cn from 'classnames'
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {Expandable} from '@festi/common/components/common'
import {styledTheme} from '@festi/common/themes'

import ErrorMsg from './ErrorMsg'

const Wrapper = styled.div`
  margin-bottom: 12px;
`

const InnerWrapper = styled.div`
  position: relative;
  margin-bottom: 4px;
  border-top: 1px solid ${palette('ui20Solid')};
  border-bottom: 1px solid ${palette('ui20Solid')};
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  transition: border-color 0.15s, opacity 0.15s;

  .remixicon-icon {
    transition: fill 0.15s, transform 0.15s ease-out;
  }

  &.isOpen {
    border-top-color: ${palette('lightBlue')};
    border-bottom-color: ${palette('lightBlue')};
    border-left-color: ${palette('border')};
    border-right-color: ${palette('border')};

    .remixicon-icon {
      transform: rotate(180deg);
      fill: ${palette('lightBlue')};
    }
  }

  &.hasError {
    border-top-color: ${palette('error')};
    border-bottom-color: ${palette('error')};
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`

const HeadBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid transparent;
  background-color: ${palette('white')};
  transition: border-color 0.15s;
  cursor: pointer;

  &.isOpen {
    border-color: ${palette('border')};
  }

  &.disabled {
    cursor: default;
  }
`

const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  text-align: left;
  margin-bottom: 4px;
  color: ${palette('lightBlue')};
`

const SelectedValue = styled.div`
  font-weight: 500;
  font-size: 1rem;
  color: ${palette('blue')};
  text-align: left;
`

const ItemsWrapper = styled.div`
  max-height: 315px;
  overflow-y: auto;
`

const Item = styled.button`
  width: 100%;
  padding: 16px 12px;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  border: 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  background-color: ${palette('white')};
  transition: background-color 0.15s, border-color 0.15s;

  &:not(:first-child) {
    border-top: 1px solid ${palette('border')};
  }

  &:hover {
    cursor: pointer;
    background-color: ${palette('light')};
  }

  &.isSelected {
    border-color: ${palette('lightBlue')};
    background-color: ${palette('lightBlue10')};
  }
`

const SelectInput = styled.input`
  font-weight: 500;
  font-size: 1rem;
  text-align: left;
  border: none;
  border-width: 0px;

  &:focus {
    outline: none;
  }
`

export type SelectItem = {id: string; label: string}
interface Props {
  label: string
  items: SelectItem[]
  disabled?: boolean
  errorMsg?: string
  selectedId: string
  placeholder: string
  onSelect: (id: string) => void
}

export default function BasicSelect({
  label,
  items,
  disabled,
  errorMsg,
  selectedId,
  placeholder,
  onSelect,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [keyboardInput, setKeyboardInput] = useState<string>('')
  const [activateKeyboard, setActivateKeyboard] = useState<boolean>(false)

  const selectedItem = items?.find((i) => i.id === selectedId)
  const filteredItems = items?.filter((i) =>
    i.label?.toLowerCase().includes(keyboardInput.toLowerCase()),
  )

  function onClickbox(id: string) {
    setIsOpen(false)
    onSelect(id)
    setActivateKeyboard(false)
    setKeyboardInput('')
  }

  function handleKeyPress() {
    if (isOpen && !activateKeyboard && label === 'Staður') {
      setActivateKeyboard(true)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  })

  return (
    <Wrapper>
      <InnerWrapper className={cn({isOpen, disabled, hasError: !!errorMsg})}>
        <HeadBtn
          type="button"
          className={cn({isOpen, disabled})}
          onClick={() => {
            if (isOpen) {
              setActivateKeyboard(false)
            }
            setIsOpen(!isOpen)
            setKeyboardInput('')
          }}
        >
          <div>
            <Label>{label}</Label>
            {!activateKeyboard ? (
              <SelectedValue>
                {selectedItem?.label || placeholder}
              </SelectedValue>
            ) : (
              <SelectInput
                type="text"
                value={keyboardInput}
                autoFocus={true}
                maxLength={3}
                onChange={(e) => {
                  if (
                    !isNaN(parseInt(e.target.value)) ||
                    e.target.value === ''
                  ) {
                    setKeyboardInput(e.target.value)
                  }
                }}
              />
            )}
          </div>
          <ArrowDownSLineIcon size={28} color={styledTheme.palette.ui20Solid} />
        </HeadBtn>

        <Expandable expanded={isOpen}>
          {!keyboardInput.length ? (
            <ItemsWrapper>
              {items?.map((item: SelectItem) => (
                <Item
                  key={item.id}
                  type="button"
                  className={cn({isSelected: item === selectedItem})}
                  onClick={() => onClickbox(item.id)}
                  onFocus={() => setIsOpen(true)}
                >
                  {item.label}
                </Item>
              ))}
            </ItemsWrapper>
          ) : (
            <ItemsWrapper>
              {filteredItems?.map((item: SelectItem) => (
                <Item
                  key={item.id}
                  type="button"
                  className={cn({isSelected: item === selectedItem})}
                  onClick={() => onClickbox(item.id)}
                  onFocus={() => setIsOpen(true)}
                >
                  {item.label}
                </Item>
              ))}
            </ItemsWrapper>
          )}
        </Expandable>
      </InnerWrapper>

      <ErrorMsg>{errorMsg}</ErrorMsg>
    </Wrapper>
  )
}
