import Link from 'next/link'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'

import {
  CardSubtitle,
  CardTitle,
  PriceText,
} from '@festi/common/components/typography'
import {fluidRange} from '@festi/common/utils/styles'

import {CardWrapperProps} from './interface'

export const CardLink = styled(Link)`
  height: 100%;
  padding: 16px 6px 12px 20px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  cursor: pointer;
  position: relative;

  ${media.sm`
    padding: 28px 28px 12px 28px;
  `}

  ${media.md`
    padding: 24px 24px 12px 24px;
  `}
`

export const BigCardInner = styled.a`
  height: 100%;
  padding: 16px 6px 12px 20px;
  display: flex;
  justify-content: stretch;
  cursor: pointer;
  position: relative;

  ${media.sm`
    padding: 28px 28px 12px 28px;
  `}

  ${media.md`
    padding: 24px 24px 12px 24px;
  `}
`

export const CardText = styled.div`
  width: 60%;
  padding: 8px 0 8px 12px;

  &.isFeatured {
    width: 100%;
    padding: 8px 0;
  }

  ${media.sm`
    width: 100%;
    padding: 16px 0;

    &.isFeatured {
      width: 60%;
      padding: 16px 32px;
    }

    ${CardTitle} {
      margin-bottom: 8px;
      font-size: 1.5rem;
    }

    ${CardSubtitle} {
      margin-bottom: 16px;
    }
  `}
`

export const CardHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.hasTag {
    padding-right: 50px;
  }

  ${CardTitle} {
    margin-bottom: 4px;
  }

  ${media.sm`
    &.isFeatured {
      display: none;
    }
    &.isDiscounted {
      padding-right: 0;
    }

    ${CardTitle} {
      margin-bottom: 8px;
    }
  `}
`

export const CardBody = styled.div`
  padding: 16px 0;
  display: flex;

  &.hasMultipleTags {
    padding-right: 50px;
  }

  ${media.sm`
    padding: 0;
    flex-grow: 1;

    &.isFeatured {
      padding: 16px 0;
    }
  `}
`

export const CardStockStatus = styled.div`
  display: none;

  ${media.sm`
    display: block;
    height: 180px;
  `}
`

export const TitleWrapper = styled.div`
  display: none;
  flex-direction: column;

  &.showInMobile {
    display: flex;
  }

  ${media.sm`
    display: none;

    &.showInDesktop {
      display: flex;
    }
  `}
`

export const CardFootWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 35px;
  padding-right: 20px;

  ${media.sm`
    height: 45px;
    padding-right: 0;
  `}

  &.isFeatured {
    ${media.sm`
      ${PriceText} {
        font-size: 2.5rem;
      }
    `}
  }
`

export const CardFoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`

export const ComparisonBtnWrapper = styled.div`
  display: flex;
  gap: 10px;
  bottom: 12px;
  left: 16px;
  background-color: ${palette('white')};

  > .wishlist {
    flex: 0 0 56px;
  }
  > .comparison {
    border: 0;
    flex: 1;
  }

  ${media.sm`
    display: flex;
    position: absolute;
    overflow: hidden;
    bottom: 0;
    left: -1px;
    right: -1px;
    height: 0;
    border: 1px solid ${palette('ui20Solid')};
    border-top: 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    transition: bottom 0.15s ease-out, border-color 0.15s, height 0.15s ease-out;
  `}
`

export const TagWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`

// This wrapper is used so all cards in the same row take the same height
// But still keep a bottom space
export const OuterWrapper = styled.div`
  height: 100%;

  &.withGutter {
    ${fluidRange('padding-bottom', '12px', '24px')}
  }
`

export const CardWrapper = styled.div<CardWrapperProps>`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${palette('white')};
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette('ui20Solid')};
  box-sizing: border-box;
  transition: border-color 0.15s;
  border-color: ${({highlightColor}) => highlightColor};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: ${theme('boxShadow.card')};
    opacity: 0;
    transition: opacity 0.15s;
  }

  &:hover {
    z-index: 10;
    border-color: ${({highlightColor}) =>
      highlightColor || palette('ui60Solid')};
    &:before {
      opacity: 1;
    }
  }

  ${({highlightColor}) => media.sm`
    border-bottom: 0;
    ${ComparisonBtnWrapper} {
    border-color: ${highlightColor && highlightColor};
    }

    &:hover {
      ${ComparisonBtnWrapper} {
        bottom: -50px;
        height: 50px;
        border-color: ${highlightColor ? highlightColor : palette('ui60Solid')};
      }
    }
  `}
`
