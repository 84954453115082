import cn from 'classnames'
import HotelLineIcon from 'remixicon-react/HotelLineIcon'
import UserLineIcon from 'remixicon-react/UserLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {NavBtn} from '@festi/common/components/layout/Header'
import {useAuth} from '@festi/common/contexts'
import {formatBusinessName, wordTruncateOrAcronym} from '@festi/utils/strings'

import {styledTheme} from '../../themes'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.4s ease-in;

  &.hidden {
    opacity: 0;
  }
`

const Name = styled.span`
  display: none;
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('blue')};
  line-height: 1.2;
  overflow: hidden;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: left;
  min-width: 80px;

  opacity: 1;
  transition: opacity 0.4s ease-in;

  &.hidden {
    opacity: 0;
  }

  ${media.md`
    font-size: 1.25rem;
  `}

  ${media.sm`
    display: block;
    margin-right: 16px;
  `}
`

interface Props {
  onClickViewUser?: () => void
}

export default function UserNav({onClickViewUser}: Props): JSX.Element {
  const {user, business, loadingBusiness, selectedBusiness} = useAuth()

  const userName = wordTruncateOrAcronym(user.name)
  const businessName =
    selectedBusiness &&
    !loadingBusiness &&
    formatBusinessName(business.name, business.ssn, true)

  return (
    <NavBtn accentColor="lightBlue" onClick={onClickViewUser}>
      <Wrapper className={cn({hidden: loadingBusiness})}>
        <Name>{businessName || userName}</Name>

        {selectedBusiness ? (
          <HotelLineIcon color={styledTheme.palette.blue} />
        ) : (
          <UserLineIcon color={styledTheme.palette.blue} />
        )}
      </Wrapper>
    </NavBtn>
  )
}
