import classNames from 'classnames'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import {RemixiconReactIconComponentType} from 'remixicon-react'

const Wrapper = styled.div`
  .ToggleGroup {
    display: flex;
    gap: 1px;
    border: 1px solid ${palette('border')};
    border-radius: 8px;
    background-color: ${palette('ui5Solid')};

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 46px;
      border: none;
      background-color: ${palette('white')};
      cursor: pointer;

      svg {
        fill: ${palette('ui40Solid')};
      }

      &.selected {
        cursor: default;

        svg {
          fill: ${palette('blue')};
        }
      }

      &:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
`

interface Props {
  selected?: string
  items?: {
    icon: RemixiconReactIconComponentType
    value: string
  }[]
  handleChange: (value: string) => void
}

export default function RadixToggleGroup({
  items,
  selected,
  handleChange,
}: Props) {
  return (
    <Wrapper>
      <ToggleGroup.Root
        className="ToggleGroup"
        type="single"
        defaultValue="center"
        aria-label="Text alignment"
      >
        {items?.map(({value, icon: Icon}, i) => (
          <ToggleGroup.Item
            key={value}
            className={classNames({selected: selected === value})}
            value={value}
            aria-label={`${
              i === 0 ? 'Left' : i === items.length ? 'Right' : 'Center'
            } aligned`}
            onClick={() => handleChange(value)}
          >
            <Icon size={20} />
          </ToggleGroup.Item>
        ))}
      </ToggleGroup.Root>
    </Wrapper>
  )
}
