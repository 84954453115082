import {useCallback,  useState} from 'react'

import cn from 'classnames'
import {useCombobox, UseComboboxStateChange} from 'downshift'
import ErrorWarningFillIcon from 'remixicon-react/ErrorWarningFillIcon'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'

import ErrorMsg from './ErrorMsg'
import {
  Adornment,
  FocusLine,
  InputContainer,
  InputField,
  Placeholder,
} from './Input'

export type SelectItem = {id: string; label: string}

interface Props<Item> {
  placeholder: string
  items: Item[]
  selectedItem?: Item
  itemToString?: (item: Item | null) => string
  handleSelectedItemChange?: (changes: UseComboboxStateChange<Item>) => void
  handleClearSelection?: () => void
  disabled?: boolean
  errorMsg?: string
  setTouched?: (value: boolean, shouldValidate?: boolean) => void
}

const Wrapper = styled.div`
  margin-bottom: 12px;
`

const Item = styled.button`
  width: 100%;
  padding: 16px 12px;
  background-color: ${palette('white')};
  border: 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  transition: background-color 0.15s, border-color 0.15s;

  &:not(:first-child) {
    border-top: 1px solid ${palette('border')};
  }

  &:hover {
    cursor: pointer;
    background-color: ${palette('light')};
  }

  &.isSelected {
    border-color: ${palette('lightBlue')};
    background-color: ${palette('lightBlue10')};
  }

  &.isHighlighted {
    border-color: ${palette('lightBlue')};
    background-color: ${palette('lightBlue10')};
  }
`

const Menu = styled.ul`
  position: relative;
  background-color: ${palette('white')};
  max-height: 280px;
  width: 100%;
  overflow-y: scroll;
  padding: 0;
  list-style: none;
  opacity: 0;
  pointer-events: none;
  z-index: ${theme('zIndex.select')};
  transition: opacity 0.15s, height 0.15s;
  height: 0;
  &.isOpen {
    opacity: 1;
    height: 100%;
    pointer-events: unset;
  }
  &:focus {
    outline: none;
  }
`

export default function InputSelect<Item>({
  placeholder,
  items,
  selectedItem: initialSelectedItem,
  itemToString = (item) => (item as unknown) as string,
  errorMsg,
  handleSelectedItemChange,
  handleClearSelection,
  disabled,
  setTouched,
}: Props<Item>): JSX.Element {
  const [inputItems, setInputItems] = useState(items)

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    selectedItem,
    setInputValue,
  } = useCombobox({
    items: inputItems,
    selectedItem: initialSelectedItem,
    itemToString,
    onSelectedItemChange: handleSelectedItemChange,
    onInputValueChange: ({inputValue}) => {
      const filteredItems = items.filter((item) =>
        itemToString(item).toLowerCase().includes(inputValue.toLowerCase()),
      )
      setInputItems(filteredItems)
    },
  })

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'Escape') {
        e.preventDefault()
        setInputValue('')
        handleClearSelection()
        openMenu()
      }
    },
    [openMenu, handleClearSelection, setInputValue],
  )

  const onBlur = useCallback(() => {
    setTouched(true)
  }, [setTouched])

  const onFocus = useCallback(() => {
    if (!isOpen) {
      openMenu()
    }
  }, [isOpen, openMenu])

  return (
    <Wrapper>
      <div {...getComboboxProps()}>
        <InputContainer style={{marginBottom: 0}}>
          <InputField
            {...getInputProps({
              placeholder,
              disabled,
              onKeyDown,
              onBlur,
              onFocus,
            })}
            style={{
              borderColor: errorMsg
                ? styledTheme.palette.error
                : styledTheme.palette.ui20Solid,
            }}
          />
          <Placeholder>{placeholder}</Placeholder>
          <FocusLine />
          <Adornment
            color={styledTheme.palette.transparent}
            style={{opacity: errorMsg ? 1 : 0}}
          >
            <ErrorWarningFillIcon size={18} color={styledTheme.palette.error} />
          </Adornment>
        </InputContainer>
        <Menu {...getMenuProps()} className={cn({isOpen})}>
          {inputItems?.map((item, index) => {
            const highlighted = highlightedIndex === index
            const props = getItemProps({item, index})
            const isSelected = props.isSelected || item === selectedItem

            return (
              <Item
                key={index}
                type="button"
                {...props}
                className={cn({
                  isSelected,
                  isHighlighted: highlighted,
                })}
              >
                {itemToString(item)}
              </Item>
            )
          })}
        </Menu>
      </div>
      <ErrorMsg>{errorMsg}</ErrorMsg>
    </Wrapper>
  )
}
