import React from 'react'

import styled from 'styled-components'
import {palette} from 'styled-tools'
import {media} from 'styled-bootstrap-grid'

import HeaderLogo from './HeaderLogo'
import HeaderMenu from './HeaderMenu'
import HeaderSearchbar from './searchbar'
import HeaderUtilites from './HeaderUtilities'
import DutyfreeLine from './DutyfreeLine'
import DutyfreeBanner from './DutyfreeBanner'

export const headerMobile = 116
export const headerDesktop = 76

const HeaderPadding = styled.div`
  height: ${headerMobile}px;

  ${media.md`
    height: ${headerDesktop}px;
  `}
`

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: ${headerMobile}px;
  padding: 0 16px;
  background-color: #f6f7f9;
  border-bottom: 1px solid ${palette('border')};
  z-index: 1000;

  ${media.md`
    height: ${headerDesktop}px;
  `}
`

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    'logo utilities menu'
    'line line line'
    'search search search';
  grid-template-columns: auto 1fr auto;
  gap: 2px 4px;
  width: 100%;
  padding: 8px 0;

  ${media.md`
    grid-template-areas:
    'logo menu search utilities'
    'line line line line';
    grid-template-columns: auto auto 1fr auto;
    gap: 0 10px;
    padding: 0;
  `}
`

const Line = styled.div`
  grid-area: line;
`

const Logo = styled.div`
  grid-area: logo;
`

const Menu = styled.div`
  grid-area: menu;
`

const Search = styled.div`
  grid-area: search;
  width: 100%;
`

const Utilities = styled.div`
  grid-area: utilities;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

interface Props {
  onClickCart: () => void
  onClickLogin: () => void
  onClickWishlist: () => void
}

export default function Header({
  onClickCart,
  onClickLogin,
  onClickWishlist,
}: Props) {
  return (
    <>
      <HeaderPadding />

      <Wrapper>
        <Grid>
          <Line>
            <DutyfreeLine />
          </Line>

          <Logo>
            <HeaderLogo />
          </Logo>

          <Menu>
            <HeaderMenu />
          </Menu>

          <Search>
            <HeaderSearchbar />
          </Search>

          <Utilities>
            <HeaderUtilites
              onClickCart={onClickCart}
              onClickLogin={onClickLogin}
              onClickWishlist={onClickWishlist}
            />
          </Utilities>
        </Grid>
      </Wrapper>

      <DutyfreeBanner />
    </>
  )
}
