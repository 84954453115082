import React from 'react'
import dynamic from 'next/dynamic'

import {Showcase} from '@festi/common/api/rest'
import {Inventory} from '@festi/common/utils/products'

import {ArrivalStoreStock, StockStatusType} from '../common/StockChip'

const StockStatus = dynamic(() => import('../common/StockChip'), {
  ssr: false,
})
interface Props {
  sku?: string
  inventory: Inventory[]
  showcases: Showcase[]
  showShowcases: boolean
}

export default function ProductStock({
  sku,
  inventory,
  showcases,
  showShowcases,
}: Props) {
  return (
    <>
      <ArrivalStoreStock sku={sku} />

      {inventory.map((i) => (
        <StockStatus
          key={i.status}
          status={i.status as StockStatusType}
          warehouses={i.warehouses}
        />
      ))}

      {showShowcases &&
        (showcases.length ? (
          <StockStatus
            status="showcase"
            warehouses={showcases.map((s) => s.name)}
          />
        ) : (
          <StockStatus status="no_showcase" />
        ))}
    </>
  )
}
