import {useEffect, useState} from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import {DynamicIcon} from '@festi/common/components/common'
import {palette} from 'styled-tools'
import {useMedia} from 'react-use'
import {fluidRange} from '@festi/common/utils/styles'

const Bullet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  max-width: 300px;
  margin: auto;

  @media (max-width: 1039px) {
    flex-direction: row;
    gap: 16px;
  }
`

const BulletText = styled.div`
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 410;
  color: ${palette('blue')};
  text-decoration: underline;
`

const InfoBulletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 166px;
  margin-top: 40px;
  padding: 40px 36px;
  border-top: 1px solid ${palette('border')};
  background-color: ${palette('background')};

  @media (max-width: 1039px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    padding: 24px 36px;
  }

  @media (max-width: 425px) {
    align-items: flex-start;
  }
`

const InfoBullets = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${fluidRange('gap', '32px', '240px')};

  @media (max-width: 1039px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
`

interface BulletProps {
  icon: string | null
  title: string | null
  link: string | null
  description?: string | null
}

function FooterInfoBulletItem({icon, title, link}: BulletProps): JSX.Element {
  const [iconSize, setIconSize] = useState(48)

  const isSmallScreen = useMedia('(max-width: 1039px)', false)

  useEffect(() => {
    if (isSmallScreen) {
      setIconSize(40)
    } else {
      setIconSize(48)
    }
  }, [isSmallScreen])

  const bullet = (
    <Bullet>
      <DynamicIcon icon={icon || ''} size={iconSize} />
      <BulletText>{title}</BulletText>
    </Bullet>
  )

  if (link) {
    return <Link href={link}>{bullet}</Link>
  }

  return bullet
}

interface BulletListProps {
  infoBullets: BulletProps[]
  dutyFreeInfoBullets: BulletProps[]
  onDutyFree: boolean
}

export default function FooterInfoBulletList({
  infoBullets,
  dutyFreeInfoBullets,
  onDutyFree,
}: BulletListProps): JSX.Element {
  const bulletsToRender = onDutyFree ? dutyFreeInfoBullets : infoBullets

  return (
    <InfoBulletWrapper>
      <InfoBullets>
        {bulletsToRender.map((bullet: BulletProps, index) => (
          <FooterInfoBulletItem key={index} {...bullet} />
        ))}
      </InfoBullets>
    </InfoBulletWrapper>
  )
}
